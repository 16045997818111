import { SvgIcon, SvgIconProps } from "@mui/material";

function WindowsLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={[
        { fill: "none", width: 80, height: 51, scale: 0.7 },
        sx ?? false,
      ].flat(2)}
      {...props}
    >
      <path
        fill="url(#a)"
        d="m15.833 19.812-11.866-.02v8.71c0 .79.584 1.46 1.367 1.568l10.5 1.453v-11.71Z"
      />
      <path
        fill="url(#b)"
        d="M17.416 19.815v11.927l14.822 2.05c.951.132 1.8-.607 1.8-1.568l.003-12.38-16.625-.029Z"
      />
      <path
        fill="url(#c)"
        d="M15.832 6.536 5.321 8.024c-.782.111-1.363.78-1.362 1.57l.01 8.614h11.863V6.536Z"
      />
      <path
        fill="url(#d)"
        d="M17.416 6.312v11.896h16.621V5.782c0-.963-.852-1.703-1.805-1.568L17.416 6.312Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={7.681}
          x2={13.13}
          y1={20.678}
          y2={29.759}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784D8" />
          <stop offset={1} stopColor="#0864C5" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={19.477}
          x2={35.264}
          y1={21.478}
          y2={32.375}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31ABEC" />
          <stop offset={1} stopColor="#1582D5" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={6.445}
          x2={20.415}
          y1={9.262}
          y2={21.835}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31ABEC" />
          <stop offset={1} stopColor="#1582D5" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={20.514}
          x2={44.357}
          y1={8.033}
          y2={24.239}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54DAFF" />
          <stop offset={1} stopColor="#25A2E5" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default WindowsLogo;
