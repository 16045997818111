import { makeAutoObservable } from "mobx";
import { MediaEntity, PatientEntity } from "service";
import { getPatientDetails, getPatientMedia } from "service";

interface PatientStoreProps {
  patient?: PatientEntity;
  fetchPatientDetail: (patientId: string) => void;
  patientMedia?: MediaEntity[];
  clearPatientMedia: () => void;
}

export class PatientStore implements PatientStoreProps {
  patient?: PatientEntity;
  patientMedia?: MediaEntity[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPatient(patient: PatientEntity) {
    this.patient = patient ?? {};
  }

  async fetchPatientDetail(patientId: string) {
    try {
      const { data } = await getPatientDetails(patientId);
      this.setPatient(data);
    } catch (err) {
      console.log({ err });
    }
  }

  async fetchPatientExams(patientId: string) {
    try {
      const { data } = await getPatientMedia(patientId);
      this.setPatientMedia(data);
    } catch (err) {
      console.log({ err });
    }
  }

  clearPatientMedia() {
    this.patientMedia = [];
  }

  setPatientMedia(data?: MediaEntity[]) {
    this.patientMedia = data;
  }
}
