import * as React from "react";
import { Observer } from "mobx-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DropdownStore from "./dropdown-store";
import { Box, SxProps } from "@mui/material";
import { IconViewType } from "../IconView";
import IconButtonView from "../IconButtonView";

export interface OptionType {
  id: string | number;
  value: string;
}

interface DropdownViewProps {
  onClick?: (option: OptionType) => void;
  options: OptionType[];
  iconType?: IconViewType;
  iconProps?: SxProps;
  boxProps?: SxProps;
  children?: React.ReactNode | React.ReactNode[];
}

const DropdownView: React.FunctionComponent<DropdownViewProps> = ({
  onClick,
  options,
  iconType = "expandMore",
  iconProps,
  boxProps,
  children,
}) => {
  const _dropdownStore = new DropdownStore();

  return (
    <Box sx={boxProps}>
      <IconButtonView
        onClick={_dropdownStore?.toggle}
        sx={iconProps}
        iconType={iconType}
      >
        {children}
      </IconButtonView>
      <Observer>
        {() => (
          <Menu
            anchorEl={_dropdownStore?.anchorEl}
            id="account-menu"
            open={_dropdownStore?.open ?? false}
            onClose={_dropdownStore?.toggle}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {options.map((option) => (
              <MenuItem
                key={`dropdown-item-${option.id}`}
                onClick={(e) => {
                  onClick?.(option);
                  _dropdownStore?.toggle(e);
                }}
                sx={{ pt: 1.5 }}
              >
                {option.value}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Observer>
    </Box>
  );
};

export default DropdownView;
