import { LocaleType, RouteType } from "config";
import { ServerResponseEntity, storage, UserEntity } from "service";

export type Cacheable = {
  activeRoute?: RouteType;
  activeLang?: LocaleType;
  user?: UserEntity;
  taskId?: string;
};

async function cacheable<T>(
  key: keyof Cacheable,
  fn?: () => Promise<T>,
  defaultValue?: T
) {
  let result;
  try {
    // retrive the data from backend.
    result = await fn?.();
    const { data } = (result ?? defaultValue) as ServerResponseEntity<T>;
    cacheLocally(key, data ?? defaultValue);
  } catch {
    // if failed to retrieve the data from backend, try localStorage.
    const { getCache } = storage;
    const cache = getCache();
    const cached = cache[key];
    // use the cached data if available, otherwise the default value.
    result = cached ?? defaultValue;
  }

  return result as T;
}

function cacheLocally<T>(key: keyof Cacheable, value: T) {
  const { cacheName, setStorage, getCache } = storage;
  // save the data to localStorage.
  const cache = getCache() as Record<string, T>;
  cache[key] = value;
  localStorage.setItem(cacheName, JSON.stringify(cache));
  setStorage(getCache());
  return value;
}

export { cacheable, cacheLocally };
