import {
  Box,
  Card,
  CardHeader,
  Stack,
  IconButton,
  CardActions,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { cardHeaderStyle, baseCardStyle } from "config";
import { Observer } from "mobx-react";
import { useEffect } from "react";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { last } from "lodash";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { FileData } from "@ffmpeg/ffmpeg/dist/esm/types";
import { reaction } from "mobx";
import { useExamViewContext } from "main";

function ExamStreamView() {
  const examStore = useExamViewContext();
  const ffmpeg = new FFmpeg();

  useEffect(() => {
    // Logout from PU
    return () => {
      examStore.logout();
    };
  }, []);

  useEffect(() => {
    reaction(
      () => examStore.imageChunks.length,
      (chunkLength, oldChunkLength) => {
        if (chunkLength === oldChunkLength) {
          return;
        }

        if (
          examStore.isWaitingForVideo &&
          chunkLength === examStore.bufferSize
        ) {
          examStore.toggleWaitingForVideo();
          convertImagesToMp4();
        }
      }
    );
  }, []);

  const imagesToMp4Toggle = () => {
    examStore.toggleWaitingForVideo();
    examStore.toggleVideoConverting();
  };

  const convertImagesToMp4 = async () => {
    // if (k3900Store.originImageChunks.length === 0) {
    //   return;
    // }

    await ffmpeg.load();

    // Load the FFmpeg.wasm library
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }

    // Prepare the images and add them to FFmpeg's virtual file system
    for (let i = 0; i < examStore.originImageChunks.length; i++) {
      await ffmpeg.writeFile(
        `img${i}.jpg`,
        await fetchFile(examStore.originImageChunks[i])
      );
    }

    await ffmpeg.exec([
      "-framerate",
      "8", // Frame rate (8 frame per second) - adjust as needed
      "-i",
      "img%d.jpg", // Input format
      "-c:v",
      "libx264", // Video codec
      "-pix_fmt",
      "yuv420p", // Pixel format
      "output.mp4", // Output filename
    ]);
    const data: FileData = await ffmpeg.readFile("output.mp4");

    // // Create a blob URL so it can be downloaded/viewed
    const videoBlob = new Blob([data], { type: "video/mp4" });
    const blobVideoUrl = URL.createObjectURL(videoBlob);

    examStore.appendFilteredVideo(blobVideoUrl);
    examStore.toggleVideoConverting();
    console.log("Stop loading");
  };

  const captureImage = () => {
    const lastImageChunk = last(examStore.imageChunks);
    if (lastImageChunk) {
      examStore.handleSavePreset(lastImageChunk);
    }
  };

  return (
    <Card
      sx={{
        ...baseCardStyle,
        flex: 4,
      }}
    >
      <CardHeader
        sx={{
          ...cardHeaderStyle,
        }}
        title={
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              sx={{ color: "secondary.main", width: 40 }}
              onClick={captureImage}
            >
              <CameraAltOutlinedIcon />
            </IconButton>
            <Observer>
              {() => (
                <Box>
                  {examStore.isVideoConverting && (
                    <CircularProgress size="20px" />
                  )}
                  {!examStore.isVideoConverting && (
                    <IconButton
                      sx={{ color: "secondary.main", width: 40 }}
                      onClick={imagesToMp4Toggle}
                    >
                      <VideocamOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </Observer>
          </Stack>
        }
      />
      <Observer>
        {() => (
          <CardMedia
            component="img"
            image={last(examStore.imageChunks)}
            sx={{ py: 2, height: "84%", objectFit: "contain" }}
          />
        )}
      </Observer>
      <CardActions
        sx={{
          height: "8%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Observer>
          {() => (
            <Box>
              {examStore.streamType === "pause" && (
                <PlayCircleFilledRoundedIcon
                  fontSize="large"
                  sx={{ color: "white" }}
                  onClick={() => {
                    examStore.setStreamType("play");
                  }}
                />
              )}
              {examStore.streamType === "play" && (
                <PauseCircleFilledRoundedIcon
                  fontSize="large"
                  sx={{ color: "white" }}
                  onClick={() => {
                    examStore.setStreamType("pause");
                  }}
                />
              )}
            </Box>
          )}
        </Observer>
      </CardActions>
    </Card>
  );
}

export default ExamStreamView;
