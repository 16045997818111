import axios from "axios";
import { Dictionary } from "lodash";
import { BaseURL, appStore } from "service";
import { URLType } from "./url";

function client(_type: URLType, params: Dictionary<object> = {}) {
  const { authStore, uiStore } = appStore;
  const token = authStore.getToken();
  const url = BaseURL[_type];
  return axios.create({
    baseURL: url,
    data: params,
    headers: {
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      locale: uiStore.locale,
    },
    params,
  });
}

export default client;
