import { Box, Divider } from "@mui/material";
import { LoginLogo } from "assets";
import {
  TextBoxView,
  FormView,
  TextFieldView,
  ButtonView,
  CheckBoxView,
  IconView,
} from "main";
import { routes } from "config";
import { Link, useNavigate } from "react-router-dom";
import { appStore, FormStore, SignInEntity } from "service";
import { useCallback } from "react";

function SignInPage() {
  const signInFormStore = new FormStore();
  const { authStore } = appStore;
  const navigate = useNavigate();

  const onSubmitClick = useCallback(async () => {
    const isValid = await signInFormStore.validate();
    if (!isValid) {
      return;
    }
    const values = signInFormStore.getValues() as SignInEntity;
    authStore.signIn(values).then(({ success }) => {
      if (success) {
        authStore.setLoggedIn(true);
        navigate(routes.patients.path);
      }
    });
  }, [signInFormStore]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: {
            xs: "100%",
            sm: 520,
          },
          maxWidth: "100%",
          minWidth: "300px",
          p: 3,
          boxSizing: "border-box",
        }}
      >
        <LoginLogo />
        <TextBoxView
          locale="pages.sign_in.login"
          sx={{ typography: "h4", mt: 3, fontWeight: "bold" }}
        />
        <TextBoxView
          locale="pages.sign_in.sub_title"
          sx={{ typography: "body1", fontWeight: "light" }}
        />
        <FormView store={signInFormStore} sx={{ my: 5 }}>
          <TextFieldView
            name="email"
            label="pages.sign_in.email_address_label"
            type="email"
            placeholder="pages.sign_in.email_address_placeholder"
            rules="required|email"
          />
          <TextFieldView
            name="password"
            label="pages.sign_in.password_label"
            type="password"
            placeholder="pages.sign_in.password_placeholder"
            rules="required"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CheckBoxView name="remember" label="pages.sign_in.remember" />
            <Link to="/">
              <TextBoxView
                locale="pages.sign_in.forgot_password"
                sx={{
                  typography: "body2",
                  fontWeight: "bold",
                  color: "primary.light",
                }}
              />
            </Link>
          </Box>
          <ButtonView onClick={onSubmitClick} title="pages.sign_in.login" />
        </FormView>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Divider sx={{ flex: 1 }} />
          <TextBoxView
            locale="pages.sign_in.or_login_with"
            sx={{
              typography: "body2",
              fontWeight: "bold",
              color: "secondary",
              flex: 1,
              display: "block",
              textAlign: "center",
            }}
          />
          <Divider sx={{ flex: 1 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            mb: 7,
            mt: 1,
          }}
        >
          <IconView type="windows" />
          <IconView type="google" />
          <IconView type="linkedIn" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextBoxView
            locale="pages.sign_in.have_account"
            sx={{
              typography: "body2",
              fontWeight: "bold",
              color: "secondary",
              display: "block",
              textAlign: "center",
              mr: 1,
            }}
          />
          <TextBoxView
            locale="pages.sign_in.sing_up_here"
            sx={{
              typography: "body2",
              fontWeight: "bold",
              color: "primary.light",
              display: "block",
              textAlign: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SignInPage;
