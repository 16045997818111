import { TypographyOptions } from "@mui/material/styles/createTypography";

const themeTypography: TypographyOptions = {
  fontFamily: "Plus Jakarta Sans",
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 700,
  fontWeightBold: 800,
  h4: {
    fontSize: 27,
    letterSpacing: "-0.02em",
    lineHeight: 2.5,
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 14,
  },
};

export { themeTypography };
