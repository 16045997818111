import _ from "lodash";
import { MediaEntity } from "service";

export const groupedPatientMedia = (data: MediaEntity[]) => {
  const result = _.chain(data)
    .groupBy("timestamp")
    .mapValues((items) =>
      _.chain(items)
        .groupBy((item) => item.exam.name)
        .mapValues((exams) => [exams])
        .value()
    )
    .value();

  return result;
};
