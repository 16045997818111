import { Box } from "@mui/material";
import { authRoutes, routes } from "config";
import { find } from "lodash";
import { BreadcrumbsView, NavigationBarView } from "main";
import { useLocation } from "react-router-dom";

function NavigationView() {
  const location = useLocation();
  const activeRoute = find(routes, { path: location.pathname });
  const isAuthRoute = Boolean(find(authRoutes, { id: activeRoute?.id }));
  return (
    <Box hidden={isAuthRoute}>
      <NavigationBarView />
      <BreadcrumbsView />
    </Box>
  );
}

export default NavigationView;
