import { RouteProps } from "config";
import { PatientsPage } from "main";

export type NavigationRouteType = "patients";

const _navigationRoutes: Record<NavigationRouteType, RouteProps> = {
  patients: {
    id: "patients",
    path: "patients",
    title: "pages.patients.title",
    element: PatientsPage,
    navigationIconType: "list",
    crumb: {
      paths: ["patients"],
      title: "Patient List",
    },
  },
};

export { _navigationRoutes as navigationRoutes };
