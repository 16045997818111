import { makeAutoObservable } from "mobx";
import { Cacheable, cacheLocally } from "service";

interface StorageStoreProps {
  setStorage: (cache: Cacheable) => void;
  getCache: () => Cacheable;
  clearCache: (key?: keyof Cacheable) => void;
  cacheName: string;
  storage: Cacheable;
}

class StorageStore implements StorageStoreProps {
  cacheName = "mauirawdata-cache";
  storage: Cacheable = {};

  constructor() {
    this.setStorage(this.getCache());
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setStorage(cache: Cacheable) {
    this.storage = cache;
  }

  getCache(): Cacheable {
    const cache = localStorage.getItem(this.cacheName) || "{}";
    return JSON.parse(cache);
  }

  cache<T>(key: keyof Cacheable, value: T) {
    return cacheLocally(key, value);
  }

  clearCache(key?: keyof Cacheable) {
    const cache = this.getCache();
    if (key) {
      delete cache[key];
      localStorage.setItem(this.cacheName, JSON.stringify(cache));
    } else {
      localStorage.removeItem(this.cacheName);
    }
  }
}

export const storage = new StorageStore();
