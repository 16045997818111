import { SvgIcon, SvgIconProps } from "@mui/material";

function PatientMenuIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 5 20"
      sx={[{ fill: "none", width: 35, height: 25 }, sx ?? false].flat(2)}
      {...props}
    >
      <path
        fill="#fff"
        color="red"
        d="M5.656 3H12V1H5.656l-1 1 1 1ZM4.938.312C5.125.126 5.375 0 5.655 0H12c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1H5.656a.99.99 0 0 1-.718-.281L3.593 2.375a.53.53 0 0 1 0-.719L4.938.313ZM1 3c-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1Zm0 5c-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1Zm-1 4c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Zm5.656 1H12v-2H5.656l-1 1 1 1Zm-.718-2.688c.187-.187.437-.312.718-.312H12c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1H5.656a.99.99 0 0 1-.718-.281l-1.344-1.344a.53.53 0 0 1 0-.719l1.344-1.344ZM4.655 7l1 1H14V6H5.656l-1 1Zm-1.062-.344 1.344-1.343c.187-.188.437-.313.718-.313H14c.531 0 1 .469 1 1v2c0 .563-.469 1-1 1H5.656a.99.99 0 0 1-.718-.281L3.593 7.375a.53.53 0 0 1 0-.719Z"
      />
    </SvgIcon>
  );
}

export default PatientMenuIcon;
