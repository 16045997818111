import { ReactNode } from "react";
import { Table, TableCell, TableRow } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { TextBoxView } from "main";
import { PatientEntity } from "service";

type PatientsViewProps = {
  patients: PatientEntity[];
  onRowClick: (id: string) => void;
};

function PatientsView({ patients, onRowClick }: PatientsViewProps) {
  const renderTableHeader = (): ReactNode => (
    <TableRow
      sx={{
        width: "100%",
      }}
    >
      <TableCell sx={{ border: "none" }}>
        <TextBoxView
          locale="pages.patients.patient_id"
          sx={{ color: "secondary.dark" }}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <TextBoxView
          locale="pages.patients.patient_full_name"
          sx={{ color: "secondary.dark" }}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <TextBoxView
          locale="pages.patients.patient_hospital"
          sx={{ color: "secondary.dark" }}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <TextBoxView
          locale="pages.patients.patient_exam_date"
          sx={{ color: "secondary.dark" }}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <TextBoxView
          locale="pages.patients.patient_images"
          sx={{ color: "secondary.dark" }}
        />
      </TableCell>
    </TableRow>
  );

  const renderRow = (index: number): ReactNode => {
    const patient = patients[index];
    if (!patient) return null;

    return (
      <>
        <TableCell
          sx={{
            color: "secondary.main",
            border: "none",
            padding: "10px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          {patient.shortId}
        </TableCell>
        <TableCell
          sx={{ color: "secondary.main", border: "none", padding: "10px" }}
        >
          {patient.fullName}
        </TableCell>
        <TableCell
          sx={{ color: "secondary.main", border: "none", padding: "10px" }}
        >
          {patient.hospitalId}
        </TableCell>
        <TableCell
          sx={{ color: "secondary.main", border: "none", padding: "10px" }}
        >
          {patient.lastExamDay}
        </TableCell>
        <TableCell
          sx={{
            color: "secondary.main",
            border: "none",
            padding: "10px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {patient.images}
        </TableCell>
      </>
    );
  };

  return (
    <TableVirtuoso
      style={{
        width: "100%",
        height: "auto",
      }}
      data={patients}
      useWindowScroll
      fixedHeaderContent={renderTableHeader}
      itemContent={renderRow}
      components={{
        TableRow: (props) => {
          const patient = patients[props["data-index"]];
          return (
            <TableRow
              {...props}
              onClick={() => onRowClick(patient.id)}
              sx={{
                border: "none",
                backgroundColor: "black.100",
                marginBottom: "20px",
                boxSizing: "border-box",
                width: "100%",
                padding: 1,
              }}
            />
          );
        },
        Table: (props) => (
          <Table
            {...props}
            sx={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 10px!important",
              backgroundColor: "black.400",
              px: 2,
            }}
          />
        ),
      }}
    />
  );
}

export default PatientsView;
