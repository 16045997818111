import { SxProps } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { TextBoxView } from "main";

type ButtonViewProps = ButtonProps & {
  textBoxStyle?: SxProps;
};

function ButtonView({
  title = "",
  variant = "contained",
  children,
  textBoxStyle,
  ...props
}: ButtonViewProps) {
  return (
    <Button {...props} {...{ variant }}>
      {title && <TextBoxView locale={title} sx={textBoxStyle} />}
      {children}
    </Button>
  );
}

export default ButtonView;
