import React from "react";
import {
  Search,
  HelpOutlineOutlined,
  NotificationsNoneOutlined,
  ExpandMoreOutlined,
  Close,
  PanoramaOutlined,
  List,
  ControlPoint,
  RemoveCircle,
  FileCopyOutlined,
  TuneOutlined,
  GraphicEqOutlined,
  KeyboardArrowRightOutlined,
  Lens,
} from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { HomeLogo, WindowsLogo, GoogleLogo, LinkedInLogo } from "assets";

export type IconViewType =
  | "home"
  | "logo"
  | "search"
  | "windows"
  | "google"
  | "linkedIn"
  | "help"
  | "notification"
  | "expandMore"
  | "close"
  | "done"
  | "filter"
  | "picture"
  | "list"
  | "controlPoint"
  | "remove"
  | "file"
  | "tune"
  | "equalize"
  | "arrowRight"
  | "lens";

type IconViewProps = SvgIconTypeMap["props"] & {
  type: IconViewType;
};

export const IconView: React.FunctionComponent<IconViewProps> = ({
  type,
  ...props
}) => {
  switch (type) {
    case "home":
      return <HomeLogo {...props} />;
    case "search":
      return <Search {...props} />;
    case "windows":
      return <WindowsLogo {...props} />;
    case "google":
      return <GoogleLogo {...props} />;
    case "linkedIn":
      return <LinkedInLogo {...props} />;
    case "help":
      return <HelpOutlineOutlined {...props} />;
    case "notification":
      return <NotificationsNoneOutlined {...props} />;
    case "expandMore":
      return <ExpandMoreOutlined {...props} />;
    case "close":
    case "done":
      return <Close {...props} />;
    case "filter":
      return <FilterAltOutlinedIcon {...props} />;
    case "picture":
      return <PanoramaOutlined {...props} />;
    case "list":
      return <List {...props} />;
    case "controlPoint":
      return <ControlPoint {...props} />;
    case "remove":
      return <RemoveCircle {...props} />;
    case "file":
      return <FileCopyOutlined {...props} />;
    case "tune":
      return <TuneOutlined {...props} />;
    case "equalize":
      return <GraphicEqOutlined {...props} />;
    case "arrowRight":
      return <KeyboardArrowRightOutlined {...props} />;
    case "lens":
      return <Lens {...props} />;
    default:
      return null;
  }
};
