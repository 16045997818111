import { Outlet, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationView from "./NavigationView";
import { useContext, useEffect } from "react";
import { AppStoreContext } from "service";
import { routes } from "config";

function RootView() {
  const { authStore } = useContext(AppStoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStore.loggedIn) {
      navigate(routes.signIn.path);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <NavigationView />
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default RootView;
