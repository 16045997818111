import {
  client,
  DataSetEntity,
  PUEntity,
  ServerResponseEntity,
  TaskEntity,
  Url,
} from "service";

async function _getAvailablePU(): Promise<ServerResponseEntity<PUEntity>> {
  return await client("users").get(Url.processingUnitAvailable);
}

async function _loadDataset(
  key: string,
  isLocal: boolean
): Promise<ServerResponseEntity<DataSetEntity>> {
  const params = { key: key, local: isLocal };
  return await client("puService").post(Url.loadDataSet, params);
}

async function _getTaskStatus(
  taskId: string
): Promise<ServerResponseEntity<TaskEntity>> {
  const params = { id: taskId };
  return await client("puService").post(Url.taskStatus, params);
}

async function _cancelTask(taskId: string) {
  const params = { id: taskId };
  return await client("puService").post(Url.cancelTask, params);
}

async function _getAvailableDatasets(): Promise<
  ServerResponseEntity<string[]>
> {
  return await client("puService").get(Url.availableDatasets);
}

export {
  _getAvailablePU as getAvailablePU,
  _loadDataset as loadDataset,
  _getTaskStatus as getTaskStatus,
  _cancelTask as cancelTask,
  _getAvailableDatasets as getAvailableDatasets,
};
