import { Stack, StackProps } from "@mui/material";
import { createContext, useContext } from "react";
import { FormStore } from "service";

export type FormViewProps = StackProps & {
  store: FormStore;
  children?: React.ReactNode | React.ReactNode[];
};

// Create the context
const FormViewContext = createContext<FormStore | null>(null);

// Create a custom hook for using the FormViewContext
export const useFormViewContext = () => {
  const context = useContext(FormViewContext);
  if (!context) {
    throw new Error("useFormViewContext must be used within a FormViewContext");
  }
  return context;
};

function FormView({ store, children, sx, ...props }: FormViewProps) {
  return (
    <FormViewContext.Provider value={store}>
      <Stack
        spacing={3}
        sx={[
          { display: "flex", flexDirection: "column", width: "100%" },
          sx ?? false,
        ].flat(2)}
        {...props}
      >
        {children}
      </Stack>
    </FormViewContext.Provider>
  );
}

export default FormView;
