import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormViewContext } from "./FormView";
import { useEffect } from "react";
import { Observer } from "mobx-react";
import { useIntl } from "react-intl";
import { TextBoxView } from "main";

export type CheckBoxViewProps = Omit<CheckboxProps, "label"> & {
  name: string;
  label?: string;
  rules?: string;
  defaultValue?: boolean;
};

function CheckBoxView({
  name,
  label,
  rules,
  hidden,
  defaultValue,
}: CheckBoxViewProps) {
  const formStore = useFormViewContext();
  const intl = useIntl();

  useEffect(() => {
    formStore?.setRule(name, rules);
    formStore.setAttributeName(
      name,
      label ? intl.formatMessage({ id: label }) : name
    );
    const value = defaultValue as boolean;
    if (value) {
      formStore.setValue(name, value);
    }
  }, [formStore, defaultValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    formStore.setValue(name, checked);
  };

  return (
    <Box {...{ hidden }}>
      <Observer>
        {() => (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formStore.getValue(name) ?? defaultValue ?? false}
                  onChange={onChange}
                  name={name}
                />
              }
              label={<TextBoxView locale={label} />}
            />
            <FormHelperText sx={{ mx: 1 }} error>
              {formStore?.errors.get(name)}
            </FormHelperText>
          </>
        )}
      </Observer>
    </Box>
  );
}

export default CheckBoxView;
