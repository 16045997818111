import { RouteProps } from "config";
import { SignInPage } from "main";

export type AuthRouteType = "signIn";

const _authRoutes: Record<AuthRouteType, RouteProps> = {
  signIn: {
    id: "signIn",
    path: "/",
    title: "",
    element: SignInPage,
  },
};

export { _authRoutes as authRoutes };
