import { registerValidators } from "utils";
import { createElement, useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Observer } from "mobx-react";
import { AppStoreContext } from "service";
import { lang, routes } from "config";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { Box } from "@mui/material";
import { ErrorPage, RootView, SnackbarView } from "main";
import { map } from "lodash";
import { reaction } from "mobx";

function App() {
  const { uiStore, authStore, puStore } = useContext(AppStoreContext);

  useEffect(() => {
    registerValidators();
  }, []);

  useEffect(() => {
    let pingPUInterval: NodeJS.Timer;

    reaction(
      () => authStore.loggedIn, // Function to observe
      (newValue, oldValue) => {
        if (newValue === oldValue) {
          return;
        }
        if (newValue) {
          puStore.getAvailablePU();

          pingPUInterval = setInterval(() => {
            puStore.getAvailablePU();
          }, 3000);
        } else {
          clearInterval(pingPUInterval);
        }
      },
      {
        fireImmediately: true,
      }
    );
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootView />,
      errorElement: <ErrorPage />,
      children: map(routes, (route) => {
        return {
          id: route.id,
          path: route.path,
          element: createElement(route.element),
          loader: route.loader,
          handle: {
            crumb: route.crumb,
          },
        } as unknown as RouteObject;
      }),
    },
  ]);

  return (
    <Observer>
      {() => (
        <IntlProvider
          locale={uiStore.locale}
          defaultLocale="en"
          messages={lang[uiStore.locale]}
        >
          <SnackbarView {...uiStore.message} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "relative",
              overflow: "auto",
              bgcolor: "background.paper-1",
            }}
          >
            <RouterProvider router={router} />
          </Box>
        </IntlProvider>
      )}
    </Observer>
  );
}

export default App;
