// source: src/proto/k3900.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global =
  (typeof globalThis !== "undefined" && globalThis) ||
  (typeof window !== "undefined" && window) ||
  (typeof global !== "undefined" && global) ||
  (typeof self !== "undefined" && self) ||
  function () {
    return this;
  }.call(null) ||
  Function("return this")();

var google_protobuf_any_pb = require("google-protobuf/google/protobuf/any_pb.js");
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol("proto.k3900.AboutMsg", null, global);
goog.exportSymbol("proto.k3900.BFInterp", null, global);
goog.exportSymbol("proto.k3900.BeamformParameters", null, global);
goog.exportSymbol("proto.k3900.BeamformerParameter", null, global);
goog.exportSymbol("proto.k3900.BeamformerParametersRequest", null, global);
goog.exportSymbol("proto.k3900.BeamformerParametersResponse", null, global);
goog.exportSymbol("proto.k3900.BlankRequest", null, global);
goog.exportSymbol("proto.k3900.BoardInfo", null, global);
goog.exportSymbol("proto.k3900.BoardParameterType", null, global);
goog.exportSymbol("proto.k3900.BoardTestRequest", null, global);
goog.exportSymbol("proto.k3900.BoardTestResults", null, global);
goog.exportSymbol("proto.k3900.BoardVoltageMonitor", null, global);
goog.exportSymbol("proto.k3900.CalAction", null, global);
goog.exportSymbol("proto.k3900.ClearErrorsRequest", null, global);
goog.exportSymbol("proto.k3900.ClipRequest", null, global);
goog.exportSymbol("proto.k3900.Credentials", null, global);
goog.exportSymbol("proto.k3900.CursorMoveRequest", null, global);
goog.exportSymbol("proto.k3900.DataFile", null, global);
goog.exportSymbol("proto.k3900.DiagnosticRequest", null, global);
goog.exportSymbol("proto.k3900.DiagnosticTestList", null, global);
goog.exportSymbol("proto.k3900.DiagnosticTestsRequest", null, global);
goog.exportSymbol("proto.k3900.DownloadRequest", null, global);
goog.exportSymbol("proto.k3900.EditMeasurementRequest", null, global);
goog.exportSymbol("proto.k3900.ElemList", null, global);
goog.exportSymbol("proto.k3900.EngControlMsg", null, global);
goog.exportSymbol("proto.k3900.EngineeringCmdRequest", null, global);
goog.exportSymbol("proto.k3900.ErrorReport", null, global);
goog.exportSymbol("proto.k3900.Exam", null, global);
goog.exportSymbol("proto.k3900.ExamFileRequest", null, global);
goog.exportSymbol("proto.k3900.ExamRequest", null, global);
goog.exportSymbol("proto.k3900.ExamStore", null, global);
goog.exportSymbol("proto.k3900.ExamStoresRequest", null, global);
goog.exportSymbol("proto.k3900.ExamStoresTypeRequest", null, global);
goog.exportSymbol("proto.k3900.ExamStoresTypes", null, global);
goog.exportSymbol("proto.k3900.ExternalStorageMsg", null, global);
goog.exportSymbol("proto.k3900.Fan", null, global);
goog.exportSymbol("proto.k3900.FanController", null, global);
goog.exportSymbol("proto.k3900.FileType", null, global);
goog.exportSymbol("proto.k3900.Filter", null, global);
goog.exportSymbol("proto.k3900.FilterList", null, global);
goog.exportSymbol("proto.k3900.FilterOrder", null, global);
goog.exportSymbol("proto.k3900.FilterRequest", null, global);
goog.exportSymbol("proto.k3900.FloatArrayParam", null, global);
goog.exportSymbol("proto.k3900.FloatParam", null, global);
goog.exportSymbol("proto.k3900.FloatRequest", null, global);
goog.exportSymbol("proto.k3900.FpgaStatus", null, global);
goog.exportSymbol("proto.k3900.FrameMsg", null, global);
goog.exportSymbol("proto.k3900.FrameRequest", null, global);
goog.exportSymbol("proto.k3900.HighVoltageReadings", null, global);
goog.exportSymbol("proto.k3900.ImageChunk", null, global);
goog.exportSymbol("proto.k3900.ImageFormat", null, global);
goog.exportSymbol("proto.k3900.ImageRequest", null, global);
goog.exportSymbol("proto.k3900.IntMsg", null, global);
goog.exportSymbol("proto.k3900.IntParam", null, global);
goog.exportSymbol("proto.k3900.LoginRequest", null, global);
goog.exportSymbol("proto.k3900.LogoutRequest", null, global);
goog.exportSymbol("proto.k3900.MaskMsg", null, global);
goog.exportSymbol("proto.k3900.MasterGainRange", null, global);
goog.exportSymbol("proto.k3900.Measurement", null, global);
goog.exportSymbol("proto.k3900.MeasurementRequest", null, global);
goog.exportSymbol("proto.k3900.Measurements", null, global);
goog.exportSymbol("proto.k3900.MediaControlType", null, global);
goog.exportSymbol("proto.k3900.MediaFile", null, global);
goog.exportSymbol("proto.k3900.MediaFileRequest", null, global);
goog.exportSymbol("proto.k3900.MediaRequest", null, global);
goog.exportSymbol("proto.k3900.MediaUpdateRequest", null, global);
goog.exportSymbol("proto.k3900.NameRequest", null, global);
goog.exportSymbol("proto.k3900.Patient", null, global);
goog.exportSymbol("proto.k3900.PatientRequest", null, global);
goog.exportSymbol("proto.k3900.Ping", null, global);
goog.exportSymbol("proto.k3900.PingDownloadDataRequest", null, global);
goog.exportSymbol("proto.k3900.PingRequest", null, global);
goog.exportSymbol("proto.k3900.Pixel", null, global);
goog.exportSymbol("proto.k3900.PostProcessingParameters", null, global);
goog.exportSymbol("proto.k3900.PresetList", null, global);
goog.exportSymbol("proto.k3900.PresetResponse", null, global);
goog.exportSymbol("proto.k3900.ProbeArray", null, global);
goog.exportSymbol("proto.k3900.ProbeArrayCal", null, global);
goog.exportSymbol("proto.k3900.ProbeCalRequest", null, global);
goog.exportSymbol("proto.k3900.ProbeElement", null, global);
goog.exportSymbol("proto.k3900.ProbeFile", null, global);
goog.exportSymbol("proto.k3900.ProbeInfo", null, global);
goog.exportSymbol("proto.k3900.ProbeList", null, global);
goog.exportSymbol("proto.k3900.ProbeRequest", null, global);
goog.exportSymbol("proto.k3900.Progress", null, global);
goog.exportSymbol("proto.k3900.ProgressRequest", null, global);
goog.exportSymbol("proto.k3900.ProgressType", null, global);
goog.exportSymbol("proto.k3900.QueryRequest", null, global);
goog.exportSymbol("proto.k3900.RawCaptureMetadata", null, global);
goog.exportSymbol("proto.k3900.RawFrameMetadata", null, global);
goog.exportSymbol("proto.k3900.ResponseMsg", null, global);
goog.exportSymbol("proto.k3900.SaveRequest", null, global);
goog.exportSymbol("proto.k3900.ShutdownRequest", null, global);
goog.exportSymbol("proto.k3900.SoftwareUpdateRequest", null, global);
goog.exportSymbol("proto.k3900.State", null, global);
goog.exportSymbol("proto.k3900.StorageRequest", null, global);
goog.exportSymbol("proto.k3900.StringList", null, global);
goog.exportSymbol("proto.k3900.SystemInfo", null, global);
goog.exportSymbol("proto.k3900.SystemNameChange", null, global);
goog.exportSymbol("proto.k3900.SystemReport", null, global);
goog.exportSymbol("proto.k3900.SystemState", null, global);
goog.exportSymbol("proto.k3900.SystemStateRequest", null, global);
goog.exportSymbol("proto.k3900.SystemStatus", null, global);
goog.exportSymbol("proto.k3900.TECMsg", null, global);
goog.exportSymbol("proto.k3900.ToggleParam", null, global);
goog.exportSymbol("proto.k3900.UploadStatusResponse", null, global);
goog.exportSymbol("proto.k3900.Vector3", null, global);
goog.exportSymbol("proto.k3900.VersionInfo", null, global);
goog.exportSymbol("proto.k3900.Voltage", null, global);
goog.exportSymbol("proto.k3900.WriteProbeRequest", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Vector3 = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Vector3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Vector3.displayName = "proto.k3900.Vector3";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.RawFrameMetadata = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.RawFrameMetadata.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.RawFrameMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.RawFrameMetadata.displayName = "proto.k3900.RawFrameMetadata";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Filter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Filter.displayName = "proto.k3900.Filter";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ProbeElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeElement.displayName = "proto.k3900.ProbeElement";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeArray = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ProbeArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeArray.displayName = "proto.k3900.ProbeArray";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeArrayCal = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ProbeArrayCal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeArrayCal.displayName = "proto.k3900.ProbeArrayCal";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeCalRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ProbeCalRequest.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ProbeCalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeCalRequest.displayName = "proto.k3900.ProbeCalRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeFile = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ProbeFile.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ProbeFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeFile.displayName = "proto.k3900.ProbeFile";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeInfo = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ProbeInfo.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ProbeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeInfo.displayName = "proto.k3900.ProbeInfo";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BeamformParameters = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.BeamformParameters.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.BeamformParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BeamformParameters.displayName = "proto.k3900.BeamformParameters";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PostProcessingParameters = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.PostProcessingParameters.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.PostProcessingParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PostProcessingParameters.displayName =
    "proto.k3900.PostProcessingParameters";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.RawCaptureMetadata = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.RawCaptureMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.RawCaptureMetadata.displayName = "proto.k3900.RawCaptureMetadata";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Ping = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Ping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Ping.displayName = "proto.k3900.Ping";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ResponseMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ResponseMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ResponseMsg.displayName = "proto.k3900.ResponseMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ImageFormat = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ImageFormat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ImageFormat.displayName = "proto.k3900.ImageFormat";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.UploadStatusResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.UploadStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.UploadStatusResponse.displayName =
    "proto.k3900.UploadStatusResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.DownloadRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.DownloadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.DownloadRequest.displayName = "proto.k3900.DownloadRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PingDownloadDataRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.PingDownloadDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PingDownloadDataRequest.displayName =
    "proto.k3900.PingDownloadDataRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FloatRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.FloatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FloatRequest.displayName = "proto.k3900.FloatRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.DataFile = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.DataFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.DataFile.displayName = "proto.k3900.DataFile";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemNameChange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.SystemNameChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemNameChange.displayName = "proto.k3900.SystemNameChange";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BoardVoltageMonitor = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.BoardVoltageMonitor.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.BoardVoltageMonitor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BoardVoltageMonitor.displayName =
    "proto.k3900.BoardVoltageMonitor";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MasterGainRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MasterGainRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MasterGainRange.displayName = "proto.k3900.MasterGainRange";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Voltage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Voltage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Voltage.displayName = "proto.k3900.Voltage";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BoardTestRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.BoardTestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BoardTestRequest.displayName = "proto.k3900.BoardTestRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BoardTestResults = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.BoardTestResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BoardTestResults.displayName = "proto.k3900.BoardTestResults";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.SystemReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemReport.displayName = "proto.k3900.SystemReport";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BoardInfo = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.BoardInfo.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.BoardInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BoardInfo.displayName = "proto.k3900.BoardInfo";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BoardParameterType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.BoardParameterType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BoardParameterType.displayName = "proto.k3900.BoardParameterType";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemInfo = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.SystemInfo.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.SystemInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemInfo.displayName = "proto.k3900.SystemInfo";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.EngineeringCmdRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.EngineeringCmdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.EngineeringCmdRequest.displayName =
    "proto.k3900.EngineeringCmdRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PresetResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.PresetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PresetResponse.displayName = "proto.k3900.PresetResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.IntMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.IntMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.IntMsg.displayName = "proto.k3900.IntMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.TECMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.TECMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.TECMsg.displayName = "proto.k3900.TECMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.EngControlMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.EngControlMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.EngControlMsg.displayName = "proto.k3900.EngControlMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.AboutMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.AboutMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.AboutMsg.displayName = "proto.k3900.AboutMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.VersionInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.VersionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.VersionInfo.displayName = "proto.k3900.VersionInfo";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Credentials = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Credentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Credentials.displayName = "proto.k3900.Credentials";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.LoginRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.LoginRequest.displayName = "proto.k3900.LoginRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.LogoutRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.LogoutRequest.displayName = "proto.k3900.LogoutRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExternalStorageMsg = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ExternalStorageMsg.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ExternalStorageMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExternalStorageMsg.displayName = "proto.k3900.ExternalStorageMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.StorageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.StorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.StorageRequest.displayName = "proto.k3900.StorageRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PingRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.PingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PingRequest.displayName = "proto.k3900.PingRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ElemList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ElemList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ElemList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ElemList.displayName = "proto.k3900.ElemList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MaskMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MaskMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MaskMsg.displayName = "proto.k3900.MaskMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BlankRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.BlankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BlankRequest.displayName = "proto.k3900.BlankRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ProbeList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ProbeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeList.displayName = "proto.k3900.ProbeList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PresetList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.PresetList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.PresetList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PresetList.displayName = "proto.k3900.PresetList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.StringList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.StringList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.StringList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.StringList.displayName = "proto.k3900.StringList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.NameRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.NameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.NameRequest.displayName = "proto.k3900.NameRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProbeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ProbeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProbeRequest.displayName = "proto.k3900.ProbeRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.WriteProbeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.WriteProbeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.WriteProbeRequest.displayName = "proto.k3900.WriteProbeRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FilterRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.FilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FilterRequest.displayName = "proto.k3900.FilterRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ClearErrorsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ClearErrorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ClearErrorsRequest.displayName = "proto.k3900.ClearErrorsRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.DiagnosticTestsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.DiagnosticTestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.DiagnosticTestsRequest.displayName =
    "proto.k3900.DiagnosticTestsRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MediaFileRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MediaFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MediaFileRequest.displayName = "proto.k3900.MediaFileRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SoftwareUpdateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.SoftwareUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SoftwareUpdateRequest.displayName =
    "proto.k3900.SoftwareUpdateRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MediaUpdateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MediaUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MediaUpdateRequest.displayName = "proto.k3900.MediaUpdateRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MediaFile = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MediaFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MediaFile.displayName = "proto.k3900.MediaFile";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ProgressRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ProgressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ProgressRequest.displayName = "proto.k3900.ProgressRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Progress = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Progress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Progress.displayName = "proto.k3900.Progress";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.DiagnosticTestList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.DiagnosticTestList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.DiagnosticTestList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.DiagnosticTestList.displayName = "proto.k3900.DiagnosticTestList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.DiagnosticRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.DiagnosticRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.DiagnosticRequest.displayName = "proto.k3900.DiagnosticRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamStore = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamStore.displayName = "proto.k3900.ExamStore";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SaveRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.SaveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SaveRequest.displayName = "proto.k3900.SaveRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ClipRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ClipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ClipRequest.displayName = "proto.k3900.ClipRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.QueryRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.QueryRequest.displayName = "proto.k3900.QueryRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.PatientRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.PatientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.PatientRequest.displayName = "proto.k3900.PatientRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Patient = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Patient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Patient.displayName = "proto.k3900.Patient";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamStoresRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamStoresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamStoresRequest.displayName = "proto.k3900.ExamStoresRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamStoresTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamStoresTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamStoresTypeRequest.displayName =
    "proto.k3900.ExamStoresTypeRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamStoresTypes = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamStoresTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamStoresTypes.displayName = "proto.k3900.ExamStoresTypes";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamFileRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamFileRequest.displayName = "proto.k3900.ExamFileRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Exam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Exam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Exam.displayName = "proto.k3900.Exam";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ExamRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ExamRequest.displayName = "proto.k3900.ExamRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FrameMsg = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.FrameMsg.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.FrameMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FrameMsg.displayName = "proto.k3900.FrameMsg";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FilterList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.FilterList.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.FilterList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FilterList.displayName = "proto.k3900.FilterList";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Pixel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Pixel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Pixel.displayName = "proto.k3900.Pixel";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FrameRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.FrameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FrameRequest.displayName = "proto.k3900.FrameRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MeasurementRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MeasurementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MeasurementRequest.displayName = "proto.k3900.MeasurementRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.EditMeasurementRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.EditMeasurementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.EditMeasurementRequest.displayName =
    "proto.k3900.EditMeasurementRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.CursorMoveRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.CursorMoveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.CursorMoveRequest.displayName = "proto.k3900.CursorMoveRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ShutdownRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ShutdownRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ShutdownRequest.displayName = "proto.k3900.ShutdownRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemStateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.SystemStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemStateRequest.displayName = "proto.k3900.SystemStateRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ImageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ImageRequest.displayName = "proto.k3900.ImageRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Measurement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Measurement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Measurement.displayName = "proto.k3900.Measurement";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Measurements = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.Measurements.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.Measurements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Measurements.displayName = "proto.k3900.Measurements";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.MediaRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.MediaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.MediaRequest.displayName = "proto.k3900.MediaRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FpgaStatus = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.FpgaStatus.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.FpgaStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FpgaStatus.displayName = "proto.k3900.FpgaStatus";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemStatus = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.SystemStatus.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.SystemStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemStatus.displayName = "proto.k3900.SystemStatus";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.Fan = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.Fan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.Fan.displayName = "proto.k3900.Fan";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FanController = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.FanController.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.FanController, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FanController.displayName = "proto.k3900.FanController";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ErrorReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ErrorReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ErrorReport.displayName = "proto.k3900.ErrorReport";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.HighVoltageReadings = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.HighVoltageReadings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.HighVoltageReadings.displayName =
    "proto.k3900.HighVoltageReadings";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ImageChunk = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.ImageChunk.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.ImageChunk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ImageChunk.displayName = "proto.k3900.ImageChunk";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.SystemState = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.SystemState.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.SystemState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.SystemState.displayName = "proto.k3900.SystemState";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BeamformerParameter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.BeamformerParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BeamformerParameter.displayName =
    "proto.k3900.BeamformerParameter";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BeamformerParametersRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.BeamformerParametersRequest.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.BeamformerParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BeamformerParametersRequest.displayName =
    "proto.k3900.BeamformerParametersRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.BeamformerParametersResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.k3900.BeamformerParametersResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.k3900.BeamformerParametersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.BeamformerParametersResponse.displayName =
    "proto.k3900.BeamformerParametersResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FloatParam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.FloatParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FloatParam.displayName = "proto.k3900.FloatParam";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.IntParam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.IntParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.IntParam.displayName = "proto.k3900.IntParam";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.ToggleParam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.ToggleParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.ToggleParam.displayName = "proto.k3900.ToggleParam";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.k3900.FloatArrayParam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.k3900.FloatArrayParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.k3900.FloatArrayParam.displayName = "proto.k3900.FloatArrayParam";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Vector3.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Vector3.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Vector3} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Vector3.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Vector3}
 */
proto.k3900.Vector3.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Vector3();
  return proto.k3900.Vector3.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Vector3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Vector3}
 */
proto.k3900.Vector3.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setX(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setY(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setZ(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Vector3.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Vector3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Vector3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Vector3.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
};

/**
 * optional float x = 1;
 * @return {number}
 */
proto.k3900.Vector3.prototype.getX = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Vector3} returns this
 */
proto.k3900.Vector3.prototype.setX = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional float y = 2;
 * @return {number}
 */
proto.k3900.Vector3.prototype.getY = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Vector3} returns this
 */
proto.k3900.Vector3.prototype.setY = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional float z = 3;
 * @return {number}
 */
proto.k3900.Vector3.prototype.getZ = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Vector3} returns this
 */
proto.k3900.Vector3.prototype.setZ = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.RawFrameMetadata.repeatedFields_ = [2, 5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.RawFrameMetadata.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.RawFrameMetadata.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.RawFrameMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.RawFrameMetadata.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        nSamplesPerPing: jspb.Message.getFieldWithDefault(msg, 1, 0),
        nPings: jspb.Message.getFieldWithDefault(msg, 4, 0),
        pingsList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
        transmitSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
        channelMapList:
          (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
        nFiles: jspb.Message.getFieldWithDefault(msg, 6, 0),
        nRxsPerFile: jspb.Message.getFieldWithDefault(msg, 7, 0),
        sampleRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
        sampleSize: jspb.Message.getFieldWithDefault(msg, 9, 0),
        acousticPower: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          10,
          0.0
        ),
        txFreq: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
        masterGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
        pingMode: jspb.Message.getFieldWithDefault(msg, 13, 0),
        fps: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
        lna: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
        vga: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
        iq: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.RawFrameMetadata}
 */
proto.k3900.RawFrameMetadata.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.RawFrameMetadata();
  return proto.k3900.RawFrameMetadata.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.RawFrameMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.RawFrameMetadata}
 */
proto.k3900.RawFrameMetadata.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNSamplesPerPing(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNPings(value);
        break;
      case 2:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addPings(values[i]);
        }
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTransmitSize(value);
        break;
      case 5:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addChannelMap(values[i]);
        }
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNFiles(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNRxsPerFile(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setSampleRate(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSampleSize(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setAcousticPower(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTxFreq(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setMasterGain(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPingMode(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setFps(value);
        break;
      case 15:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setLna(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setVga(value);
        break;
      case 17:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIq(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.RawFrameMetadata.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.RawFrameMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.RawFrameMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.RawFrameMetadata.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getNSamplesPerPing();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getNPings();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getPingsList();
  if (f.length > 0) {
    writer.writePackedInt32(2, f);
  }
  f = message.getTransmitSize();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getChannelMapList();
  if (f.length > 0) {
    writer.writePackedInt32(5, f);
  }
  f = message.getNFiles();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getNRxsPerFile();
  if (f !== 0) {
    writer.writeInt32(7, f);
  }
  f = message.getSampleRate();
  if (f !== 0.0) {
    writer.writeDouble(8, f);
  }
  f = message.getSampleSize();
  if (f !== 0) {
    writer.writeInt32(9, f);
  }
  f = message.getAcousticPower();
  if (f !== 0.0) {
    writer.writeFloat(10, f);
  }
  f = message.getTxFreq();
  if (f !== 0.0) {
    writer.writeFloat(11, f);
  }
  f = message.getMasterGain();
  if (f !== 0.0) {
    writer.writeFloat(12, f);
  }
  f = message.getPingMode();
  if (f !== 0) {
    writer.writeInt32(13, f);
  }
  f = message.getFps();
  if (f !== 0.0) {
    writer.writeFloat(14, f);
  }
  f = message.getLna();
  if (f !== 0.0) {
    writer.writeFloat(15, f);
  }
  f = message.getVga();
  if (f !== 0.0) {
    writer.writeFloat(16, f);
  }
  f = message.getIq();
  if (f) {
    writer.writeBool(17, f);
  }
};

/**
 * optional int32 n_samples_per_ping = 1;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getNSamplesPerPing = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setNSamplesPerPing = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 n_pings = 4;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getNPings = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setNPings = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * repeated int32 pings = 2;
 * @return {!Array<number>}
 */
proto.k3900.RawFrameMetadata.prototype.getPingsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setPingsList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.addPings = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.clearPingsList = function () {
  return this.setPingsList([]);
};

/**
 * optional int32 transmit_size = 3;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getTransmitSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setTransmitSize = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * repeated int32 channel_map = 5;
 * @return {!Array<number>}
 */
proto.k3900.RawFrameMetadata.prototype.getChannelMapList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setChannelMapList = function (value) {
  return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.addChannelMap = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.clearChannelMapList = function () {
  return this.setChannelMapList([]);
};

/**
 * optional int32 n_files = 6;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getNFiles = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setNFiles = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional int32 n_rxs_per_file = 7;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getNRxsPerFile = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setNRxsPerFile = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional double sample_rate = 8;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getSampleRate = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setSampleRate = function (value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional int32 sample_size = 9;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getSampleSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setSampleSize = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * optional float acoustic_power = 10;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getAcousticPower = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setAcousticPower = function (value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional float tx_freq = 11;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getTxFreq = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setTxFreq = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional float master_gain = 12;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getMasterGain = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setMasterGain = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * optional int32 ping_mode = 13;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getPingMode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setPingMode = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional float fps = 14;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getFps = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setFps = function (value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};

/**
 * optional float lna = 15;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getLna = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setLna = function (value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};

/**
 * optional float vga = 16;
 * @return {number}
 */
proto.k3900.RawFrameMetadata.prototype.getVga = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setVga = function (value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};

/**
 * optional bool iq = 17;
 * @return {boolean}
 */
proto.k3900.RawFrameMetadata.prototype.getIq = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 17, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.RawFrameMetadata} returns this
 */
proto.k3900.RawFrameMetadata.prototype.setIq = function (value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Filter.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Filter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Filter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Filter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        bypass: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        orderSel: jspb.Message.getFieldWithDefault(msg, 2, 0),
        freqSel: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        typeSel: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Filter}
 */
proto.k3900.Filter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Filter();
  return proto.k3900.Filter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Filter}
 */
proto.k3900.Filter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setBypass(value);
        break;
      case 2:
        var value = /** @type {!proto.k3900.FilterOrder} */ (reader.readEnum());
        msg.setOrderSel(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setFreqSel(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTypeSel(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Filter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Filter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBypass();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getOrderSel();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getFreqSel();
  if (f !== 0.0) {
    writer.writeDouble(3, f);
  }
  f = message.getTypeSel();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
};

/**
 * optional bool bypass = 1;
 * @return {boolean}
 */
proto.k3900.Filter.prototype.getBypass = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.Filter} returns this
 */
proto.k3900.Filter.prototype.setBypass = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional FilterOrder order_sel = 2;
 * @return {!proto.k3900.FilterOrder}
 */
proto.k3900.Filter.prototype.getOrderSel = function () {
  return /** @type {!proto.k3900.FilterOrder} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.k3900.FilterOrder} value
 * @return {!proto.k3900.Filter} returns this
 */
proto.k3900.Filter.prototype.setOrderSel = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional double freq_sel = 3;
 * @return {number}
 */
proto.k3900.Filter.prototype.getFreqSel = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Filter} returns this
 */
proto.k3900.Filter.prototype.setFreqSel = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional int32 type_sel = 4;
 * @return {number}
 */
proto.k3900.Filter.prototype.getTypeSel = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Filter} returns this
 */
proto.k3900.Filter.prototype.setTypeSel = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeElement.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeElement.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeElement.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        loc:
          (f = msg.getLoc()) &&
          proto.k3900.Vector3.toObject(includeInstance, f),
        normal:
          (f = msg.getNormal()) &&
          proto.k3900.Vector3.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeElement}
 */
proto.k3900.ProbeElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeElement();
  return proto.k3900.ProbeElement.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeElement}
 */
proto.k3900.ProbeElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Vector3();
        reader.readMessage(
          value,
          proto.k3900.Vector3.deserializeBinaryFromReader
        );
        msg.setLoc(value);
        break;
      case 2:
        var value = new proto.k3900.Vector3();
        reader.readMessage(
          value,
          proto.k3900.Vector3.deserializeBinaryFromReader
        );
        msg.setNormal(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLoc();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Vector3.serializeBinaryToWriter);
  }
  f = message.getNormal();
  if (f != null) {
    writer.writeMessage(2, f, proto.k3900.Vector3.serializeBinaryToWriter);
  }
};

/**
 * optional Vector3 loc = 1;
 * @return {?proto.k3900.Vector3}
 */
proto.k3900.ProbeElement.prototype.getLoc = function () {
  return /** @type{?proto.k3900.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Vector3, 1)
  );
};

/**
 * @param {?proto.k3900.Vector3|undefined} value
 * @return {!proto.k3900.ProbeElement} returns this
 */
proto.k3900.ProbeElement.prototype.setLoc = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ProbeElement} returns this
 */
proto.k3900.ProbeElement.prototype.clearLoc = function () {
  return this.setLoc(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ProbeElement.prototype.hasLoc = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Vector3 normal = 2;
 * @return {?proto.k3900.Vector3}
 */
proto.k3900.ProbeElement.prototype.getNormal = function () {
  return /** @type{?proto.k3900.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Vector3, 2)
  );
};

/**
 * @param {?proto.k3900.Vector3|undefined} value
 * @return {!proto.k3900.ProbeElement} returns this
 */
proto.k3900.ProbeElement.prototype.setNormal = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ProbeElement} returns this
 */
proto.k3900.ProbeElement.prototype.clearNormal = function () {
  return this.setNormal(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ProbeElement.prototype.hasNormal = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeArray.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeArray.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeArray} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeArray.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        nelems: jspb.Message.getFieldWithDefault(msg, 2, 0),
        angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        loc:
          (f = msg.getLoc()) &&
          proto.k3900.Vector3.toObject(includeInstance, f),
        roc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeArray}
 */
proto.k3900.ProbeArray.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeArray();
  return proto.k3900.ProbeArray.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeArray}
 */
proto.k3900.ProbeArray.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setPitch(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNelems(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setAngle(value);
        break;
      case 4:
        var value = new proto.k3900.Vector3();
        reader.readMessage(
          value,
          proto.k3900.Vector3.deserializeBinaryFromReader
        );
        msg.setLoc(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setRoc(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeArray.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeArray.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPitch();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getNelems();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getLoc();
  if (f != null) {
    writer.writeMessage(4, f, proto.k3900.Vector3.serializeBinaryToWriter);
  }
  f = message.getRoc();
  if (f !== 0.0) {
    writer.writeFloat(5, f);
  }
};

/**
 * optional float pitch = 1;
 * @return {number}
 */
proto.k3900.ProbeArray.prototype.getPitch = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.setPitch = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional int32 nelems = 2;
 * @return {number}
 */
proto.k3900.ProbeArray.prototype.getNelems = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.setNelems = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional float angle = 3;
 * @return {number}
 */
proto.k3900.ProbeArray.prototype.getAngle = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.setAngle = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional Vector3 loc = 4;
 * @return {?proto.k3900.Vector3}
 */
proto.k3900.ProbeArray.prototype.getLoc = function () {
  return /** @type{?proto.k3900.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Vector3, 4)
  );
};

/**
 * @param {?proto.k3900.Vector3|undefined} value
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.setLoc = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.clearLoc = function () {
  return this.setLoc(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ProbeArray.prototype.hasLoc = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional float roc = 5;
 * @return {number}
 */
proto.k3900.ProbeArray.prototype.getRoc = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeArray} returns this
 */
proto.k3900.ProbeArray.prototype.setRoc = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeArrayCal.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ProbeArrayCal.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeArrayCal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeArrayCal.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        geometry:
          (f = msg.getGeometry()) &&
          proto.k3900.ProbeArray.toObject(includeInstance, f),
        deactivateRx: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        deactivateTx: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeArrayCal}
 */
proto.k3900.ProbeArrayCal.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeArrayCal();
  return proto.k3900.ProbeArrayCal.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeArrayCal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeArrayCal}
 */
proto.k3900.ProbeArrayCal.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.ProbeArray();
        reader.readMessage(
          value,
          proto.k3900.ProbeArray.deserializeBinaryFromReader
        );
        msg.setGeometry(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDeactivateRx(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDeactivateTx(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeArrayCal.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeArrayCal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeArrayCal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeArrayCal.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGeometry();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.ProbeArray.serializeBinaryToWriter);
  }
  f = message.getDeactivateRx();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getDeactivateTx();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional ProbeArray geometry = 1;
 * @return {?proto.k3900.ProbeArray}
 */
proto.k3900.ProbeArrayCal.prototype.getGeometry = function () {
  return /** @type{?proto.k3900.ProbeArray} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ProbeArray, 1)
  );
};

/**
 * @param {?proto.k3900.ProbeArray|undefined} value
 * @return {!proto.k3900.ProbeArrayCal} returns this
 */
proto.k3900.ProbeArrayCal.prototype.setGeometry = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ProbeArrayCal} returns this
 */
proto.k3900.ProbeArrayCal.prototype.clearGeometry = function () {
  return this.setGeometry(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ProbeArrayCal.prototype.hasGeometry = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool deactivate_rx = 2;
 * @return {boolean}
 */
proto.k3900.ProbeArrayCal.prototype.getDeactivateRx = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ProbeArrayCal} returns this
 */
proto.k3900.ProbeArrayCal.prototype.setDeactivateRx = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool deactivate_tx = 3;
 * @return {boolean}
 */
proto.k3900.ProbeArrayCal.prototype.getDeactivateTx = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ProbeArrayCal} returns this
 */
proto.k3900.ProbeArrayCal.prototype.setDeactivateTx = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ProbeCalRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeCalRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ProbeCalRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeCalRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeCalRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        arraysList: jspb.Message.toObjectList(
          msg.getArraysList(),
          proto.k3900.ProbeArrayCal.toObject,
          includeInstance
        ),
        action: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeCalRequest}
 */
proto.k3900.ProbeCalRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeCalRequest();
  return proto.k3900.ProbeCalRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeCalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeCalRequest}
 */
proto.k3900.ProbeCalRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = new proto.k3900.ProbeArrayCal();
        reader.readMessage(
          value,
          proto.k3900.ProbeArrayCal.deserializeBinaryFromReader
        );
        msg.addArrays(value);
        break;
      case 3:
        var value = /** @type {!proto.k3900.CalAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeCalRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeCalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeCalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeCalRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getArraysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.k3900.ProbeArrayCal.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.ProbeCalRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeCalRequest} returns this
 */
proto.k3900.ProbeCalRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated ProbeArrayCal arrays = 2;
 * @return {!Array<!proto.k3900.ProbeArrayCal>}
 */
proto.k3900.ProbeCalRequest.prototype.getArraysList = function () {
  return /** @type{!Array<!proto.k3900.ProbeArrayCal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.ProbeArrayCal, 2)
  );
};

/**
 * @param {!Array<!proto.k3900.ProbeArrayCal>} value
 * @return {!proto.k3900.ProbeCalRequest} returns this
 */
proto.k3900.ProbeCalRequest.prototype.setArraysList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.k3900.ProbeArrayCal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.ProbeArrayCal}
 */
proto.k3900.ProbeCalRequest.prototype.addArrays = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.k3900.ProbeArrayCal,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ProbeCalRequest} returns this
 */
proto.k3900.ProbeCalRequest.prototype.clearArraysList = function () {
  return this.setArraysList([]);
};

/**
 * optional CalAction action = 3;
 * @return {!proto.k3900.CalAction}
 */
proto.k3900.ProbeCalRequest.prototype.getAction = function () {
  return /** @type {!proto.k3900.CalAction} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};

/**
 * @param {!proto.k3900.CalAction} value
 * @return {!proto.k3900.ProbeCalRequest} returns this
 */
proto.k3900.ProbeCalRequest.prototype.setAction = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ProbeFile.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeFile.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeFile.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeFile} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeFile.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        arrayList: jspb.Message.toObjectList(
          msg.getArrayList(),
          proto.k3900.ProbeArray.toObject,
          includeInstance
        ),
        impedance: jspb.Message.getFieldWithDefault(msg, 3, 0),
        vpos: jspb.Message.getFieldWithDefault(msg, 4, 0),
        vneg: jspb.Message.getFieldWithDefault(msg, 5, 0),
        frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        bandwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        series: jspb.Message.getFieldWithDefault(msg, 8, ""),
        tpn: jspb.Message.getFieldWithDefault(msg, 9, 0),
        serial: jspb.Message.getFieldWithDefault(msg, 10, ""),
        id: jspb.Message.getFieldWithDefault(msg, 11, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeFile}
 */
proto.k3900.ProbeFile.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeFile();
  return proto.k3900.ProbeFile.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeFile}
 */
proto.k3900.ProbeFile.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new proto.k3900.ProbeArray();
        reader.readMessage(
          value,
          proto.k3900.ProbeArray.deserializeBinaryFromReader
        );
        msg.addArray(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImpedance(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setVpos(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setVneg(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setFrequency(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBandwidth(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setSeries(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTpn(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setSerial(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeFile.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeFile.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getArrayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.k3900.ProbeArray.serializeBinaryToWriter
    );
  }
  f = message.getImpedance();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getVpos();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getVneg();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeFloat(6, f);
  }
  f = message.getBandwidth();
  if (f !== 0.0) {
    writer.writeFloat(7, f);
  }
  f = message.getSeries();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getTpn();
  if (f !== 0) {
    writer.writeInt32(9, f);
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(11, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.ProbeFile.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated ProbeArray array = 2;
 * @return {!Array<!proto.k3900.ProbeArray>}
 */
proto.k3900.ProbeFile.prototype.getArrayList = function () {
  return /** @type{!Array<!proto.k3900.ProbeArray>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.ProbeArray, 2)
  );
};

/**
 * @param {!Array<!proto.k3900.ProbeArray>} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setArrayList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.k3900.ProbeArray=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.ProbeArray}
 */
proto.k3900.ProbeFile.prototype.addArray = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.k3900.ProbeArray,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.clearArrayList = function () {
  return this.setArrayList([]);
};

/**
 * optional int32 impedance = 3;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getImpedance = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setImpedance = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 vpos = 4;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getVpos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setVpos = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 vneg = 5;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getVneg = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setVneg = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional float frequency = 6;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getFrequency = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setFrequency = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional float bandwidth = 7;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getBandwidth = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setBandwidth = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional string series = 8;
 * @return {string}
 */
proto.k3900.ProbeFile.prototype.getSeries = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setSeries = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional int32 tpn = 9;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getTpn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setTpn = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * optional string serial = 10;
 * @return {string}
 */
proto.k3900.ProbeFile.prototype.getSerial = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setSerial = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional int32 id = 11;
 * @return {number}
 */
proto.k3900.ProbeFile.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeFile} returns this
 */
proto.k3900.ProbeFile.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ProbeInfo.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        elemsList: jspb.Message.toObjectList(
          msg.getElemsList(),
          proto.k3900.ProbeElement.toObject,
          includeInstance
        ),
        impedance: jspb.Message.getFieldWithDefault(msg, 3, 0),
        vpos: jspb.Message.getFieldWithDefault(msg, 4, 0),
        vneg: jspb.Message.getFieldWithDefault(msg, 5, 0),
        frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        bandwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        id: jspb.Message.getFieldWithDefault(msg, 8, 0),
        series: jspb.Message.getFieldWithDefault(msg, 9, ""),
        part: jspb.Message.getFieldWithDefault(msg, 10, 0),
        serial: jspb.Message.getFieldWithDefault(msg, 11, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeInfo}
 */
proto.k3900.ProbeInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeInfo();
  return proto.k3900.ProbeInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeInfo}
 */
proto.k3900.ProbeInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new proto.k3900.ProbeElement();
        reader.readMessage(
          value,
          proto.k3900.ProbeElement.deserializeBinaryFromReader
        );
        msg.addElems(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImpedance(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setVpos(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setVneg(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setFrequency(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBandwidth(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setSeries(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPart(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setSerial(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getElemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.k3900.ProbeElement.serializeBinaryToWriter
    );
  }
  f = message.getImpedance();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getVpos();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getVneg();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeFloat(6, f);
  }
  f = message.getBandwidth();
  if (f !== 0.0) {
    writer.writeFloat(7, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(8, f);
  }
  f = message.getSeries();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getPart();
  if (f !== 0) {
    writer.writeInt32(10, f);
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.ProbeInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated ProbeElement elems = 2;
 * @return {!Array<!proto.k3900.ProbeElement>}
 */
proto.k3900.ProbeInfo.prototype.getElemsList = function () {
  return /** @type{!Array<!proto.k3900.ProbeElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.ProbeElement, 2)
  );
};

/**
 * @param {!Array<!proto.k3900.ProbeElement>} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setElemsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.k3900.ProbeElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.ProbeElement}
 */
proto.k3900.ProbeInfo.prototype.addElems = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.k3900.ProbeElement,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.clearElemsList = function () {
  return this.setElemsList([]);
};

/**
 * optional int32 impedance = 3;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getImpedance = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setImpedance = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 vpos = 4;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getVpos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setVpos = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 vneg = 5;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getVneg = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setVneg = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional float frequency = 6;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getFrequency = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setFrequency = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional float bandwidth = 7;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getBandwidth = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setBandwidth = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional int32 id = 8;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional string series = 9;
 * @return {string}
 */
proto.k3900.ProbeInfo.prototype.getSeries = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setSeries = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional int32 part = 10;
 * @return {number}
 */
proto.k3900.ProbeInfo.prototype.getPart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setPart = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

/**
 * optional string serial = 11;
 * @return {string}
 */
proto.k3900.ProbeInfo.prototype.getSerial = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeInfo} returns this
 */
proto.k3900.ProbeInfo.prototype.setSerial = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.BeamformParameters.repeatedFields_ = [9, 10, 15, 16, 17];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BeamformParameters.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BeamformParameters.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BeamformParameters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BeamformParameters.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        imageWidth: jspb.Message.getFieldWithDefault(msg, 1, 0),
        imageHeight: jspb.Message.getFieldWithDefault(msg, 2, 0),
        bpp: jspb.Message.getFieldWithDefault(msg, 3, 0),
        mmpp:
          (f = msg.getMmpp()) &&
          proto.k3900.Vector3.toObject(includeInstance, f),
        origin:
          (f = msg.getOrigin()) &&
          proto.k3900.Vector3.toObject(includeInstance, f),
        bpf:
          (f = msg.getBpf()) && proto.k3900.Filter.toObject(includeInstance, f),
        sos: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        interp: jspb.Message.getFieldWithDefault(msg, 8, 0),
        osampleOffsetList:
          (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null
            ? undefined
            : f,
        tgcList:
          (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null
            ? undefined
            : f,
        agcOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
        agcSampleOffset: jspb.Message.getFieldWithDefault(msg, 19, 0),
        flipX: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
        flipY: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
        txApts: jspb.Message.getFieldWithDefault(msg, 13, 0),
        rxApts: jspb.Message.getFieldWithDefault(msg, 14, 0),
        txSrcList: jspb.Message.toObjectList(
          msg.getTxSrcList(),
          proto.k3900.ProbeElement.toObject,
          includeInstance
        ),
        txMasksList:
          (f = jspb.Message.getRepeatedBooleanField(msg, 16)) == null
            ? undefined
            : f,
        rxMasksList:
          (f = jspb.Message.getRepeatedBooleanField(msg, 17)) == null
            ? undefined
            : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BeamformParameters}
 */
proto.k3900.BeamformParameters.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BeamformParameters();
  return proto.k3900.BeamformParameters.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BeamformParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BeamformParameters}
 */
proto.k3900.BeamformParameters.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImageWidth(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setImageHeight(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setBpp(value);
        break;
      case 4:
        var value = new proto.k3900.Vector3();
        reader.readMessage(
          value,
          proto.k3900.Vector3.deserializeBinaryFromReader
        );
        msg.setMmpp(value);
        break;
      case 5:
        var value = new proto.k3900.Vector3();
        reader.readMessage(
          value,
          proto.k3900.Vector3.deserializeBinaryFromReader
        );
        msg.setOrigin(value);
        break;
      case 6:
        var value = new proto.k3900.Filter();
        reader.readMessage(
          value,
          proto.k3900.Filter.deserializeBinaryFromReader
        );
        msg.setBpf(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setSos(value);
        break;
      case 8:
        var value = /** @type {!proto.k3900.BFInterp} */ (reader.readEnum());
        msg.setInterp(value);
        break;
      case 9:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited()
            ? reader.readPackedDouble()
            : [reader.readDouble()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addOsampleOffset(values[i]);
        }
        break;
      case 10:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addTgc(values[i]);
        }
        break;
      case 18:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setAgcOffset(value);
        break;
      case 19:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setAgcSampleOffset(value);
        break;
      case 11:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setFlipX(value);
        break;
      case 12:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setFlipY(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTxApts(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setRxApts(value);
        break;
      case 15:
        var value = new proto.k3900.ProbeElement();
        reader.readMessage(
          value,
          proto.k3900.ProbeElement.deserializeBinaryFromReader
        );
        msg.addTxSrc(value);
        break;
      case 16:
        var values = /** @type {!Array<boolean>} */ (
          reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addTxMasks(values[i]);
        }
        break;
      case 17:
        var values = /** @type {!Array<boolean>} */ (
          reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addRxMasks(values[i]);
        }
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BeamformParameters.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BeamformParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BeamformParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BeamformParameters.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getImageWidth();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getImageHeight();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getBpp();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getMmpp();
  if (f != null) {
    writer.writeMessage(4, f, proto.k3900.Vector3.serializeBinaryToWriter);
  }
  f = message.getOrigin();
  if (f != null) {
    writer.writeMessage(5, f, proto.k3900.Vector3.serializeBinaryToWriter);
  }
  f = message.getBpf();
  if (f != null) {
    writer.writeMessage(6, f, proto.k3900.Filter.serializeBinaryToWriter);
  }
  f = message.getSos();
  if (f !== 0.0) {
    writer.writeDouble(7, f);
  }
  f = message.getInterp();
  if (f !== 0.0) {
    writer.writeEnum(8, f);
  }
  f = message.getOsampleOffsetList();
  if (f.length > 0) {
    writer.writePackedDouble(9, f);
  }
  f = message.getTgcList();
  if (f.length > 0) {
    writer.writePackedFloat(10, f);
  }
  f = message.getAgcOffset();
  if (f !== 0.0) {
    writer.writeFloat(18, f);
  }
  f = message.getAgcSampleOffset();
  if (f !== 0) {
    writer.writeInt32(19, f);
  }
  f = message.getFlipX();
  if (f) {
    writer.writeBool(11, f);
  }
  f = message.getFlipY();
  if (f) {
    writer.writeBool(12, f);
  }
  f = message.getTxApts();
  if (f !== 0) {
    writer.writeInt32(13, f);
  }
  f = message.getRxApts();
  if (f !== 0) {
    writer.writeInt32(14, f);
  }
  f = message.getTxSrcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.k3900.ProbeElement.serializeBinaryToWriter
    );
  }
  f = message.getTxMasksList();
  if (f.length > 0) {
    writer.writePackedBool(16, f);
  }
  f = message.getRxMasksList();
  if (f.length > 0) {
    writer.writePackedBool(17, f);
  }
};

/**
 * optional int32 image_width = 1;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getImageWidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setImageWidth = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 image_height = 2;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getImageHeight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setImageHeight = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 bpp = 3;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getBpp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setBpp = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional Vector3 mmpp = 4;
 * @return {?proto.k3900.Vector3}
 */
proto.k3900.BeamformParameters.prototype.getMmpp = function () {
  return /** @type{?proto.k3900.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Vector3, 4)
  );
};

/**
 * @param {?proto.k3900.Vector3|undefined} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setMmpp = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearMmpp = function () {
  return this.setMmpp(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.BeamformParameters.prototype.hasMmpp = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Vector3 origin = 5;
 * @return {?proto.k3900.Vector3}
 */
proto.k3900.BeamformParameters.prototype.getOrigin = function () {
  return /** @type{?proto.k3900.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Vector3, 5)
  );
};

/**
 * @param {?proto.k3900.Vector3|undefined} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setOrigin = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearOrigin = function () {
  return this.setOrigin(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.BeamformParameters.prototype.hasOrigin = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional Filter bpf = 6;
 * @return {?proto.k3900.Filter}
 */
proto.k3900.BeamformParameters.prototype.getBpf = function () {
  return /** @type{?proto.k3900.Filter} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Filter, 6)
  );
};

/**
 * @param {?proto.k3900.Filter|undefined} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setBpf = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearBpf = function () {
  return this.setBpf(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.BeamformParameters.prototype.hasBpf = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional double sos = 7;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getSos = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setSos = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional BFInterp interp = 8;
 * @return {!proto.k3900.BFInterp}
 */
proto.k3900.BeamformParameters.prototype.getInterp = function () {
  return /** @type {!proto.k3900.BFInterp} */ (
    jspb.Message.getFieldWithDefault(this, 8, 0)
  );
};

/**
 * @param {!proto.k3900.BFInterp} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setInterp = function (value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};

/**
 * repeated double osample_offset = 9;
 * @return {!Array<number>}
 */
proto.k3900.BeamformParameters.prototype.getOsampleOffsetList = function () {
  return /** @type {!Array<number>} */ (
    jspb.Message.getRepeatedFloatingPointField(this, 9)
  );
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setOsampleOffsetList = function (
  value
) {
  return jspb.Message.setField(this, 9, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.addOsampleOffset = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearOsampleOffsetList = function () {
  return this.setOsampleOffsetList([]);
};

/**
 * repeated float tgc = 10;
 * @return {!Array<number>}
 */
proto.k3900.BeamformParameters.prototype.getTgcList = function () {
  return /** @type {!Array<number>} */ (
    jspb.Message.getRepeatedFloatingPointField(this, 10)
  );
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setTgcList = function (value) {
  return jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.addTgc = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearTgcList = function () {
  return this.setTgcList([]);
};

/**
 * optional float agc_offset = 18;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getAgcOffset = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setAgcOffset = function (value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};

/**
 * optional int32 agc_sample_offset = 19;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getAgcSampleOffset = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setAgcSampleOffset = function (value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};

/**
 * optional bool flip_x = 11;
 * @return {boolean}
 */
proto.k3900.BeamformParameters.prototype.getFlipX = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 11, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setFlipX = function (value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};

/**
 * optional bool flip_y = 12;
 * @return {boolean}
 */
proto.k3900.BeamformParameters.prototype.getFlipY = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 12, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setFlipY = function (value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};

/**
 * optional int32 tx_apts = 13;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getTxApts = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setTxApts = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional int32 rx_apts = 14;
 * @return {number}
 */
proto.k3900.BeamformParameters.prototype.getRxApts = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setRxApts = function (value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * repeated ProbeElement tx_src = 15;
 * @return {!Array<!proto.k3900.ProbeElement>}
 */
proto.k3900.BeamformParameters.prototype.getTxSrcList = function () {
  return /** @type{!Array<!proto.k3900.ProbeElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.ProbeElement, 15)
  );
};

/**
 * @param {!Array<!proto.k3900.ProbeElement>} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setTxSrcList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};

/**
 * @param {!proto.k3900.ProbeElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.ProbeElement}
 */
proto.k3900.BeamformParameters.prototype.addTxSrc = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    15,
    opt_value,
    proto.k3900.ProbeElement,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearTxSrcList = function () {
  return this.setTxSrcList([]);
};

/**
 * repeated bool tx_masks = 16;
 * @return {!Array<boolean>}
 */
proto.k3900.BeamformParameters.prototype.getTxMasksList = function () {
  return /** @type {!Array<boolean>} */ (
    jspb.Message.getRepeatedBooleanField(this, 16)
  );
};

/**
 * @param {!Array<boolean>} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setTxMasksList = function (value) {
  return jspb.Message.setField(this, 16, value || []);
};

/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.addTxMasks = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearTxMasksList = function () {
  return this.setTxMasksList([]);
};

/**
 * repeated bool rx_masks = 17;
 * @return {!Array<boolean>}
 */
proto.k3900.BeamformParameters.prototype.getRxMasksList = function () {
  return /** @type {!Array<boolean>} */ (
    jspb.Message.getRepeatedBooleanField(this, 17)
  );
};

/**
 * @param {!Array<boolean>} value
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.setRxMasksList = function (value) {
  return jspb.Message.setField(this, 17, value || []);
};

/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.addRxMasks = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformParameters} returns this
 */
proto.k3900.BeamformParameters.prototype.clearRxMasksList = function () {
  return this.setRxMasksList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.PostProcessingParameters.repeatedFields_ = [6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PostProcessingParameters.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.PostProcessingParameters.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PostProcessingParameters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PostProcessingParameters.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        contrast: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        brightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        gamma: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        edgeFilter: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        gaussianFilter: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          5,
          0.0
        ),
        measurementsList: jspb.Message.toObjectList(
          msg.getMeasurementsList(),
          proto.k3900.Measurements.toObject,
          includeInstance
        ),
        manualgrayscale: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
        compression: jspb.Message.getFieldWithDefault(msg, 8, ""),
        favg: jspb.Message.getFieldWithDefault(msg, 9, 0),
        graymap: jspb.Message.getFieldWithDefault(msg, 10, ""),
        dr: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
        peakDb: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
        histequal: jspb.Message.getFieldWithDefault(msg, 13, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PostProcessingParameters}
 */
proto.k3900.PostProcessingParameters.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PostProcessingParameters();
  return proto.k3900.PostProcessingParameters.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PostProcessingParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PostProcessingParameters}
 */
proto.k3900.PostProcessingParameters.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setContrast(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBrightness(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGamma(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setEdgeFilter(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGaussianFilter(value);
        break;
      case 6:
        var value = new proto.k3900.Measurements();
        reader.readMessage(
          value,
          proto.k3900.Measurements.deserializeBinaryFromReader
        );
        msg.addMeasurements(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setManualgrayscale(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompression(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFavg(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setGraymap(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setDr(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setPeakDb(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setHistequal(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PostProcessingParameters.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PostProcessingParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PostProcessingParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PostProcessingParameters.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getContrast();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getBrightness();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getGamma();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getEdgeFilter();
  if (f !== 0.0) {
    writer.writeFloat(4, f);
  }
  f = message.getGaussianFilter();
  if (f !== 0.0) {
    writer.writeFloat(5, f);
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.k3900.Measurements.serializeBinaryToWriter
    );
  }
  f = message.getManualgrayscale();
  if (f) {
    writer.writeBool(7, f);
  }
  f = message.getCompression();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getFavg();
  if (f !== 0) {
    writer.writeInt32(9, f);
  }
  f = message.getGraymap();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getDr();
  if (f !== 0.0) {
    writer.writeFloat(11, f);
  }
  f = message.getPeakDb();
  if (f !== 0.0) {
    writer.writeFloat(12, f);
  }
  f = message.getHistequal();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
};

/**
 * optional float contrast = 1;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getContrast = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setContrast = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional float brightness = 2;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getBrightness = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setBrightness = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional float gamma = 3;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getGamma = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setGamma = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float edge_filter = 4;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getEdgeFilter = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setEdgeFilter = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional float gaussian_filter = 5;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getGaussianFilter = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setGaussianFilter = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

/**
 * repeated Measurements measurements = 6;
 * @return {!Array<!proto.k3900.Measurements>}
 */
proto.k3900.PostProcessingParameters.prototype.getMeasurementsList =
  function () {
    return /** @type{!Array<!proto.k3900.Measurements>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.k3900.Measurements, 6)
    );
  };

/**
 * @param {!Array<!proto.k3900.Measurements>} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setMeasurementsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.k3900.Measurements=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.Measurements}
 */
proto.k3900.PostProcessingParameters.prototype.addMeasurements = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.k3900.Measurements,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.clearMeasurementsList =
  function () {
    return this.setMeasurementsList([]);
  };

/**
 * optional bool manualgrayscale = 7;
 * @return {boolean}
 */
proto.k3900.PostProcessingParameters.prototype.getManualgrayscale =
  function () {
    return /** @type {boolean} */ (
      jspb.Message.getBooleanFieldWithDefault(this, 7, false)
    );
  };

/**
 * @param {boolean} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setManualgrayscale = function (
  value
) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional string compression = 8;
 * @return {string}
 */
proto.k3900.PostProcessingParameters.prototype.getCompression = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setCompression = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional int32 favg = 9;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getFavg = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setFavg = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * optional string graymap = 10;
 * @return {string}
 */
proto.k3900.PostProcessingParameters.prototype.getGraymap = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setGraymap = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional float dr = 11;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getDr = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setDr = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional float peak_db = 12;
 * @return {number}
 */
proto.k3900.PostProcessingParameters.prototype.getPeakDb = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setPeakDb = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * optional string histequal = 13;
 * @return {string}
 */
proto.k3900.PostProcessingParameters.prototype.getHistequal = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PostProcessingParameters} returns this
 */
proto.k3900.PostProcessingParameters.prototype.setHistequal = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.RawCaptureMetadata.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.RawCaptureMetadata.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.RawCaptureMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.RawCaptureMetadata.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        nFrames: jspb.Message.getFieldWithDefault(msg, 1, 0),
        probe:
          (f = msg.getProbe()) &&
          proto.k3900.ProbeInfo.toObject(includeInstance, f),
        frameMetadata:
          (f = msg.getFrameMetadata()) &&
          proto.k3900.RawFrameMetadata.toObject(includeInstance, f),
        bfParams:
          (f = msg.getBfParams()) &&
          proto.k3900.BeamformParameters.toObject(includeInstance, f),
        postProcessing:
          (f = msg.getPostProcessing()) &&
          proto.k3900.PostProcessingParameters.toObject(includeInstance, f),
        memid: jspb.Message.getFieldWithDefault(msg, 6, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.RawCaptureMetadata}
 */
proto.k3900.RawCaptureMetadata.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.RawCaptureMetadata();
  return proto.k3900.RawCaptureMetadata.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.RawCaptureMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.RawCaptureMetadata}
 */
proto.k3900.RawCaptureMetadata.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNFrames(value);
        break;
      case 2:
        var value = new proto.k3900.ProbeInfo();
        reader.readMessage(
          value,
          proto.k3900.ProbeInfo.deserializeBinaryFromReader
        );
        msg.setProbe(value);
        break;
      case 3:
        var value = new proto.k3900.RawFrameMetadata();
        reader.readMessage(
          value,
          proto.k3900.RawFrameMetadata.deserializeBinaryFromReader
        );
        msg.setFrameMetadata(value);
        break;
      case 4:
        var value = new proto.k3900.BeamformParameters();
        reader.readMessage(
          value,
          proto.k3900.BeamformParameters.deserializeBinaryFromReader
        );
        msg.setBfParams(value);
        break;
      case 5:
        var value = new proto.k3900.PostProcessingParameters();
        reader.readMessage(
          value,
          proto.k3900.PostProcessingParameters.deserializeBinaryFromReader
        );
        msg.setPostProcessing(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.RawCaptureMetadata.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.RawCaptureMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.RawCaptureMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.RawCaptureMetadata.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getNFrames();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getProbe();
  if (f != null) {
    writer.writeMessage(2, f, proto.k3900.ProbeInfo.serializeBinaryToWriter);
  }
  f = message.getFrameMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.k3900.RawFrameMetadata.serializeBinaryToWriter
    );
  }
  f = message.getBfParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.k3900.BeamformParameters.serializeBinaryToWriter
    );
  }
  f = message.getPostProcessing();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.k3900.PostProcessingParameters.serializeBinaryToWriter
    );
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
};

/**
 * optional int32 n_frames = 1;
 * @return {number}
 */
proto.k3900.RawCaptureMetadata.prototype.getNFrames = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setNFrames = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional ProbeInfo probe = 2;
 * @return {?proto.k3900.ProbeInfo}
 */
proto.k3900.RawCaptureMetadata.prototype.getProbe = function () {
  return /** @type{?proto.k3900.ProbeInfo} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ProbeInfo, 2)
  );
};

/**
 * @param {?proto.k3900.ProbeInfo|undefined} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setProbe = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.clearProbe = function () {
  return this.setProbe(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.RawCaptureMetadata.prototype.hasProbe = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional RawFrameMetadata frame_metadata = 3;
 * @return {?proto.k3900.RawFrameMetadata}
 */
proto.k3900.RawCaptureMetadata.prototype.getFrameMetadata = function () {
  return /** @type{?proto.k3900.RawFrameMetadata} */ (
    jspb.Message.getWrapperField(this, proto.k3900.RawFrameMetadata, 3)
  );
};

/**
 * @param {?proto.k3900.RawFrameMetadata|undefined} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setFrameMetadata = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.clearFrameMetadata = function () {
  return this.setFrameMetadata(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.RawCaptureMetadata.prototype.hasFrameMetadata = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional BeamformParameters bf_params = 4;
 * @return {?proto.k3900.BeamformParameters}
 */
proto.k3900.RawCaptureMetadata.prototype.getBfParams = function () {
  return /** @type{?proto.k3900.BeamformParameters} */ (
    jspb.Message.getWrapperField(this, proto.k3900.BeamformParameters, 4)
  );
};

/**
 * @param {?proto.k3900.BeamformParameters|undefined} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setBfParams = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.clearBfParams = function () {
  return this.setBfParams(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.RawCaptureMetadata.prototype.hasBfParams = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional PostProcessingParameters post_processing = 5;
 * @return {?proto.k3900.PostProcessingParameters}
 */
proto.k3900.RawCaptureMetadata.prototype.getPostProcessing = function () {
  return /** @type{?proto.k3900.PostProcessingParameters} */ (
    jspb.Message.getWrapperField(this, proto.k3900.PostProcessingParameters, 5)
  );
};

/**
 * @param {?proto.k3900.PostProcessingParameters|undefined} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setPostProcessing = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.clearPostProcessing = function () {
  return this.setPostProcessing(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.RawCaptureMetadata.prototype.hasPostProcessing = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string memid = 6;
 * @return {string}
 */
proto.k3900.RawCaptureMetadata.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.RawCaptureMetadata} returns this
 */
proto.k3900.RawCaptureMetadata.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Ping.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Ping.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Ping} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Ping.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        samples: msg.getSamples_asB64(),
        file: jspb.Message.getFieldWithDefault(msg, 2, 0),
        ping: jspb.Message.getFieldWithDefault(msg, 3, 0),
        frame: jspb.Message.getFieldWithDefault(msg, 4, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 5, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Ping}
 */
proto.k3900.Ping.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Ping();
  return proto.k3900.Ping.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Ping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Ping}
 */
proto.k3900.Ping.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSamples(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFile(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPing(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFrame(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Ping.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Ping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Ping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Ping.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSamples_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getFile();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getPing();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getFrame();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional bytes samples = 1;
 * @return {string}
 */
proto.k3900.Ping.prototype.getSamples = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes samples = 1;
 * This is a type-conversion wrapper around `getSamples()`
 * @return {string}
 */
proto.k3900.Ping.prototype.getSamples_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSamples()));
};

/**
 * optional bytes samples = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSamples()`
 * @return {!Uint8Array}
 */
proto.k3900.Ping.prototype.getSamples_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSamples()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.Ping} returns this
 */
proto.k3900.Ping.prototype.setSamples = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional int32 file = 2;
 * @return {number}
 */
proto.k3900.Ping.prototype.getFile = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Ping} returns this
 */
proto.k3900.Ping.prototype.setFile = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 ping = 3;
 * @return {number}
 */
proto.k3900.Ping.prototype.getPing = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Ping} returns this
 */
proto.k3900.Ping.prototype.setPing = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 frame = 4;
 * @return {number}
 */
proto.k3900.Ping.prototype.getFrame = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Ping} returns this
 */
proto.k3900.Ping.prototype.setFrame = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional string memid = 5;
 * @return {string}
 */
proto.k3900.Ping.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Ping} returns this
 */
proto.k3900.Ping.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ResponseMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ResponseMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ResponseMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ResponseMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        msg: jspb.Message.getFieldWithDefault(msg, 1, ""),
        code: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ResponseMsg}
 */
proto.k3900.ResponseMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ResponseMsg();
  return proto.k3900.ResponseMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ResponseMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ResponseMsg}
 */
proto.k3900.ResponseMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMsg(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ResponseMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ResponseMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ResponseMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ResponseMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional string msg = 1;
 * @return {string}
 */
proto.k3900.ResponseMsg.prototype.getMsg = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ResponseMsg} returns this
 */
proto.k3900.ResponseMsg.prototype.setMsg = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 code = 2;
 * @return {number}
 */
proto.k3900.ResponseMsg.prototype.getCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ResponseMsg} returns this
 */
proto.k3900.ResponseMsg.prototype.setCode = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ImageFormat.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ImageFormat.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ImageFormat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ImageFormat.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        raw: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        bpp: jspb.Message.getFieldWithDefault(msg, 2, 0),
        applyGamma: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        applyBnc: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
        applyTgc: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        memid: jspb.Message.getFieldWithDefault(msg, 6, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ImageFormat}
 */
proto.k3900.ImageFormat.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ImageFormat();
  return proto.k3900.ImageFormat.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ImageFormat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ImageFormat}
 */
proto.k3900.ImageFormat.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setRaw(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setBpp(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setApplyGamma(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setApplyBnc(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setApplyTgc(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ImageFormat.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ImageFormat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ImageFormat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ImageFormat.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRaw();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getBpp();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getApplyGamma();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getApplyBnc();
  if (f) {
    writer.writeBool(4, f);
  }
  f = message.getApplyTgc();
  if (f) {
    writer.writeBool(5, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
};

/**
 * optional bool raw = 1;
 * @return {boolean}
 */
proto.k3900.ImageFormat.prototype.getRaw = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setRaw = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional int32 bpp = 2;
 * @return {number}
 */
proto.k3900.ImageFormat.prototype.getBpp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setBpp = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional bool apply_gamma = 3;
 * @return {boolean}
 */
proto.k3900.ImageFormat.prototype.getApplyGamma = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setApplyGamma = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool apply_bnc = 4;
 * @return {boolean}
 */
proto.k3900.ImageFormat.prototype.getApplyBnc = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setApplyBnc = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional bool apply_tgc = 5;
 * @return {boolean}
 */
proto.k3900.ImageFormat.prototype.getApplyTgc = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setApplyTgc = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string memid = 6;
 * @return {string}
 */
proto.k3900.ImageFormat.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ImageFormat} returns this
 */
proto.k3900.ImageFormat.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.UploadStatusResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.UploadStatusResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.UploadStatusResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.UploadStatusResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.UploadStatusResponse}
 */
proto.k3900.UploadStatusResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.UploadStatusResponse();
  return proto.k3900.UploadStatusResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.UploadStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.UploadStatusResponse}
 */
proto.k3900.UploadStatusResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.UploadStatusResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.UploadStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.UploadStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.UploadStatusResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.k3900.UploadStatusResponse.prototype.getCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.UploadStatusResponse} returns this
 */
proto.k3900.UploadStatusResponse.prototype.setCode = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.k3900.UploadStatusResponse.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.UploadStatusResponse} returns this
 */
proto.k3900.UploadStatusResponse.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.DownloadRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.DownloadRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.DownloadRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.DownloadRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.DownloadRequest}
 */
proto.k3900.DownloadRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.DownloadRequest();
  return proto.k3900.DownloadRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.DownloadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.DownloadRequest}
 */
proto.k3900.DownloadRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.DownloadRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.DownloadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.DownloadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.DownloadRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.DownloadRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DownloadRequest} returns this
 */
proto.k3900.DownloadRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PingDownloadDataRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.PingDownloadDataRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PingDownloadDataRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PingDownloadDataRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        file: jspb.Message.getFieldWithDefault(msg, 1, 0),
        nFrames: jspb.Message.getFieldWithDefault(msg, 2, 0),
        frameStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PingDownloadDataRequest}
 */
proto.k3900.PingDownloadDataRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PingDownloadDataRequest();
  return proto.k3900.PingDownloadDataRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PingDownloadDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PingDownloadDataRequest}
 */
proto.k3900.PingDownloadDataRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFile(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNFrames(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFrameStart(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PingDownloadDataRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PingDownloadDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PingDownloadDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PingDownloadDataRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getFile();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getNFrames();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getFrameStart();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional int32 file = 1;
 * @return {number}
 */
proto.k3900.PingDownloadDataRequest.prototype.getFile = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.PingDownloadDataRequest} returns this
 */
proto.k3900.PingDownloadDataRequest.prototype.setFile = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 n_frames = 2;
 * @return {number}
 */
proto.k3900.PingDownloadDataRequest.prototype.getNFrames = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.PingDownloadDataRequest} returns this
 */
proto.k3900.PingDownloadDataRequest.prototype.setNFrames = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 frame_start = 3;
 * @return {number}
 */
proto.k3900.PingDownloadDataRequest.prototype.getFrameStart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.PingDownloadDataRequest} returns this
 */
proto.k3900.PingDownloadDataRequest.prototype.setFrameStart = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string memid = 4;
 * @return {string}
 */
proto.k3900.PingDownloadDataRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PingDownloadDataRequest} returns this
 */
proto.k3900.PingDownloadDataRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FloatRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FloatRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FloatRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FloatRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FloatRequest}
 */
proto.k3900.FloatRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FloatRequest();
  return proto.k3900.FloatRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FloatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FloatRequest}
 */
proto.k3900.FloatRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FloatRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FloatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FloatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FloatRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
};

/**
 * optional float value = 1;
 * @return {number}
 */
proto.k3900.FloatRequest.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FloatRequest} returns this
 */
proto.k3900.FloatRequest.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.DataFile.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.DataFile.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.DataFile} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.DataFile.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
        filesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
        md5sum: msg.getMd5sum_asB64(),
        data: msg.getData_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.DataFile}
 */
proto.k3900.DataFile.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.DataFile();
  return proto.k3900.DataFile.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.DataFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.DataFile}
 */
proto.k3900.DataFile.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setFilename(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setFilesize(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setMd5sum(value);
        break;
      case 4:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.DataFile.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.DataFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.DataFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.DataFile.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFilesize();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getMd5sum_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(4, f);
  }
};

/**
 * optional string filename = 1;
 * @return {string}
 */
proto.k3900.DataFile.prototype.getFilename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DataFile} returns this
 */
proto.k3900.DataFile.prototype.setFilename = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int64 filesize = 2;
 * @return {number}
 */
proto.k3900.DataFile.prototype.getFilesize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.DataFile} returns this
 */
proto.k3900.DataFile.prototype.setFilesize = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional bytes md5sum = 3;
 * @return {string}
 */
proto.k3900.DataFile.prototype.getMd5sum = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes md5sum = 3;
 * This is a type-conversion wrapper around `getMd5sum()`
 * @return {string}
 */
proto.k3900.DataFile.prototype.getMd5sum_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getMd5sum()));
};

/**
 * optional bytes md5sum = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMd5sum()`
 * @return {!Uint8Array}
 */
proto.k3900.DataFile.prototype.getMd5sum_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getMd5sum()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.DataFile} returns this
 */
proto.k3900.DataFile.prototype.setMd5sum = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

/**
 * optional bytes data = 4;
 * @return {string}
 */
proto.k3900.DataFile.prototype.getData = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.k3900.DataFile.prototype.getData_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.k3900.DataFile.prototype.getData_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.DataFile} returns this
 */
proto.k3900.DataFile.prototype.setData = function (value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemNameChange.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.SystemNameChange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemNameChange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemNameChange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        dhcp: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        ip: jspb.Message.getFieldWithDefault(msg, 4, ""),
        mask: jspb.Message.getFieldWithDefault(msg, 5, ""),
        gateway: jspb.Message.getFieldWithDefault(msg, 6, ""),
        dns: jspb.Message.getFieldWithDefault(msg, 7, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemNameChange}
 */
proto.k3900.SystemNameChange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemNameChange();
  return proto.k3900.SystemNameChange.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemNameChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemNameChange}
 */
proto.k3900.SystemNameChange.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDhcp(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setIp(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMask(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setGateway(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setDns(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemNameChange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemNameChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemNameChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemNameChange.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getDhcp();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getMask();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getGateway();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getDns();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bool dhcp = 3;
 * @return {boolean}
 */
proto.k3900.SystemNameChange.prototype.getDhcp = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setDhcp = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional string ip = 4;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getIp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setIp = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string mask = 5;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getMask = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setMask = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string gateway = 6;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getGateway = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setGateway = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string dns = 7;
 * @return {string}
 */
proto.k3900.SystemNameChange.prototype.getDns = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemNameChange} returns this
 */
proto.k3900.SystemNameChange.prototype.setDns = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.BoardVoltageMonitor.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BoardVoltageMonitor.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BoardVoltageMonitor.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BoardVoltageMonitor} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BoardVoltageMonitor.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        voltagesList: jspb.Message.toObjectList(
          msg.getVoltagesList(),
          proto.k3900.Voltage.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BoardVoltageMonitor}
 */
proto.k3900.BoardVoltageMonitor.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BoardVoltageMonitor();
  return proto.k3900.BoardVoltageMonitor.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BoardVoltageMonitor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BoardVoltageMonitor}
 */
proto.k3900.BoardVoltageMonitor.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new proto.k3900.Voltage();
        reader.readMessage(
          value,
          proto.k3900.Voltage.deserializeBinaryFromReader
        );
        msg.addVoltages(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BoardVoltageMonitor.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BoardVoltageMonitor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BoardVoltageMonitor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BoardVoltageMonitor.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getVoltagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.k3900.Voltage.serializeBinaryToWriter
    );
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.BoardVoltageMonitor.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardVoltageMonitor} returns this
 */
proto.k3900.BoardVoltageMonitor.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated Voltage voltages = 2;
 * @return {!Array<!proto.k3900.Voltage>}
 */
proto.k3900.BoardVoltageMonitor.prototype.getVoltagesList = function () {
  return /** @type{!Array<!proto.k3900.Voltage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.Voltage, 2)
  );
};

/**
 * @param {!Array<!proto.k3900.Voltage>} value
 * @return {!proto.k3900.BoardVoltageMonitor} returns this
 */
proto.k3900.BoardVoltageMonitor.prototype.setVoltagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.k3900.Voltage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.Voltage}
 */
proto.k3900.BoardVoltageMonitor.prototype.addVoltages = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.k3900.Voltage,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BoardVoltageMonitor} returns this
 */
proto.k3900.BoardVoltageMonitor.prototype.clearVoltagesList = function () {
  return this.setVoltagesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MasterGainRange.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.MasterGainRange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MasterGainRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MasterGainRange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MasterGainRange}
 */
proto.k3900.MasterGainRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MasterGainRange();
  return proto.k3900.MasterGainRange.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MasterGainRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MasterGainRange}
 */
proto.k3900.MasterGainRange.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setMin(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setMax(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MasterGainRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MasterGainRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MasterGainRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MasterGainRange.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
};

/**
 * optional float min = 1;
 * @return {number}
 */
proto.k3900.MasterGainRange.prototype.getMin = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.MasterGainRange} returns this
 */
proto.k3900.MasterGainRange.prototype.setMin = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional float max = 2;
 * @return {number}
 */
proto.k3900.MasterGainRange.prototype.getMax = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.MasterGainRange} returns this
 */
proto.k3900.MasterGainRange.prototype.setMax = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Voltage.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Voltage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Voltage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Voltage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        position: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        intolerance: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Voltage}
 */
proto.k3900.Voltage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Voltage();
  return proto.k3900.Voltage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Voltage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Voltage}
 */
proto.k3900.Voltage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPosition(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIntolerance(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Voltage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Voltage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Voltage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Voltage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getIntolerance();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.k3900.Voltage.prototype.getPosition = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Voltage} returns this
 */
proto.k3900.Voltage.prototype.setPosition = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional float value = 2;
 * @return {number}
 */
proto.k3900.Voltage.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Voltage} returns this
 */
proto.k3900.Voltage.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional bool intolerance = 3;
 * @return {boolean}
 */
proto.k3900.Voltage.prototype.getIntolerance = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.Voltage} returns this
 */
proto.k3900.Voltage.prototype.setIntolerance = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BoardTestRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BoardTestRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BoardTestRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BoardTestRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        board: jspb.Message.getFieldWithDefault(msg, 1, ""),
        test: jspb.Message.getFieldWithDefault(msg, 2, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BoardTestRequest}
 */
proto.k3900.BoardTestRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BoardTestRequest();
  return proto.k3900.BoardTestRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BoardTestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BoardTestRequest}
 */
proto.k3900.BoardTestRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setBoard(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTest(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BoardTestRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BoardTestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BoardTestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BoardTestRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getBoard();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string board = 1;
 * @return {string}
 */
proto.k3900.BoardTestRequest.prototype.getBoard = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardTestRequest} returns this
 */
proto.k3900.BoardTestRequest.prototype.setBoard = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string test = 2;
 * @return {string}
 */
proto.k3900.BoardTestRequest.prototype.getTest = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardTestRequest} returns this
 */
proto.k3900.BoardTestRequest.prototype.setTest = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.BoardTestRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardTestRequest} returns this
 */
proto.k3900.BoardTestRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BoardTestResults.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BoardTestResults.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BoardTestResults} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BoardTestResults.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        test: jspb.Message.getFieldWithDefault(msg, 1, ""),
        passed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BoardTestResults}
 */
proto.k3900.BoardTestResults.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BoardTestResults();
  return proto.k3900.BoardTestResults.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BoardTestResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BoardTestResults}
 */
proto.k3900.BoardTestResults.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTest(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPassed(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BoardTestResults.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BoardTestResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BoardTestResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BoardTestResults.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional string test = 1;
 * @return {string}
 */
proto.k3900.BoardTestResults.prototype.getTest = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardTestResults} returns this
 */
proto.k3900.BoardTestResults.prototype.setTest = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool passed = 2;
 * @return {boolean}
 */
proto.k3900.BoardTestResults.prototype.getPassed = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.BoardTestResults} returns this
 */
proto.k3900.BoardTestResults.prototype.setPassed = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemReport.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.SystemReport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemReport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        state: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemReport}
 */
proto.k3900.SystemReport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemReport();
  return proto.k3900.SystemReport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemReport}
 */
proto.k3900.SystemReport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setState(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemReport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.SystemReport.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemReport} returns this
 */
proto.k3900.SystemReport.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string state = 2;
 * @return {string}
 */
proto.k3900.SystemReport.prototype.getState = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemReport} returns this
 */
proto.k3900.SystemReport.prototype.setState = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.BoardInfo.repeatedFields_ = [5, 6, 7, 9];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BoardInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.BoardInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BoardInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BoardInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        id: jspb.Message.getFieldWithDefault(msg, 2, ""),
        temp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        i2c: jspb.Message.getFieldWithDefault(msg, 4, ""),
        voltageMonitorsList: jspb.Message.toObjectList(
          msg.getVoltageMonitorsList(),
          proto.k3900.BoardVoltageMonitor.toObject,
          includeInstance
        ),
        testResultsList: jspb.Message.toObjectList(
          msg.getTestResultsList(),
          proto.k3900.BoardTestResults.toObject,
          includeInstance
        ),
        errorStatusList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
        desc: jspb.Message.getFieldWithDefault(msg, 8, ""),
        paramsList: jspb.Message.toObjectList(
          msg.getParamsList(),
          proto.k3900.BoardParameterType.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BoardInfo}
 */
proto.k3900.BoardInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BoardInfo();
  return proto.k3900.BoardInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BoardInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BoardInfo}
 */
proto.k3900.BoardInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTemp(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setI2c(value);
        break;
      case 5:
        var value = new proto.k3900.BoardVoltageMonitor();
        reader.readMessage(
          value,
          proto.k3900.BoardVoltageMonitor.deserializeBinaryFromReader
        );
        msg.addVoltageMonitors(value);
        break;
      case 6:
        var value = new proto.k3900.BoardTestResults();
        reader.readMessage(
          value,
          proto.k3900.BoardTestResults.deserializeBinaryFromReader
        );
        msg.addTestResults(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addErrorStatus(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setDesc(value);
        break;
      case 9:
        var value = new proto.k3900.BoardParameterType();
        reader.readMessage(
          value,
          proto.k3900.BoardParameterType.deserializeBinaryFromReader
        );
        msg.addParams(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BoardInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BoardInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BoardInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BoardInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getTemp();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getI2c();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getVoltageMonitorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.k3900.BoardVoltageMonitor.serializeBinaryToWriter
    );
  }
  f = message.getTestResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.k3900.BoardTestResults.serializeBinaryToWriter
    );
  }
  f = message.getErrorStatusList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.k3900.BoardParameterType.serializeBinaryToWriter
    );
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.BoardInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string id = 2;
 * @return {string}
 */
proto.k3900.BoardInfo.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional float temp = 3;
 * @return {number}
 */
proto.k3900.BoardInfo.prototype.getTemp = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setTemp = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional string i2c = 4;
 * @return {string}
 */
proto.k3900.BoardInfo.prototype.getI2c = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setI2c = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * repeated BoardVoltageMonitor voltage_monitors = 5;
 * @return {!Array<!proto.k3900.BoardVoltageMonitor>}
 */
proto.k3900.BoardInfo.prototype.getVoltageMonitorsList = function () {
  return /** @type{!Array<!proto.k3900.BoardVoltageMonitor>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.k3900.BoardVoltageMonitor,
      5
    )
  );
};

/**
 * @param {!Array<!proto.k3900.BoardVoltageMonitor>} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setVoltageMonitorsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.k3900.BoardVoltageMonitor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.BoardVoltageMonitor}
 */
proto.k3900.BoardInfo.prototype.addVoltageMonitors = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    5,
    opt_value,
    proto.k3900.BoardVoltageMonitor,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.clearVoltageMonitorsList = function () {
  return this.setVoltageMonitorsList([]);
};

/**
 * repeated BoardTestResults test_results = 6;
 * @return {!Array<!proto.k3900.BoardTestResults>}
 */
proto.k3900.BoardInfo.prototype.getTestResultsList = function () {
  return /** @type{!Array<!proto.k3900.BoardTestResults>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.BoardTestResults, 6)
  );
};

/**
 * @param {!Array<!proto.k3900.BoardTestResults>} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setTestResultsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.k3900.BoardTestResults=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.BoardTestResults}
 */
proto.k3900.BoardInfo.prototype.addTestResults = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.k3900.BoardTestResults,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.clearTestResultsList = function () {
  return this.setTestResultsList([]);
};

/**
 * repeated string error_status = 7;
 * @return {!Array<string>}
 */
proto.k3900.BoardInfo.prototype.getErrorStatusList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setErrorStatusList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.addErrorStatus = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.clearErrorStatusList = function () {
  return this.setErrorStatusList([]);
};

/**
 * optional string desc = 8;
 * @return {string}
 */
proto.k3900.BoardInfo.prototype.getDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setDesc = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * repeated BoardParameterType params = 9;
 * @return {!Array<!proto.k3900.BoardParameterType>}
 */
proto.k3900.BoardInfo.prototype.getParamsList = function () {
  return /** @type{!Array<!proto.k3900.BoardParameterType>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.k3900.BoardParameterType,
      9
    )
  );
};

/**
 * @param {!Array<!proto.k3900.BoardParameterType>} value
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.setParamsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.k3900.BoardParameterType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.BoardParameterType}
 */
proto.k3900.BoardInfo.prototype.addParams = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    9,
    opt_value,
    proto.k3900.BoardParameterType,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BoardInfo} returns this
 */
proto.k3900.BoardInfo.prototype.clearParamsList = function () {
  return this.setParamsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BoardParameterType.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BoardParameterType.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BoardParameterType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BoardParameterType.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        unit: jspb.Message.getFieldWithDefault(msg, 2, ""),
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        pass: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BoardParameterType}
 */
proto.k3900.BoardParameterType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BoardParameterType();
  return proto.k3900.BoardParameterType.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BoardParameterType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BoardParameterType}
 */
proto.k3900.BoardParameterType.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUnit(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPass(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BoardParameterType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BoardParameterType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BoardParameterType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BoardParameterType.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getPass();
  if (f) {
    writer.writeBool(4, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.BoardParameterType.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardParameterType} returns this
 */
proto.k3900.BoardParameterType.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string unit = 2;
 * @return {string}
 */
proto.k3900.BoardParameterType.prototype.getUnit = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BoardParameterType} returns this
 */
proto.k3900.BoardParameterType.prototype.setUnit = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional float value = 3;
 * @return {number}
 */
proto.k3900.BoardParameterType.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.BoardParameterType} returns this
 */
proto.k3900.BoardParameterType.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional bool pass = 4;
 * @return {boolean}
 */
proto.k3900.BoardParameterType.prototype.getPass = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.BoardParameterType} returns this
 */
proto.k3900.BoardParameterType.prototype.setPass = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.SystemInfo.repeatedFields_ = [1, 2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.SystemInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        boardsList: jspb.Message.toObjectList(
          msg.getBoardsList(),
          proto.k3900.BoardInfo.toObject,
          includeInstance
        ),
        issuesList: jspb.Message.toObjectList(
          msg.getIssuesList(),
          proto.k3900.SystemReport.toObject,
          includeInstance
        ),
        probe:
          (f = msg.getProbe()) &&
          proto.k3900.ProbeFile.toObject(includeInstance, f),
        tec:
          (f = msg.getTec()) && proto.k3900.TECMsg.toObject(includeInstance, f),
        probeDetected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        probeValid: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemInfo}
 */
proto.k3900.SystemInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemInfo();
  return proto.k3900.SystemInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemInfo}
 */
proto.k3900.SystemInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.BoardInfo();
        reader.readMessage(
          value,
          proto.k3900.BoardInfo.deserializeBinaryFromReader
        );
        msg.addBoards(value);
        break;
      case 2:
        var value = new proto.k3900.SystemReport();
        reader.readMessage(
          value,
          proto.k3900.SystemReport.deserializeBinaryFromReader
        );
        msg.addIssues(value);
        break;
      case 3:
        var value = new proto.k3900.ProbeFile();
        reader.readMessage(
          value,
          proto.k3900.ProbeFile.deserializeBinaryFromReader
        );
        msg.setProbe(value);
        break;
      case 4:
        var value = new proto.k3900.TECMsg();
        reader.readMessage(
          value,
          proto.k3900.TECMsg.deserializeBinaryFromReader
        );
        msg.setTec(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setProbeDetected(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setProbeValid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBoardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.k3900.BoardInfo.serializeBinaryToWriter
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.k3900.SystemReport.serializeBinaryToWriter
    );
  }
  f = message.getProbe();
  if (f != null) {
    writer.writeMessage(3, f, proto.k3900.ProbeFile.serializeBinaryToWriter);
  }
  f = message.getTec();
  if (f != null) {
    writer.writeMessage(4, f, proto.k3900.TECMsg.serializeBinaryToWriter);
  }
  f = message.getProbeDetected();
  if (f) {
    writer.writeBool(5, f);
  }
  f = message.getProbeValid();
  if (f) {
    writer.writeBool(6, f);
  }
};

/**
 * repeated BoardInfo boards = 1;
 * @return {!Array<!proto.k3900.BoardInfo>}
 */
proto.k3900.SystemInfo.prototype.getBoardsList = function () {
  return /** @type{!Array<!proto.k3900.BoardInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.BoardInfo, 1)
  );
};

/**
 * @param {!Array<!proto.k3900.BoardInfo>} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setBoardsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.k3900.BoardInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.BoardInfo}
 */
proto.k3900.SystemInfo.prototype.addBoards = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.k3900.BoardInfo,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.clearBoardsList = function () {
  return this.setBoardsList([]);
};

/**
 * repeated SystemReport issues = 2;
 * @return {!Array<!proto.k3900.SystemReport>}
 */
proto.k3900.SystemInfo.prototype.getIssuesList = function () {
  return /** @type{!Array<!proto.k3900.SystemReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.SystemReport, 2)
  );
};

/**
 * @param {!Array<!proto.k3900.SystemReport>} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setIssuesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.k3900.SystemReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.SystemReport}
 */
proto.k3900.SystemInfo.prototype.addIssues = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.k3900.SystemReport,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.clearIssuesList = function () {
  return this.setIssuesList([]);
};

/**
 * optional ProbeFile probe = 3;
 * @return {?proto.k3900.ProbeFile}
 */
proto.k3900.SystemInfo.prototype.getProbe = function () {
  return /** @type{?proto.k3900.ProbeFile} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ProbeFile, 3)
  );
};

/**
 * @param {?proto.k3900.ProbeFile|undefined} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setProbe = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.clearProbe = function () {
  return this.setProbe(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemInfo.prototype.hasProbe = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional TECMsg tec = 4;
 * @return {?proto.k3900.TECMsg}
 */
proto.k3900.SystemInfo.prototype.getTec = function () {
  return /** @type{?proto.k3900.TECMsg} */ (
    jspb.Message.getWrapperField(this, proto.k3900.TECMsg, 4)
  );
};

/**
 * @param {?proto.k3900.TECMsg|undefined} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setTec = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.clearTec = function () {
  return this.setTec(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemInfo.prototype.hasTec = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool probe_detected = 5;
 * @return {boolean}
 */
proto.k3900.SystemInfo.prototype.getProbeDetected = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setProbeDetected = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional bool probe_valid = 6;
 * @return {boolean}
 */
proto.k3900.SystemInfo.prototype.getProbeValid = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 6, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemInfo} returns this
 */
proto.k3900.SystemInfo.prototype.setProbeValid = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.EngineeringCmdRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.EngineeringCmdRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.EngineeringCmdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.EngineeringCmdRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        message: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.EngineeringCmdRequest}
 */
proto.k3900.EngineeringCmdRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.EngineeringCmdRequest();
  return proto.k3900.EngineeringCmdRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.EngineeringCmdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.EngineeringCmdRequest}
 */
proto.k3900.EngineeringCmdRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.EngineeringCmdRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.EngineeringCmdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.EngineeringCmdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.EngineeringCmdRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.k3900.EngineeringCmdRequest.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.EngineeringCmdRequest} returns this
 */
proto.k3900.EngineeringCmdRequest.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.EngineeringCmdRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.EngineeringCmdRequest} returns this
 */
proto.k3900.EngineeringCmdRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PresetResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.PresetResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PresetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PresetResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dflt: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PresetResponse}
 */
proto.k3900.PresetResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PresetResponse();
  return proto.k3900.PresetResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PresetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PresetResponse}
 */
proto.k3900.PresetResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDflt(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PresetResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PresetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PresetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PresetResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDflt();
  if (f) {
    writer.writeBool(1, f);
  }
};

/**
 * optional bool dflt = 1;
 * @return {boolean}
 */
proto.k3900.PresetResponse.prototype.getDflt = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.PresetResponse} returns this
 */
proto.k3900.PresetResponse.prototype.setDflt = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.IntMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.IntMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.IntMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.IntMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        value: jspb.Message.getFieldWithDefault(msg, 1, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.IntMsg}
 */
proto.k3900.IntMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.IntMsg();
  return proto.k3900.IntMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.IntMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.IntMsg}
 */
proto.k3900.IntMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.IntMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.IntMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.IntMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.IntMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
};

/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.k3900.IntMsg.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.IntMsg} returns this
 */
proto.k3900.IntMsg.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.TECMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.TECMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.TECMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.TECMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        probeConnected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        nBadElements: jspb.Message.getFieldWithDefault(msg, 2, 0),
        worstVariance: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          3,
          0.0
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.TECMsg}
 */
proto.k3900.TECMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.TECMsg();
  return proto.k3900.TECMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.TECMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.TECMsg}
 */
proto.k3900.TECMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setProbeConnected(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNBadElements(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setWorstVariance(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.TECMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.TECMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.TECMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.TECMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProbeConnected();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getNBadElements();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getWorstVariance();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
};

/**
 * optional bool probe_connected = 1;
 * @return {boolean}
 */
proto.k3900.TECMsg.prototype.getProbeConnected = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.TECMsg} returns this
 */
proto.k3900.TECMsg.prototype.setProbeConnected = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional int32 n_bad_elements = 2;
 * @return {number}
 */
proto.k3900.TECMsg.prototype.getNBadElements = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.TECMsg} returns this
 */
proto.k3900.TECMsg.prototype.setNBadElements = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional float worst_variance = 3;
 * @return {number}
 */
proto.k3900.TECMsg.prototype.getWorstVariance = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.TECMsg} returns this
 */
proto.k3900.TECMsg.prototype.setWorstVariance = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.EngControlMsg.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.EngControlMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.EngControlMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.EngControlMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        lna: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.EngControlMsg}
 */
proto.k3900.EngControlMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.EngControlMsg();
  return proto.k3900.EngControlMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.EngControlMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.EngControlMsg}
 */
proto.k3900.EngControlMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setLna(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.EngControlMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.EngControlMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.EngControlMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.EngControlMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLna();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional float lna = 1;
 * @return {number}
 */
proto.k3900.EngControlMsg.prototype.getLna = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.EngControlMsg} returns this
 */
proto.k3900.EngControlMsg.prototype.setLna = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.EngControlMsg.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.EngControlMsg} returns this
 */
proto.k3900.EngControlMsg.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.AboutMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.AboutMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.AboutMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.AboutMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        text: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.AboutMsg}
 */
proto.k3900.AboutMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.AboutMsg();
  return proto.k3900.AboutMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.AboutMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.AboutMsg}
 */
proto.k3900.AboutMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setText(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.AboutMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.AboutMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.AboutMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.AboutMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string text = 1;
 * @return {string}
 */
proto.k3900.AboutMsg.prototype.getText = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.AboutMsg} returns this
 */
proto.k3900.AboutMsg.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.VersionInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.VersionInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.VersionInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.VersionInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        version: jspb.Message.getFieldWithDefault(msg, 2, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.VersionInfo}
 */
proto.k3900.VersionInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.VersionInfo();
  return proto.k3900.VersionInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.VersionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.VersionInfo}
 */
proto.k3900.VersionInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setVersion(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.VersionInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.VersionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.VersionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.VersionInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.VersionInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.VersionInfo} returns this
 */
proto.k3900.VersionInfo.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string version = 2;
 * @return {string}
 */
proto.k3900.VersionInfo.prototype.getVersion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.VersionInfo} returns this
 */
proto.k3900.VersionInfo.prototype.setVersion = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.VersionInfo.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.VersionInfo} returns this
 */
proto.k3900.VersionInfo.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Credentials.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Credentials.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Credentials} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Credentials.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        username: jspb.Message.getFieldWithDefault(msg, 1, ""),
        password: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Credentials}
 */
proto.k3900.Credentials.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Credentials();
  return proto.k3900.Credentials.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Credentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Credentials}
 */
proto.k3900.Credentials.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUsername(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPassword(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Credentials.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Credentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Credentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Credentials.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string username = 1;
 * @return {string}
 */
proto.k3900.Credentials.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Credentials} returns this
 */
proto.k3900.Credentials.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string password = 2;
 * @return {string}
 */
proto.k3900.Credentials.prototype.getPassword = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Credentials} returns this
 */
proto.k3900.Credentials.prototype.setPassword = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.LoginRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.LoginRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.LoginRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.LoginRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        credentials:
          (f = msg.getCredentials()) &&
          proto.k3900.Credentials.toObject(includeInstance, f),
        ro: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        quick: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.LoginRequest}
 */
proto.k3900.LoginRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.LoginRequest();
  return proto.k3900.LoginRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.LoginRequest}
 */
proto.k3900.LoginRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Credentials();
        reader.readMessage(
          value,
          proto.k3900.Credentials.deserializeBinaryFromReader
        );
        msg.setCredentials(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setRo(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setQuick(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.LoginRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.LoginRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Credentials.serializeBinaryToWriter);
  }
  f = message.getRo();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getQuick();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional Credentials credentials = 1;
 * @return {?proto.k3900.Credentials}
 */
proto.k3900.LoginRequest.prototype.getCredentials = function () {
  return /** @type{?proto.k3900.Credentials} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Credentials, 1)
  );
};

/**
 * @param {?proto.k3900.Credentials|undefined} value
 * @return {!proto.k3900.LoginRequest} returns this
 */
proto.k3900.LoginRequest.prototype.setCredentials = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.LoginRequest} returns this
 */
proto.k3900.LoginRequest.prototype.clearCredentials = function () {
  return this.setCredentials(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.LoginRequest.prototype.hasCredentials = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool ro = 2;
 * @return {boolean}
 */
proto.k3900.LoginRequest.prototype.getRo = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.LoginRequest} returns this
 */
proto.k3900.LoginRequest.prototype.setRo = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool quick = 3;
 * @return {boolean}
 */
proto.k3900.LoginRequest.prototype.getQuick = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.LoginRequest} returns this
 */
proto.k3900.LoginRequest.prototype.setQuick = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.LogoutRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.LogoutRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.LogoutRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.LogoutRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        quick: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.LogoutRequest}
 */
proto.k3900.LogoutRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.LogoutRequest();
  return proto.k3900.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.LogoutRequest}
 */
proto.k3900.LogoutRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setQuick(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.LogoutRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.LogoutRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getQuick();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.LogoutRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.LogoutRequest} returns this
 */
proto.k3900.LogoutRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool quick = 2;
 * @return {boolean}
 */
proto.k3900.LogoutRequest.prototype.getQuick = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.LogoutRequest} returns this
 */
proto.k3900.LogoutRequest.prototype.setQuick = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ExternalStorageMsg.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExternalStorageMsg.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ExternalStorageMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExternalStorageMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExternalStorageMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        mount: jspb.Message.getFieldWithDefault(msg, 1, ""),
        usbDrivesList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExternalStorageMsg}
 */
proto.k3900.ExternalStorageMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExternalStorageMsg();
  return proto.k3900.ExternalStorageMsg.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExternalStorageMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExternalStorageMsg}
 */
proto.k3900.ExternalStorageMsg.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMount(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addUsbDrives(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExternalStorageMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExternalStorageMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExternalStorageMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExternalStorageMsg.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMount();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUsbDrivesList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
};

/**
 * optional string mount = 1;
 * @return {string}
 */
proto.k3900.ExternalStorageMsg.prototype.getMount = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExternalStorageMsg} returns this
 */
proto.k3900.ExternalStorageMsg.prototype.setMount = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated string usb_drives = 2;
 * @return {!Array<string>}
 */
proto.k3900.ExternalStorageMsg.prototype.getUsbDrivesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.ExternalStorageMsg} returns this
 */
proto.k3900.ExternalStorageMsg.prototype.setUsbDrivesList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.ExternalStorageMsg} returns this
 */
proto.k3900.ExternalStorageMsg.prototype.addUsbDrives = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ExternalStorageMsg} returns this
 */
proto.k3900.ExternalStorageMsg.prototype.clearUsbDrivesList = function () {
  return this.setUsbDrivesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.StorageRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.StorageRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.StorageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.StorageRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        credentials:
          (f = msg.getCredentials()) &&
          proto.k3900.Credentials.toObject(includeInstance, f),
        autoConnect: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        memid: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.StorageRequest}
 */
proto.k3900.StorageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.StorageRequest();
  return proto.k3900.StorageRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.StorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.StorageRequest}
 */
proto.k3900.StorageRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new proto.k3900.Credentials();
        reader.readMessage(
          value,
          proto.k3900.Credentials.deserializeBinaryFromReader
        );
        msg.setCredentials(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutoConnect(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.StorageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.StorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.StorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.StorageRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(2, f, proto.k3900.Credentials.serializeBinaryToWriter);
  }
  f = message.getAutoConnect();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.StorageRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.StorageRequest} returns this
 */
proto.k3900.StorageRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Credentials credentials = 2;
 * @return {?proto.k3900.Credentials}
 */
proto.k3900.StorageRequest.prototype.getCredentials = function () {
  return /** @type{?proto.k3900.Credentials} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Credentials, 2)
  );
};

/**
 * @param {?proto.k3900.Credentials|undefined} value
 * @return {!proto.k3900.StorageRequest} returns this
 */
proto.k3900.StorageRequest.prototype.setCredentials = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.StorageRequest} returns this
 */
proto.k3900.StorageRequest.prototype.clearCredentials = function () {
  return this.setCredentials(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.StorageRequest.prototype.hasCredentials = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool auto_connect = 3;
 * @return {boolean}
 */
proto.k3900.StorageRequest.prototype.getAutoConnect = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.StorageRequest} returns this
 */
proto.k3900.StorageRequest.prototype.setAutoConnect = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional string memid = 4;
 * @return {string}
 */
proto.k3900.StorageRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.StorageRequest} returns this
 */
proto.k3900.StorageRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PingRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.PingRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PingRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PingRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ping: jspb.Message.getFieldWithDefault(msg, 1, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PingRequest}
 */
proto.k3900.PingRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PingRequest();
  return proto.k3900.PingRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PingRequest}
 */
proto.k3900.PingRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPing(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PingRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PingRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPing();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional int32 ping = 1;
 * @return {number}
 */
proto.k3900.PingRequest.prototype.getPing = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.PingRequest} returns this
 */
proto.k3900.PingRequest.prototype.setPing = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.PingRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PingRequest} returns this
 */
proto.k3900.PingRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ElemList.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ElemList.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ElemList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ElemList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ElemList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        elemsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ElemList}
 */
proto.k3900.ElemList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ElemList();
  return proto.k3900.ElemList.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ElemList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ElemList}
 */
proto.k3900.ElemList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addElems(values[i]);
        }
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ElemList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ElemList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ElemList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ElemList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getElemsList();
  if (f.length > 0) {
    writer.writePackedInt32(1, f);
  }
};

/**
 * repeated int32 elems = 1;
 * @return {!Array<number>}
 */
proto.k3900.ElemList.prototype.getElemsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.ElemList} returns this
 */
proto.k3900.ElemList.prototype.setElemsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.ElemList} returns this
 */
proto.k3900.ElemList.prototype.addElems = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ElemList} returns this
 */
proto.k3900.ElemList.prototype.clearElemsList = function () {
  return this.setElemsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MaskMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.MaskMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MaskMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MaskMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        on: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        element: jspb.Message.getFieldWithDefault(msg, 2, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MaskMsg}
 */
proto.k3900.MaskMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MaskMsg();
  return proto.k3900.MaskMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MaskMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MaskMsg}
 */
proto.k3900.MaskMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setOn(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setElement(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MaskMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MaskMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MaskMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MaskMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getOn();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getElement();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional bool on = 1;
 * @return {boolean}
 */
proto.k3900.MaskMsg.prototype.getOn = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.MaskMsg} returns this
 */
proto.k3900.MaskMsg.prototype.setOn = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional int32 element = 2;
 * @return {number}
 */
proto.k3900.MaskMsg.prototype.getElement = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.MaskMsg} returns this
 */
proto.k3900.MaskMsg.prototype.setElement = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.MaskMsg.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.MaskMsg} returns this
 */
proto.k3900.MaskMsg.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BlankRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.BlankRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BlankRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BlankRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BlankRequest}
 */
proto.k3900.BlankRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BlankRequest();
  return proto.k3900.BlankRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BlankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BlankRequest}
 */
proto.k3900.BlankRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BlankRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BlankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BlankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BlankRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.BlankRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BlankRequest} returns this
 */
proto.k3900.BlankRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ProbeList.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeList.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        namesList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeList}
 */
proto.k3900.ProbeList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeList();
  return proto.k3900.ProbeList.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeList}
 */
proto.k3900.ProbeList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addNames(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.k3900.ProbeList.prototype.getNamesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.ProbeList} returns this
 */
proto.k3900.ProbeList.prototype.setNamesList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.ProbeList} returns this
 */
proto.k3900.ProbeList.prototype.addNames = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ProbeList} returns this
 */
proto.k3900.ProbeList.prototype.clearNamesList = function () {
  return this.setNamesList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.PresetList.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PresetList.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.PresetList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PresetList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PresetList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dflt: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        namesList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PresetList}
 */
proto.k3900.PresetList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PresetList();
  return proto.k3900.PresetList.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PresetList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PresetList}
 */
proto.k3900.PresetList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDflt(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addNames(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PresetList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PresetList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PresetList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PresetList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDflt();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
};

/**
 * optional bool dflt = 1;
 * @return {boolean}
 */
proto.k3900.PresetList.prototype.getDflt = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.PresetList} returns this
 */
proto.k3900.PresetList.prototype.setDflt = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * repeated string names = 2;
 * @return {!Array<string>}
 */
proto.k3900.PresetList.prototype.getNamesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.PresetList} returns this
 */
proto.k3900.PresetList.prototype.setNamesList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.PresetList} returns this
 */
proto.k3900.PresetList.prototype.addNames = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.PresetList} returns this
 */
proto.k3900.PresetList.prototype.clearNamesList = function () {
  return this.setNamesList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.StringList.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.StringList.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.StringList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.StringList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.StringList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        namesList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.StringList}
 */
proto.k3900.StringList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.StringList();
  return proto.k3900.StringList.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.StringList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.StringList}
 */
proto.k3900.StringList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addNames(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.StringList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.StringList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.StringList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.StringList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.k3900.StringList.prototype.getNamesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.StringList} returns this
 */
proto.k3900.StringList.prototype.setNamesList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.StringList} returns this
 */
proto.k3900.StringList.prototype.addNames = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.StringList} returns this
 */
proto.k3900.StringList.prototype.clearNamesList = function () {
  return this.setNamesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.NameRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.NameRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.NameRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.NameRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.NameRequest}
 */
proto.k3900.NameRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.NameRequest();
  return proto.k3900.NameRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.NameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.NameRequest}
 */
proto.k3900.NameRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.NameRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.NameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.NameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.NameRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.NameRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.NameRequest} returns this
 */
proto.k3900.NameRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.NameRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.NameRequest} returns this
 */
proto.k3900.NameRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProbeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ProbeRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProbeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProbeRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProbeRequest}
 */
proto.k3900.ProbeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProbeRequest();
  return proto.k3900.ProbeRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProbeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProbeRequest}
 */
proto.k3900.ProbeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProbeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProbeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProbeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProbeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.ProbeRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeRequest} returns this
 */
proto.k3900.ProbeRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.ProbeRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProbeRequest} returns this
 */
proto.k3900.ProbeRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.WriteProbeRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.WriteProbeRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.WriteProbeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.WriteProbeRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        tpn: jspb.Message.getFieldWithDefault(msg, 2, 0),
        id: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.WriteProbeRequest}
 */
proto.k3900.WriteProbeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.WriteProbeRequest();
  return proto.k3900.WriteProbeRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.WriteProbeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.WriteProbeRequest}
 */
proto.k3900.WriteProbeRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTpn(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.WriteProbeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.WriteProbeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.WriteProbeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.WriteProbeRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getTpn();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.WriteProbeRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.WriteProbeRequest} returns this
 */
proto.k3900.WriteProbeRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 tpn = 2;
 * @return {number}
 */
proto.k3900.WriteProbeRequest.prototype.getTpn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.WriteProbeRequest} returns this
 */
proto.k3900.WriteProbeRequest.prototype.setTpn = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.k3900.WriteProbeRequest.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.WriteProbeRequest} returns this
 */
proto.k3900.WriteProbeRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FilterRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.FilterRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FilterRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FilterRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        index: jspb.Message.getFieldWithDefault(msg, 1, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FilterRequest}
 */
proto.k3900.FilterRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FilterRequest();
  return proto.k3900.FilterRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FilterRequest}
 */
proto.k3900.FilterRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FilterRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FilterRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.k3900.FilterRequest.prototype.getIndex = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.FilterRequest} returns this
 */
proto.k3900.FilterRequest.prototype.setIndex = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.FilterRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.FilterRequest} returns this
 */
proto.k3900.FilterRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ClearErrorsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ClearErrorsRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ClearErrorsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ClearErrorsRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ClearErrorsRequest}
 */
proto.k3900.ClearErrorsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ClearErrorsRequest();
  return proto.k3900.ClearErrorsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ClearErrorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ClearErrorsRequest}
 */
proto.k3900.ClearErrorsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ClearErrorsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ClearErrorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ClearErrorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ClearErrorsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.ClearErrorsRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ClearErrorsRequest} returns this
 */
proto.k3900.ClearErrorsRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.DiagnosticTestsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.DiagnosticTestsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.DiagnosticTestsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.DiagnosticTestsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        board: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.DiagnosticTestsRequest}
 */
proto.k3900.DiagnosticTestsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.DiagnosticTestsRequest();
  return proto.k3900.DiagnosticTestsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.DiagnosticTestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.DiagnosticTestsRequest}
 */
proto.k3900.DiagnosticTestsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setBoard(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.DiagnosticTestsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.DiagnosticTestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.DiagnosticTestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.DiagnosticTestsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getBoard();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.DiagnosticTestsRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DiagnosticTestsRequest} returns this
 */
proto.k3900.DiagnosticTestsRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string board = 2;
 * @return {string}
 */
proto.k3900.DiagnosticTestsRequest.prototype.getBoard = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DiagnosticTestsRequest} returns this
 */
proto.k3900.DiagnosticTestsRequest.prototype.setBoard = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MediaFileRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.MediaFileRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MediaFileRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MediaFileRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MediaFileRequest}
 */
proto.k3900.MediaFileRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MediaFileRequest();
  return proto.k3900.MediaFileRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MediaFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MediaFileRequest}
 */
proto.k3900.MediaFileRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MediaFileRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MediaFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MediaFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MediaFileRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.MediaFileRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.MediaFileRequest} returns this
 */
proto.k3900.MediaFileRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SoftwareUpdateRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.SoftwareUpdateRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SoftwareUpdateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SoftwareUpdateRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
        file: msg.getFile_asB64(),
        filesize: jspb.Message.getFieldWithDefault(msg, 4, 0),
        md5sum: msg.getMd5sum_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SoftwareUpdateRequest}
 */
proto.k3900.SoftwareUpdateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SoftwareUpdateRequest();
  return proto.k3900.SoftwareUpdateRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SoftwareUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SoftwareUpdateRequest}
 */
proto.k3900.SoftwareUpdateRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFilename(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setFile(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFilesize(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setMd5sum(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SoftwareUpdateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SoftwareUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SoftwareUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SoftwareUpdateRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
  f = message.getFilesize();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getMd5sum_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SoftwareUpdateRequest} returns this
 */
proto.k3900.SoftwareUpdateRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string filename = 2;
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getFilename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SoftwareUpdateRequest} returns this
 */
proto.k3900.SoftwareUpdateRequest.prototype.setFilename = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bytes file = 3;
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getFile = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes file = 3;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getFile_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getFile()));
};

/**
 * optional bytes file = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getFile_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getFile()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.SoftwareUpdateRequest} returns this
 */
proto.k3900.SoftwareUpdateRequest.prototype.setFile = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

/**
 * optional int32 filesize = 4;
 * @return {number}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getFilesize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SoftwareUpdateRequest} returns this
 */
proto.k3900.SoftwareUpdateRequest.prototype.setFilesize = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bytes md5sum = 5;
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getMd5sum = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes md5sum = 5;
 * This is a type-conversion wrapper around `getMd5sum()`
 * @return {string}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getMd5sum_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getMd5sum()));
};

/**
 * optional bytes md5sum = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMd5sum()`
 * @return {!Uint8Array}
 */
proto.k3900.SoftwareUpdateRequest.prototype.getMd5sum_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getMd5sum()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.SoftwareUpdateRequest} returns this
 */
proto.k3900.SoftwareUpdateRequest.prototype.setMd5sum = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MediaUpdateRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.MediaUpdateRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MediaUpdateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MediaUpdateRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        exit: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        state: jspb.Message.getFieldWithDefault(msg, 2, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MediaUpdateRequest}
 */
proto.k3900.MediaUpdateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MediaUpdateRequest();
  return proto.k3900.MediaUpdateRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MediaUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MediaUpdateRequest}
 */
proto.k3900.MediaUpdateRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setExit(value);
        break;
      case 2:
        var value = /** @type {!proto.k3900.MediaControlType} */ (
          reader.readEnum()
        );
        msg.setState(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MediaUpdateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MediaUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MediaUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MediaUpdateRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getExit();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional bool exit = 1;
 * @return {boolean}
 */
proto.k3900.MediaUpdateRequest.prototype.getExit = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.MediaUpdateRequest} returns this
 */
proto.k3900.MediaUpdateRequest.prototype.setExit = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional MediaControlType state = 2;
 * @return {!proto.k3900.MediaControlType}
 */
proto.k3900.MediaUpdateRequest.prototype.getState = function () {
  return /** @type {!proto.k3900.MediaControlType} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.k3900.MediaControlType} value
 * @return {!proto.k3900.MediaUpdateRequest} returns this
 */
proto.k3900.MediaUpdateRequest.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.MediaUpdateRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.MediaUpdateRequest} returns this
 */
proto.k3900.MediaUpdateRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MediaFile.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.MediaFile.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MediaFile} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MediaFile.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        video: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        still: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        name: jspb.Message.getFieldWithDefault(msg, 3, ""),
        duration: jspb.Message.getFieldWithDefault(msg, 4, 0),
        data: msg.getData_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MediaFile}
 */
proto.k3900.MediaFile.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MediaFile();
  return proto.k3900.MediaFile.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MediaFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MediaFile}
 */
proto.k3900.MediaFile.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setVideo(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setStill(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setDuration(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MediaFile.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MediaFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MediaFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MediaFile.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getVideo();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getStill();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(4, f);
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
};

/**
 * optional bool video = 1;
 * @return {boolean}
 */
proto.k3900.MediaFile.prototype.getVideo = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.MediaFile} returns this
 */
proto.k3900.MediaFile.prototype.setVideo = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool still = 2;
 * @return {boolean}
 */
proto.k3900.MediaFile.prototype.getStill = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.MediaFile} returns this
 */
proto.k3900.MediaFile.prototype.setStill = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.k3900.MediaFile.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.MediaFile} returns this
 */
proto.k3900.MediaFile.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional int64 duration = 4;
 * @return {number}
 */
proto.k3900.MediaFile.prototype.getDuration = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.MediaFile} returns this
 */
proto.k3900.MediaFile.prototype.setDuration = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bytes data = 5;
 * @return {string}
 */
proto.k3900.MediaFile.prototype.getData = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.k3900.MediaFile.prototype.getData_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.k3900.MediaFile.prototype.getData_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.MediaFile} returns this
 */
proto.k3900.MediaFile.prototype.setData = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ProgressRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ProgressRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ProgressRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ProgressRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ProgressRequest}
 */
proto.k3900.ProgressRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ProgressRequest();
  return proto.k3900.ProgressRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ProgressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ProgressRequest}
 */
proto.k3900.ProgressRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.k3900.ProgressType} */ (
          reader.readEnum()
        );
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ProgressRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ProgressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ProgressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ProgressRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional ProgressType type = 1;
 * @return {!proto.k3900.ProgressType}
 */
proto.k3900.ProgressRequest.prototype.getType = function () {
  return /** @type {!proto.k3900.ProgressType} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.k3900.ProgressType} value
 * @return {!proto.k3900.ProgressRequest} returns this
 */
proto.k3900.ProgressRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.ProgressRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ProgressRequest} returns this
 */
proto.k3900.ProgressRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Progress.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Progress.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Progress} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Progress.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        percentComplete: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          1,
          0.0
        ),
        msg: jspb.Message.getFieldWithDefault(msg, 2, ""),
        code: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Progress}
 */
proto.k3900.Progress.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Progress();
  return proto.k3900.Progress.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Progress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Progress}
 */
proto.k3900.Progress.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setPercentComplete(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMsg(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Progress.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Progress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Progress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Progress.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPercentComplete();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
};

/**
 * optional float percent_complete = 1;
 * @return {number}
 */
proto.k3900.Progress.prototype.getPercentComplete = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Progress} returns this
 */
proto.k3900.Progress.prototype.setPercentComplete = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional string msg = 2;
 * @return {string}
 */
proto.k3900.Progress.prototype.getMsg = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Progress} returns this
 */
proto.k3900.Progress.prototype.setMsg = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 code = 3;
 * @return {number}
 */
proto.k3900.Progress.prototype.getCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Progress} returns this
 */
proto.k3900.Progress.prototype.setCode = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.DiagnosticTestList.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.DiagnosticTestList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.DiagnosticTestList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.DiagnosticTestList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.DiagnosticTestList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        testList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.DiagnosticTestList}
 */
proto.k3900.DiagnosticTestList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.DiagnosticTestList();
  return proto.k3900.DiagnosticTestList.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.DiagnosticTestList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.DiagnosticTestList}
 */
proto.k3900.DiagnosticTestList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addTest(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.DiagnosticTestList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.DiagnosticTestList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.DiagnosticTestList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.DiagnosticTestList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTestList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string test = 1;
 * @return {!Array<string>}
 */
proto.k3900.DiagnosticTestList.prototype.getTestList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.DiagnosticTestList} returns this
 */
proto.k3900.DiagnosticTestList.prototype.setTestList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.DiagnosticTestList} returns this
 */
proto.k3900.DiagnosticTestList.prototype.addTest = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.DiagnosticTestList} returns this
 */
proto.k3900.DiagnosticTestList.prototype.clearTestList = function () {
  return this.setTestList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.DiagnosticRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.DiagnosticRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.DiagnosticRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.DiagnosticRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        test: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
        board: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.DiagnosticRequest}
 */
proto.k3900.DiagnosticRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.DiagnosticRequest();
  return proto.k3900.DiagnosticRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.DiagnosticRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.DiagnosticRequest}
 */
proto.k3900.DiagnosticRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTest(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setBoard(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.DiagnosticRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.DiagnosticRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.DiagnosticRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.DiagnosticRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getTest();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getBoard();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string test = 1;
 * @return {string}
 */
proto.k3900.DiagnosticRequest.prototype.getTest = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DiagnosticRequest} returns this
 */
proto.k3900.DiagnosticRequest.prototype.setTest = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.DiagnosticRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DiagnosticRequest} returns this
 */
proto.k3900.DiagnosticRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string board = 3;
 * @return {string}
 */
proto.k3900.DiagnosticRequest.prototype.getBoard = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.DiagnosticRequest} returns this
 */
proto.k3900.DiagnosticRequest.prototype.setBoard = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamStore.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ExamStore.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamStore} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamStore.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        thumbnail: msg.getThumbnail_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamStore}
 */
proto.k3900.ExamStore.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamStore();
  return proto.k3900.ExamStore.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamStore}
 */
proto.k3900.ExamStore.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setThumbnail(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamStore.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamStore.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.ExamStore.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamStore} returns this
 */
proto.k3900.ExamStore.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bytes thumbnail = 2;
 * @return {string}
 */
proto.k3900.ExamStore.prototype.getThumbnail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes thumbnail = 2;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.k3900.ExamStore.prototype.getThumbnail_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getThumbnail()));
};

/**
 * optional bytes thumbnail = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.k3900.ExamStore.prototype.getThumbnail_asU8 = function () {
  return /** @type {!Uint8Array} */ (
    jspb.Message.bytesAsU8(this.getThumbnail())
  );
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.ExamStore} returns this
 */
proto.k3900.ExamStore.prototype.setThumbnail = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SaveRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.SaveRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SaveRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SaveRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, 0),
        startFrame: jspb.Message.getFieldWithDefault(msg, 2, 0),
        nFrames: jspb.Message.getFieldWithDefault(msg, 3, 0),
        name: jspb.Message.getFieldWithDefault(msg, 4, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 5, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SaveRequest}
 */
proto.k3900.SaveRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SaveRequest();
  return proto.k3900.SaveRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SaveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SaveRequest}
 */
proto.k3900.SaveRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.k3900.FileType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStartFrame(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setNFrames(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SaveRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SaveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SaveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SaveRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getStartFrame();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getNFrames();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional FileType type = 1;
 * @return {!proto.k3900.FileType}
 */
proto.k3900.SaveRequest.prototype.getType = function () {
  return /** @type {!proto.k3900.FileType} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.k3900.FileType} value
 * @return {!proto.k3900.SaveRequest} returns this
 */
proto.k3900.SaveRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional uint32 start_frame = 2;
 * @return {number}
 */
proto.k3900.SaveRequest.prototype.getStartFrame = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SaveRequest} returns this
 */
proto.k3900.SaveRequest.prototype.setStartFrame = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 n_frames = 3;
 * @return {number}
 */
proto.k3900.SaveRequest.prototype.getNFrames = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SaveRequest} returns this
 */
proto.k3900.SaveRequest.prototype.setNFrames = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string name = 4;
 * @return {string}
 */
proto.k3900.SaveRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SaveRequest} returns this
 */
proto.k3900.SaveRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string memid = 5;
 * @return {string}
 */
proto.k3900.SaveRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SaveRequest} returns this
 */
proto.k3900.SaveRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ClipRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ClipRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ClipRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ClipRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ClipRequest}
 */
proto.k3900.ClipRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ClipRequest();
  return proto.k3900.ClipRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ClipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ClipRequest}
 */
proto.k3900.ClipRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeconds(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ClipRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ClipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ClipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ClipRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional uint32 seconds = 1;
 * @return {number}
 */
proto.k3900.ClipRequest.prototype.getSeconds = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ClipRequest} returns this
 */
proto.k3900.ClipRequest.prototype.setSeconds = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.ClipRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ClipRequest} returns this
 */
proto.k3900.ClipRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.QueryRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.QueryRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.QueryRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.QueryRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pattern: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.QueryRequest}
 */
proto.k3900.QueryRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.QueryRequest();
  return proto.k3900.QueryRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.QueryRequest}
 */
proto.k3900.QueryRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPattern(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.QueryRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.QueryRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string pattern = 1;
 * @return {string}
 */
proto.k3900.QueryRequest.prototype.getPattern = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.QueryRequest} returns this
 */
proto.k3900.QueryRequest.prototype.setPattern = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.QueryRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.QueryRequest} returns this
 */
proto.k3900.QueryRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.PatientRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.PatientRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.PatientRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.PatientRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.PatientRequest}
 */
proto.k3900.PatientRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.PatientRequest();
  return proto.k3900.PatientRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.PatientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.PatientRequest}
 */
proto.k3900.PatientRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.PatientRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.PatientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.PatientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.PatientRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.k3900.PatientRequest.prototype.getPatientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PatientRequest} returns this
 */
proto.k3900.PatientRequest.prototype.setPatientId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.PatientRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.PatientRequest} returns this
 */
proto.k3900.PatientRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Patient.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Patient.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Patient} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Patient.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        first: jspb.Message.getFieldWithDefault(msg, 2, ""),
        last: jspb.Message.getFieldWithDefault(msg, 3, ""),
        birth: jspb.Message.getFieldWithDefault(msg, 4, ""),
        sex: jspb.Message.getFieldWithDefault(msg, 5, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Patient}
 */
proto.k3900.Patient.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Patient();
  return proto.k3900.Patient.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Patient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Patient}
 */
proto.k3900.Patient.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirst(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setLast(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setBirth(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSex(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Patient.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Patient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Patient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Patient.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFirst();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getLast();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getBirth();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.k3900.Patient.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Patient} returns this
 */
proto.k3900.Patient.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string first = 2;
 * @return {string}
 */
proto.k3900.Patient.prototype.getFirst = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Patient} returns this
 */
proto.k3900.Patient.prototype.setFirst = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string last = 3;
 * @return {string}
 */
proto.k3900.Patient.prototype.getLast = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Patient} returns this
 */
proto.k3900.Patient.prototype.setLast = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string birth = 4;
 * @return {string}
 */
proto.k3900.Patient.prototype.getBirth = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Patient} returns this
 */
proto.k3900.Patient.prototype.setBirth = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string sex = 5;
 * @return {string}
 */
proto.k3900.Patient.prototype.getSex = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Patient} returns this
 */
proto.k3900.Patient.prototype.setSex = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamStoresRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ExamStoresRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamStoresRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamStoresRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        exam:
          (f = msg.getExam()) && proto.k3900.Exam.toObject(includeInstance, f),
        patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
        type: jspb.Message.getFieldWithDefault(msg, 3, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamStoresRequest}
 */
proto.k3900.ExamStoresRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamStoresRequest();
  return proto.k3900.ExamStoresRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamStoresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamStoresRequest}
 */
proto.k3900.ExamStoresRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Exam();
        reader.readMessage(value, proto.k3900.Exam.deserializeBinaryFromReader);
        msg.setExam(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientId(value);
        break;
      case 3:
        var value = /** @type {!proto.k3900.FileType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamStoresRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamStoresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamStoresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamStoresRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getExam();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Exam.serializeBinaryToWriter);
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional Exam exam = 1;
 * @return {?proto.k3900.Exam}
 */
proto.k3900.ExamStoresRequest.prototype.getExam = function () {
  return /** @type{?proto.k3900.Exam} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Exam, 1)
  );
};

/**
 * @param {?proto.k3900.Exam|undefined} value
 * @return {!proto.k3900.ExamStoresRequest} returns this
 */
proto.k3900.ExamStoresRequest.prototype.setExam = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ExamStoresRequest} returns this
 */
proto.k3900.ExamStoresRequest.prototype.clearExam = function () {
  return this.setExam(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ExamStoresRequest.prototype.hasExam = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.k3900.ExamStoresRequest.prototype.getPatientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamStoresRequest} returns this
 */
proto.k3900.ExamStoresRequest.prototype.setPatientId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional FileType type = 3;
 * @return {!proto.k3900.FileType}
 */
proto.k3900.ExamStoresRequest.prototype.getType = function () {
  return /** @type {!proto.k3900.FileType} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};

/**
 * @param {!proto.k3900.FileType} value
 * @return {!proto.k3900.ExamStoresRequest} returns this
 */
proto.k3900.ExamStoresRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional string memid = 4;
 * @return {string}
 */
proto.k3900.ExamStoresRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamStoresRequest} returns this
 */
proto.k3900.ExamStoresRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamStoresTypeRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ExamStoresTypeRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamStoresTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamStoresTypeRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        exam:
          (f = msg.getExam()) && proto.k3900.Exam.toObject(includeInstance, f),
        patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamStoresTypeRequest}
 */
proto.k3900.ExamStoresTypeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamStoresTypeRequest();
  return proto.k3900.ExamStoresTypeRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamStoresTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamStoresTypeRequest}
 */
proto.k3900.ExamStoresTypeRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Exam();
        reader.readMessage(value, proto.k3900.Exam.deserializeBinaryFromReader);
        msg.setExam(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamStoresTypeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamStoresTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamStoresTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamStoresTypeRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getExam();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Exam.serializeBinaryToWriter);
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional Exam exam = 1;
 * @return {?proto.k3900.Exam}
 */
proto.k3900.ExamStoresTypeRequest.prototype.getExam = function () {
  return /** @type{?proto.k3900.Exam} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Exam, 1)
  );
};

/**
 * @param {?proto.k3900.Exam|undefined} value
 * @return {!proto.k3900.ExamStoresTypeRequest} returns this
 */
proto.k3900.ExamStoresTypeRequest.prototype.setExam = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ExamStoresTypeRequest} returns this
 */
proto.k3900.ExamStoresTypeRequest.prototype.clearExam = function () {
  return this.setExam(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ExamStoresTypeRequest.prototype.hasExam = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.k3900.ExamStoresTypeRequest.prototype.getPatientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamStoresTypeRequest} returns this
 */
proto.k3900.ExamStoresTypeRequest.prototype.setPatientId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.ExamStoresTypeRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamStoresTypeRequest} returns this
 */
proto.k3900.ExamStoresTypeRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamStoresTypes.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ExamStoresTypes.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamStoresTypes} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamStoresTypes.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        stills: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        videos: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        datasets: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamStoresTypes}
 */
proto.k3900.ExamStoresTypes.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamStoresTypes();
  return proto.k3900.ExamStoresTypes.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamStoresTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamStoresTypes}
 */
proto.k3900.ExamStoresTypes.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setStills(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setVideos(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDatasets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamStoresTypes.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamStoresTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamStoresTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamStoresTypes.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getStills();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getVideos();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getDatasets();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional bool stills = 1;
 * @return {boolean}
 */
proto.k3900.ExamStoresTypes.prototype.getStills = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ExamStoresTypes} returns this
 */
proto.k3900.ExamStoresTypes.prototype.setStills = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool videos = 2;
 * @return {boolean}
 */
proto.k3900.ExamStoresTypes.prototype.getVideos = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ExamStoresTypes} returns this
 */
proto.k3900.ExamStoresTypes.prototype.setVideos = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool datasets = 3;
 * @return {boolean}
 */
proto.k3900.ExamStoresTypes.prototype.getDatasets = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ExamStoresTypes} returns this
 */
proto.k3900.ExamStoresTypes.prototype.setDatasets = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamFileRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ExamFileRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamFileRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamFileRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        patientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
        exam:
          (f = msg.getExam()) && proto.k3900.Exam.toObject(includeInstance, f),
        memid: jspb.Message.getFieldWithDefault(msg, 5, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamFileRequest}
 */
proto.k3900.ExamFileRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamFileRequest();
  return proto.k3900.ExamFileRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamFileRequest}
 */
proto.k3900.ExamFileRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.k3900.FileType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientId(value);
        break;
      case 4:
        var value = new proto.k3900.Exam();
        reader.readMessage(value, proto.k3900.Exam.deserializeBinaryFromReader);
        msg.setExam(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamFileRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamFileRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getExam();
  if (f != null) {
    writer.writeMessage(4, f, proto.k3900.Exam.serializeBinaryToWriter);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional FileType type = 1;
 * @return {!proto.k3900.FileType}
 */
proto.k3900.ExamFileRequest.prototype.getType = function () {
  return /** @type {!proto.k3900.FileType} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.k3900.FileType} value
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.k3900.ExamFileRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string patient_id = 3;
 * @return {string}
 */
proto.k3900.ExamFileRequest.prototype.getPatientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.setPatientId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional Exam exam = 4;
 * @return {?proto.k3900.Exam}
 */
proto.k3900.ExamFileRequest.prototype.getExam = function () {
  return /** @type{?proto.k3900.Exam} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Exam, 4)
  );
};

/**
 * @param {?proto.k3900.Exam|undefined} value
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.setExam = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.clearExam = function () {
  return this.setExam(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ExamFileRequest.prototype.hasExam = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string memid = 5;
 * @return {string}
 */
proto.k3900.ExamFileRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamFileRequest} returns this
 */
proto.k3900.ExamFileRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Exam.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Exam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Exam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Exam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        date: jspb.Message.getFieldWithDefault(msg, 2, ""),
        comments: jspb.Message.getFieldWithDefault(msg, 3, ""),
        url: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Exam}
 */
proto.k3900.Exam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Exam();
  return proto.k3900.Exam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Exam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Exam}
 */
proto.k3900.Exam.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setComments(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUrl(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Exam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Exam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Exam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Exam.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.Exam.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Exam} returns this
 */
proto.k3900.Exam.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string date = 2;
 * @return {string}
 */
proto.k3900.Exam.prototype.getDate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Exam} returns this
 */
proto.k3900.Exam.prototype.setDate = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string comments = 3;
 * @return {string}
 */
proto.k3900.Exam.prototype.getComments = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Exam} returns this
 */
proto.k3900.Exam.prototype.setComments = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string url = 4;
 * @return {string}
 */
proto.k3900.Exam.prototype.getUrl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.Exam} returns this
 */
proto.k3900.Exam.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ExamRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ExamRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ExamRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ExamRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        patient:
          (f = msg.getPatient()) &&
          proto.k3900.Patient.toObject(includeInstance, f),
        exam:
          (f = msg.getExam()) && proto.k3900.Exam.toObject(includeInstance, f),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ExamRequest}
 */
proto.k3900.ExamRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ExamRequest();
  return proto.k3900.ExamRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ExamRequest}
 */
proto.k3900.ExamRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Patient();
        reader.readMessage(
          value,
          proto.k3900.Patient.deserializeBinaryFromReader
        );
        msg.setPatient(value);
        break;
      case 2:
        var value = new proto.k3900.Exam();
        reader.readMessage(value, proto.k3900.Exam.deserializeBinaryFromReader);
        msg.setExam(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ExamRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ExamRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Patient.serializeBinaryToWriter);
  }
  f = message.getExam();
  if (f != null) {
    writer.writeMessage(2, f, proto.k3900.Exam.serializeBinaryToWriter);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional Patient patient = 1;
 * @return {?proto.k3900.Patient}
 */
proto.k3900.ExamRequest.prototype.getPatient = function () {
  return /** @type{?proto.k3900.Patient} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Patient, 1)
  );
};

/**
 * @param {?proto.k3900.Patient|undefined} value
 * @return {!proto.k3900.ExamRequest} returns this
 */
proto.k3900.ExamRequest.prototype.setPatient = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ExamRequest} returns this
 */
proto.k3900.ExamRequest.prototype.clearPatient = function () {
  return this.setPatient(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ExamRequest.prototype.hasPatient = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Exam exam = 2;
 * @return {?proto.k3900.Exam}
 */
proto.k3900.ExamRequest.prototype.getExam = function () {
  return /** @type{?proto.k3900.Exam} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Exam, 2)
  );
};

/**
 * @param {?proto.k3900.Exam|undefined} value
 * @return {!proto.k3900.ExamRequest} returns this
 */
proto.k3900.ExamRequest.prototype.setExam = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ExamRequest} returns this
 */
proto.k3900.ExamRequest.prototype.clearExam = function () {
  return this.setExam(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ExamRequest.prototype.hasExam = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.ExamRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ExamRequest} returns this
 */
proto.k3900.ExamRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.FrameMsg.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FrameMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FrameMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FrameMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FrameMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        framesList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FrameMsg}
 */
proto.k3900.FrameMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FrameMsg();
  return proto.k3900.FrameMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FrameMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FrameMsg}
 */
proto.k3900.FrameMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited()
            ? reader.readPackedUint32()
            : [reader.readUint32()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addFrames(values[i]);
        }
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FrameMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FrameMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FrameMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FrameMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFramesList();
  if (f.length > 0) {
    writer.writePackedUint32(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * repeated uint32 frames = 1;
 * @return {!Array<number>}
 */
proto.k3900.FrameMsg.prototype.getFramesList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.FrameMsg} returns this
 */
proto.k3900.FrameMsg.prototype.setFramesList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.FrameMsg} returns this
 */
proto.k3900.FrameMsg.prototype.addFrames = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.FrameMsg} returns this
 */
proto.k3900.FrameMsg.prototype.clearFramesList = function () {
  return this.setFramesList([]);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.FrameMsg.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.FrameMsg} returns this
 */
proto.k3900.FrameMsg.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.FilterList.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FilterList.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FilterList.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FilterList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FilterList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        filterList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FilterList}
 */
proto.k3900.FilterList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FilterList();
  return proto.k3900.FilterList.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FilterList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FilterList}
 */
proto.k3900.FilterList.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addFilter(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FilterList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FilterList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FilterList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FilterList.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string filter = 1;
 * @return {!Array<string>}
 */
proto.k3900.FilterList.prototype.getFilterList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.FilterList} returns this
 */
proto.k3900.FilterList.prototype.setFilterList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.FilterList} returns this
 */
proto.k3900.FilterList.prototype.addFilter = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.FilterList} returns this
 */
proto.k3900.FilterList.prototype.clearFilterList = function () {
  return this.setFilterList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Pixel.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Pixel.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Pixel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Pixel.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        x: jspb.Message.getFieldWithDefault(msg, 1, 0),
        y: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Pixel}
 */
proto.k3900.Pixel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Pixel();
  return proto.k3900.Pixel.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Pixel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Pixel}
 */
proto.k3900.Pixel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setX(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setY(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Pixel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Pixel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Pixel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Pixel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 x = 1;
 * @return {number}
 */
proto.k3900.Pixel.prototype.getX = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Pixel} returns this
 */
proto.k3900.Pixel.prototype.setX = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 y = 2;
 * @return {number}
 */
proto.k3900.Pixel.prototype.getY = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Pixel} returns this
 */
proto.k3900.Pixel.prototype.setY = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FrameRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FrameRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FrameRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FrameRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FrameRequest}
 */
proto.k3900.FrameRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FrameRequest();
  return proto.k3900.FrameRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FrameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FrameRequest}
 */
proto.k3900.FrameRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FrameRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FrameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FrameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FrameRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.FrameRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.FrameRequest} returns this
 */
proto.k3900.FrameRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MeasurementRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.MeasurementRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MeasurementRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MeasurementRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MeasurementRequest}
 */
proto.k3900.MeasurementRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MeasurementRequest();
  return proto.k3900.MeasurementRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MeasurementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MeasurementRequest}
 */
proto.k3900.MeasurementRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MeasurementRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MeasurementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MeasurementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MeasurementRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.MeasurementRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.MeasurementRequest} returns this
 */
proto.k3900.MeasurementRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.EditMeasurementRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.EditMeasurementRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.EditMeasurementRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.EditMeasurementRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        idx: jspb.Message.getFieldWithDefault(msg, 1, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.EditMeasurementRequest}
 */
proto.k3900.EditMeasurementRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.EditMeasurementRequest();
  return proto.k3900.EditMeasurementRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.EditMeasurementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.EditMeasurementRequest}
 */
proto.k3900.EditMeasurementRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setIdx(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.EditMeasurementRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.EditMeasurementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.EditMeasurementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.EditMeasurementRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getIdx();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional int32 idx = 1;
 * @return {number}
 */
proto.k3900.EditMeasurementRequest.prototype.getIdx = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.EditMeasurementRequest} returns this
 */
proto.k3900.EditMeasurementRequest.prototype.setIdx = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.EditMeasurementRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.EditMeasurementRequest} returns this
 */
proto.k3900.EditMeasurementRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.CursorMoveRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.CursorMoveRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.CursorMoveRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.CursorMoveRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        delta:
          (f = msg.getDelta()) &&
          proto.k3900.Pixel.toObject(includeInstance, f),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.CursorMoveRequest}
 */
proto.k3900.CursorMoveRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.CursorMoveRequest();
  return proto.k3900.CursorMoveRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.CursorMoveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.CursorMoveRequest}
 */
proto.k3900.CursorMoveRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Pixel();
        reader.readMessage(
          value,
          proto.k3900.Pixel.deserializeBinaryFromReader
        );
        msg.setDelta(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.CursorMoveRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.CursorMoveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.CursorMoveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.CursorMoveRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDelta();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Pixel.serializeBinaryToWriter);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional Pixel delta = 1;
 * @return {?proto.k3900.Pixel}
 */
proto.k3900.CursorMoveRequest.prototype.getDelta = function () {
  return /** @type{?proto.k3900.Pixel} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Pixel, 1)
  );
};

/**
 * @param {?proto.k3900.Pixel|undefined} value
 * @return {!proto.k3900.CursorMoveRequest} returns this
 */
proto.k3900.CursorMoveRequest.prototype.setDelta = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.CursorMoveRequest} returns this
 */
proto.k3900.CursorMoveRequest.prototype.clearDelta = function () {
  return this.setDelta(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.CursorMoveRequest.prototype.hasDelta = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.CursorMoveRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.CursorMoveRequest} returns this
 */
proto.k3900.CursorMoveRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ShutdownRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.ShutdownRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ShutdownRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ShutdownRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
        reboot: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ShutdownRequest}
 */
proto.k3900.ShutdownRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ShutdownRequest();
  return proto.k3900.ShutdownRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ShutdownRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ShutdownRequest}
 */
proto.k3900.ShutdownRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setReboot(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ShutdownRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ShutdownRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ShutdownRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ShutdownRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getReboot();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.ShutdownRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ShutdownRequest} returns this
 */
proto.k3900.ShutdownRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool reboot = 2;
 * @return {boolean}
 */
proto.k3900.ShutdownRequest.prototype.getReboot = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ShutdownRequest} returns this
 */
proto.k3900.ShutdownRequest.prototype.setReboot = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemStateRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.SystemStateRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemStateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemStateRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        memid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemStateRequest}
 */
proto.k3900.SystemStateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemStateRequest();
  return proto.k3900.SystemStateRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemStateRequest}
 */
proto.k3900.SystemStateRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemStateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemStateRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string memid = 1;
 * @return {string}
 */
proto.k3900.SystemStateRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemStateRequest} returns this
 */
proto.k3900.SystemStateRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ImageRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ImageRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ImageRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        time: jspb.Message.getFieldWithDefault(msg, 1, 0),
        chunkSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
        memid: jspb.Message.getFieldWithDefault(msg, 3, ""),
        jpg: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ImageRequest}
 */
proto.k3900.ImageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ImageRequest();
  return proto.k3900.ImageRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ImageRequest}
 */
proto.k3900.ImageRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTime(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChunkSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setJpg(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ImageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ImageRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getChunkSize();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getJpg();
  if (f) {
    writer.writeBool(4, f);
  }
};

/**
 * optional uint64 time = 1;
 * @return {number}
 */
proto.k3900.ImageRequest.prototype.getTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageRequest} returns this
 */
proto.k3900.ImageRequest.prototype.setTime = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint64 chunk_size = 2;
 * @return {number}
 */
proto.k3900.ImageRequest.prototype.getChunkSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageRequest} returns this
 */
proto.k3900.ImageRequest.prototype.setChunkSize = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string memid = 3;
 * @return {string}
 */
proto.k3900.ImageRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ImageRequest} returns this
 */
proto.k3900.ImageRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional bool jpg = 4;
 * @return {boolean}
 */
proto.k3900.ImageRequest.prototype.getJpg = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageRequest} returns this
 */
proto.k3900.ImageRequest.prototype.setJpg = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Measurement.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Measurement.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Measurement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Measurement.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        p1: (f = msg.getP1()) && proto.k3900.Pixel.toObject(includeInstance, f),
        p2: (f = msg.getP2()) && proto.k3900.Pixel.toObject(includeInstance, f),
        length: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Measurement}
 */
proto.k3900.Measurement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Measurement();
  return proto.k3900.Measurement.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Measurement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Measurement}
 */
proto.k3900.Measurement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Pixel();
        reader.readMessage(
          value,
          proto.k3900.Pixel.deserializeBinaryFromReader
        );
        msg.setP1(value);
        break;
      case 2:
        var value = new proto.k3900.Pixel();
        reader.readMessage(
          value,
          proto.k3900.Pixel.deserializeBinaryFromReader
        );
        msg.setP2(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setLength(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Measurement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Measurement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Measurement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Measurement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getP1();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.Pixel.serializeBinaryToWriter);
  }
  f = message.getP2();
  if (f != null) {
    writer.writeMessage(2, f, proto.k3900.Pixel.serializeBinaryToWriter);
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
};

/**
 * optional Pixel p1 = 1;
 * @return {?proto.k3900.Pixel}
 */
proto.k3900.Measurement.prototype.getP1 = function () {
  return /** @type{?proto.k3900.Pixel} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Pixel, 1)
  );
};

/**
 * @param {?proto.k3900.Pixel|undefined} value
 * @return {!proto.k3900.Measurement} returns this
 */
proto.k3900.Measurement.prototype.setP1 = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.Measurement} returns this
 */
proto.k3900.Measurement.prototype.clearP1 = function () {
  return this.setP1(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.Measurement.prototype.hasP1 = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Pixel p2 = 2;
 * @return {?proto.k3900.Pixel}
 */
proto.k3900.Measurement.prototype.getP2 = function () {
  return /** @type{?proto.k3900.Pixel} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Pixel, 2)
  );
};

/**
 * @param {?proto.k3900.Pixel|undefined} value
 * @return {!proto.k3900.Measurement} returns this
 */
proto.k3900.Measurement.prototype.setP2 = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.Measurement} returns this
 */
proto.k3900.Measurement.prototype.clearP2 = function () {
  return this.setP2(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.Measurement.prototype.hasP2 = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional float length = 3;
 * @return {number}
 */
proto.k3900.Measurement.prototype.getLength = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.Measurement} returns this
 */
proto.k3900.Measurement.prototype.setLength = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.Measurements.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Measurements.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Measurements.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Measurements} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Measurements.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        measurementsList: jspb.Message.toObjectList(
          msg.getMeasurementsList(),
          proto.k3900.Measurement.toObject,
          includeInstance
        ),
        frame: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Measurements}
 */
proto.k3900.Measurements.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Measurements();
  return proto.k3900.Measurements.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Measurements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Measurements}
 */
proto.k3900.Measurements.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.Measurement();
        reader.readMessage(
          value,
          proto.k3900.Measurement.deserializeBinaryFromReader
        );
        msg.addMeasurements(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setFrame(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Measurements.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Measurements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Measurements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Measurements.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.k3900.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getFrame();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
};

/**
 * repeated Measurement measurements = 1;
 * @return {!Array<!proto.k3900.Measurement>}
 */
proto.k3900.Measurements.prototype.getMeasurementsList = function () {
  return /** @type{!Array<!proto.k3900.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.Measurement, 1)
  );
};

/**
 * @param {!Array<!proto.k3900.Measurement>} value
 * @return {!proto.k3900.Measurements} returns this
 */
proto.k3900.Measurements.prototype.setMeasurementsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.k3900.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.Measurement}
 */
proto.k3900.Measurements.prototype.addMeasurements = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.k3900.Measurement,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.Measurements} returns this
 */
proto.k3900.Measurements.prototype.clearMeasurementsList = function () {
  return this.setMeasurementsList([]);
};

/**
 * optional uint32 frame = 2;
 * @return {number}
 */
proto.k3900.Measurements.prototype.getFrame = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Measurements} returns this
 */
proto.k3900.Measurements.prototype.setFrame = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.MediaRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.MediaRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.MediaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.MediaRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        state: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.MediaRequest}
 */
proto.k3900.MediaRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.MediaRequest();
  return proto.k3900.MediaRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.MediaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.MediaRequest}
 */
proto.k3900.MediaRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value);
        break;
      case 2:
        var value = /** @type {!proto.k3900.MediaControlType} */ (
          reader.readEnum()
        );
        msg.setState(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.MediaRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.MediaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.MediaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.MediaRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.k3900.MediaRequest.prototype.getActive = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.MediaRequest} returns this
 */
proto.k3900.MediaRequest.prototype.setActive = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional MediaControlType state = 2;
 * @return {!proto.k3900.MediaControlType}
 */
proto.k3900.MediaRequest.prototype.getState = function () {
  return /** @type {!proto.k3900.MediaControlType} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.k3900.MediaControlType} value
 * @return {!proto.k3900.MediaRequest} returns this
 */
proto.k3900.MediaRequest.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.FpgaStatus.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FpgaStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FpgaStatus.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FpgaStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FpgaStatus.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        version: jspb.Message.getFieldWithDefault(msg, 2, ""),
        temp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        vccint: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        vccaux: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
        vccbram: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        errorsList:
          (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FpgaStatus}
 */
proto.k3900.FpgaStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FpgaStatus();
  return proto.k3900.FpgaStatus.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FpgaStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FpgaStatus}
 */
proto.k3900.FpgaStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setVersion(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTemp(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setVccint(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setVccaux(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setVccbram(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addErrors(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FpgaStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FpgaStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FpgaStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FpgaStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getTemp();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getVccint();
  if (f !== 0.0) {
    writer.writeFloat(4, f);
  }
  f = message.getVccaux();
  if (f !== 0.0) {
    writer.writeFloat(5, f);
  }
  f = message.getVccbram();
  if (f !== 0.0) {
    writer.writeFloat(6, f);
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.k3900.FpgaStatus.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string version = 2;
 * @return {string}
 */
proto.k3900.FpgaStatus.prototype.getVersion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setVersion = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional float temp = 3;
 * @return {number}
 */
proto.k3900.FpgaStatus.prototype.getTemp = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setTemp = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float vccint = 4;
 * @return {number}
 */
proto.k3900.FpgaStatus.prototype.getVccint = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setVccint = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional float vccaux = 5;
 * @return {number}
 */
proto.k3900.FpgaStatus.prototype.getVccaux = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setVccaux = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

/**
 * optional float vccbram = 6;
 * @return {number}
 */
proto.k3900.FpgaStatus.prototype.getVccbram = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setVccbram = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * repeated string errors = 7;
 * @return {!Array<string>}
 */
proto.k3900.FpgaStatus.prototype.getErrorsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.setErrorsList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.addErrors = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.FpgaStatus} returns this
 */
proto.k3900.FpgaStatus.prototype.clearErrorsList = function () {
  return this.setErrorsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.SystemStatus.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.SystemStatus.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemStatus.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        errorsList:
          (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemStatus}
 */
proto.k3900.SystemStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemStatus();
  return proto.k3900.SystemStatus.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemStatus}
 */
proto.k3900.SystemStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addErrors(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.SystemStatus.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemStatus} returns this
 */
proto.k3900.SystemStatus.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated string errors = 2;
 * @return {!Array<string>}
 */
proto.k3900.SystemStatus.prototype.getErrorsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.k3900.SystemStatus} returns this
 */
proto.k3900.SystemStatus.prototype.setErrorsList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.k3900.SystemStatus} returns this
 */
proto.k3900.SystemStatus.prototype.addErrors = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemStatus} returns this
 */
proto.k3900.SystemStatus.prototype.clearErrorsList = function () {
  return this.setErrorsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.Fan.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.Fan.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.Fan} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.Fan.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        speed: jspb.Message.getFieldWithDefault(msg, 1, 0),
        dutyCycle: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.Fan}
 */
proto.k3900.Fan.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.Fan();
  return proto.k3900.Fan.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.Fan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.Fan}
 */
proto.k3900.Fan.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSpeed(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setDutyCycle(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.Fan.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.Fan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.Fan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.Fan.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSpeed();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getDutyCycle();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 speed = 1;
 * @return {number}
 */
proto.k3900.Fan.prototype.getSpeed = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Fan} returns this
 */
proto.k3900.Fan.prototype.setSpeed = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 duty_cycle = 2;
 * @return {number}
 */
proto.k3900.Fan.prototype.getDutyCycle = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.Fan} returns this
 */
proto.k3900.Fan.prototype.setDutyCycle = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.FanController.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FanController.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.FanController.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FanController} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FanController.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        deviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        revision: jspb.Message.getFieldWithDefault(msg, 3, 0),
        fansList: jspb.Message.toObjectList(
          msg.getFansList(),
          proto.k3900.Fan.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FanController}
 */
proto.k3900.FanController.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FanController();
  return proto.k3900.FanController.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FanController} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FanController}
 */
proto.k3900.FanController.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setDeviceId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCompanyId(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setRevision(value);
        break;
      case 4:
        var value = new proto.k3900.Fan();
        reader.readMessage(value, proto.k3900.Fan.deserializeBinaryFromReader);
        msg.addFans(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FanController.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FanController.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FanController} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FanController.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getFansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.k3900.Fan.serializeBinaryToWriter);
  }
};

/**
 * optional int32 device_id = 1;
 * @return {number}
 */
proto.k3900.FanController.prototype.getDeviceId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.FanController} returns this
 */
proto.k3900.FanController.prototype.setDeviceId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.k3900.FanController.prototype.getCompanyId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.FanController} returns this
 */
proto.k3900.FanController.prototype.setCompanyId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 revision = 3;
 * @return {number}
 */
proto.k3900.FanController.prototype.getRevision = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.FanController} returns this
 */
proto.k3900.FanController.prototype.setRevision = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * repeated Fan fans = 4;
 * @return {!Array<!proto.k3900.Fan>}
 */
proto.k3900.FanController.prototype.getFansList = function () {
  return /** @type{!Array<!proto.k3900.Fan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.Fan, 4)
  );
};

/**
 * @param {!Array<!proto.k3900.Fan>} value
 * @return {!proto.k3900.FanController} returns this
 */
proto.k3900.FanController.prototype.setFansList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.k3900.Fan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.Fan}
 */
proto.k3900.FanController.prototype.addFans = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    4,
    opt_value,
    proto.k3900.Fan,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.FanController} returns this
 */
proto.k3900.FanController.prototype.clearFansList = function () {
  return this.setFansList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ErrorReport.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ErrorReport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ErrorReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ErrorReport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        critical: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        msg: jspb.Message.getFieldWithDefault(msg, 2, ""),
        criticalTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
        level: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ErrorReport}
 */
proto.k3900.ErrorReport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ErrorReport();
  return proto.k3900.ErrorReport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ErrorReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ErrorReport}
 */
proto.k3900.ErrorReport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCritical(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMsg(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCriticalTime(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLevel(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ErrorReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ErrorReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ErrorReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ErrorReport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCritical();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCriticalTime();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
};

/**
 * optional bool critical = 1;
 * @return {boolean}
 */
proto.k3900.ErrorReport.prototype.getCritical = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ErrorReport} returns this
 */
proto.k3900.ErrorReport.prototype.setCritical = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional string msg = 2;
 * @return {string}
 */
proto.k3900.ErrorReport.prototype.getMsg = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.ErrorReport} returns this
 */
proto.k3900.ErrorReport.prototype.setMsg = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 critical_time = 3;
 * @return {number}
 */
proto.k3900.ErrorReport.prototype.getCriticalTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ErrorReport} returns this
 */
proto.k3900.ErrorReport.prototype.setCriticalTime = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 level = 4;
 * @return {number}
 */
proto.k3900.ErrorReport.prototype.getLevel = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ErrorReport} returns this
 */
proto.k3900.ErrorReport.prototype.setLevel = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.HighVoltageReadings.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.HighVoltageReadings.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.HighVoltageReadings} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.HighVoltageReadings.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pos: jspb.Message.getFieldWithDefault(msg, 1, 0),
        neg: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.HighVoltageReadings}
 */
proto.k3900.HighVoltageReadings.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.HighVoltageReadings();
  return proto.k3900.HighVoltageReadings.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.HighVoltageReadings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.HighVoltageReadings}
 */
proto.k3900.HighVoltageReadings.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPos(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNeg(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.HighVoltageReadings.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.HighVoltageReadings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.HighVoltageReadings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.HighVoltageReadings.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getPos();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getNeg();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 pos = 1;
 * @return {number}
 */
proto.k3900.HighVoltageReadings.prototype.getPos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.HighVoltageReadings} returns this
 */
proto.k3900.HighVoltageReadings.prototype.setPos = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 neg = 2;
 * @return {number}
 */
proto.k3900.HighVoltageReadings.prototype.getNeg = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.HighVoltageReadings} returns this
 */
proto.k3900.HighVoltageReadings.prototype.setNeg = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.ImageChunk.repeatedFields_ = [17];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ImageChunk.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ImageChunk.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ImageChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ImageChunk.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        time: jspb.Message.getFieldWithDefault(msg, 1, 0),
        width: jspb.Message.getFieldWithDefault(msg, 2, 0),
        height: jspb.Message.getFieldWithDefault(msg, 3, 0),
        depth: jspb.Message.getFieldWithDefault(msg, 4, 0),
        xPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
        yPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        zPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        xSpacing: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
        ySpacing: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
        zSpacing: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
        scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
        currentFrame: jspb.Message.getFieldWithDefault(msg, 12, 0),
        pbSize: jspb.Message.getFieldWithDefault(msg, 13, 0),
        pbBufferSize: jspb.Message.getFieldWithDefault(msg, 14, 0),
        pbStart: jspb.Message.getFieldWithDefault(msg, 15, 0),
        imageFormat:
          (f = msg.getImageFormat()) &&
          proto.k3900.ImageFormat.toObject(includeInstance, f),
        measurementsList: jspb.Message.toObjectList(
          msg.getMeasurementsList(),
          proto.k3900.Measurement.toObject,
          includeInstance
        ),
        measPos:
          (f = msg.getMeasPos()) &&
          proto.k3900.Pixel.toObject(includeInstance, f),
        measMarkPos:
          (f = msg.getMeasMarkPos()) &&
          proto.k3900.Pixel.toObject(includeInstance, f),
        measHighlight: jspb.Message.getFieldWithDefault(msg, 20, 0),
        flow: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
        jpg: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
        pixels: msg.getPixels_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ImageChunk}
 */
proto.k3900.ImageChunk.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ImageChunk();
  return proto.k3900.ImageChunk.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ImageChunk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ImageChunk}
 */
proto.k3900.ImageChunk.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTime(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setWidth(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setHeight(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setDepth(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setXPosition(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setYPosition(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setZPosition(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setXSpacing(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setYSpacing(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setZSpacing(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setScale(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCurrentFrame(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPbSize(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPbBufferSize(value);
        break;
      case 15:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPbStart(value);
        break;
      case 16:
        var value = new proto.k3900.ImageFormat();
        reader.readMessage(
          value,
          proto.k3900.ImageFormat.deserializeBinaryFromReader
        );
        msg.setImageFormat(value);
        break;
      case 17:
        var value = new proto.k3900.Measurement();
        reader.readMessage(
          value,
          proto.k3900.Measurement.deserializeBinaryFromReader
        );
        msg.addMeasurements(value);
        break;
      case 18:
        var value = new proto.k3900.Pixel();
        reader.readMessage(
          value,
          proto.k3900.Pixel.deserializeBinaryFromReader
        );
        msg.setMeasPos(value);
        break;
      case 19:
        var value = new proto.k3900.Pixel();
        reader.readMessage(
          value,
          proto.k3900.Pixel.deserializeBinaryFromReader
        );
        msg.setMeasMarkPos(value);
        break;
      case 20:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMeasHighlight(value);
        break;
      case 22:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setFlow(value);
        break;
      case 23:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setJpg(value);
        break;
      case 21:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setPixels(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ImageChunk.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ImageChunk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ImageChunk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ImageChunk.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getXPosition();
  if (f !== 0.0) {
    writer.writeFloat(5, f);
  }
  f = message.getYPosition();
  if (f !== 0.0) {
    writer.writeFloat(6, f);
  }
  f = message.getZPosition();
  if (f !== 0.0) {
    writer.writeFloat(7, f);
  }
  f = message.getXSpacing();
  if (f !== 0.0) {
    writer.writeFloat(8, f);
  }
  f = message.getYSpacing();
  if (f !== 0.0) {
    writer.writeFloat(9, f);
  }
  f = message.getZSpacing();
  if (f !== 0.0) {
    writer.writeFloat(10, f);
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeFloat(11, f);
  }
  f = message.getCurrentFrame();
  if (f !== 0) {
    writer.writeUint32(12, f);
  }
  f = message.getPbSize();
  if (f !== 0) {
    writer.writeUint32(13, f);
  }
  f = message.getPbBufferSize();
  if (f !== 0) {
    writer.writeUint32(14, f);
  }
  f = message.getPbStart();
  if (f !== 0) {
    writer.writeUint32(15, f);
  }
  f = message.getImageFormat();
  if (f != null) {
    writer.writeMessage(16, f, proto.k3900.ImageFormat.serializeBinaryToWriter);
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.k3900.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getMeasPos();
  if (f != null) {
    writer.writeMessage(18, f, proto.k3900.Pixel.serializeBinaryToWriter);
  }
  f = message.getMeasMarkPos();
  if (f != null) {
    writer.writeMessage(19, f, proto.k3900.Pixel.serializeBinaryToWriter);
  }
  f = message.getMeasHighlight();
  if (f !== 0) {
    writer.writeInt32(20, f);
  }
  f = message.getFlow();
  if (f) {
    writer.writeBool(22, f);
  }
  f = message.getJpg();
  if (f) {
    writer.writeBool(23, f);
  }
  f = message.getPixels_asU8();
  if (f.length > 0) {
    writer.writeBytes(21, f);
  }
};

/**
 * optional uint64 time = 1;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setTime = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint32 width = 2;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getWidth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setWidth = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 height = 3;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getHeight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setHeight = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 depth = 4;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getDepth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setDepth = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional float x_position = 5;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getXPosition = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setXPosition = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

/**
 * optional float y_position = 6;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getYPosition = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setYPosition = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional float z_position = 7;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getZPosition = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setZPosition = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional float x_spacing = 8;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getXSpacing = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setXSpacing = function (value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional float y_spacing = 9;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getYSpacing = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setYSpacing = function (value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional float z_spacing = 10;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getZSpacing = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setZSpacing = function (value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional float scale = 11;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getScale = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setScale = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional uint32 current_frame = 12;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getCurrentFrame = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setCurrentFrame = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};

/**
 * optional uint32 pb_size = 13;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getPbSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setPbSize = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional uint32 pb_buffer_size = 14;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getPbBufferSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setPbBufferSize = function (value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * optional uint32 pb_start = 15;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getPbStart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setPbStart = function (value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};

/**
 * optional ImageFormat image_format = 16;
 * @return {?proto.k3900.ImageFormat}
 */
proto.k3900.ImageChunk.prototype.getImageFormat = function () {
  return /** @type{?proto.k3900.ImageFormat} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ImageFormat, 16)
  );
};

/**
 * @param {?proto.k3900.ImageFormat|undefined} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setImageFormat = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.clearImageFormat = function () {
  return this.setImageFormat(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ImageChunk.prototype.hasImageFormat = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * repeated Measurement measurements = 17;
 * @return {!Array<!proto.k3900.Measurement>}
 */
proto.k3900.ImageChunk.prototype.getMeasurementsList = function () {
  return /** @type{!Array<!proto.k3900.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.Measurement, 17)
  );
};

/**
 * @param {!Array<!proto.k3900.Measurement>} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setMeasurementsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};

/**
 * @param {!proto.k3900.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.Measurement}
 */
proto.k3900.ImageChunk.prototype.addMeasurements = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    17,
    opt_value,
    proto.k3900.Measurement,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.clearMeasurementsList = function () {
  return this.setMeasurementsList([]);
};

/**
 * optional Pixel meas_pos = 18;
 * @return {?proto.k3900.Pixel}
 */
proto.k3900.ImageChunk.prototype.getMeasPos = function () {
  return /** @type{?proto.k3900.Pixel} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Pixel, 18)
  );
};

/**
 * @param {?proto.k3900.Pixel|undefined} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setMeasPos = function (value) {
  return jspb.Message.setWrapperField(this, 18, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.clearMeasPos = function () {
  return this.setMeasPos(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ImageChunk.prototype.hasMeasPos = function () {
  return jspb.Message.getField(this, 18) != null;
};

/**
 * optional Pixel meas_mark_pos = 19;
 * @return {?proto.k3900.Pixel}
 */
proto.k3900.ImageChunk.prototype.getMeasMarkPos = function () {
  return /** @type{?proto.k3900.Pixel} */ (
    jspb.Message.getWrapperField(this, proto.k3900.Pixel, 19)
  );
};

/**
 * @param {?proto.k3900.Pixel|undefined} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setMeasMarkPos = function (value) {
  return jspb.Message.setWrapperField(this, 19, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.clearMeasMarkPos = function () {
  return this.setMeasMarkPos(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.ImageChunk.prototype.hasMeasMarkPos = function () {
  return jspb.Message.getField(this, 19) != null;
};

/**
 * optional int32 meas_highlight = 20;
 * @return {number}
 */
proto.k3900.ImageChunk.prototype.getMeasHighlight = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setMeasHighlight = function (value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};

/**
 * optional bool flow = 22;
 * @return {boolean}
 */
proto.k3900.ImageChunk.prototype.getFlow = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 22, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setFlow = function (value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};

/**
 * optional bool jpg = 23;
 * @return {boolean}
 */
proto.k3900.ImageChunk.prototype.getJpg = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 23, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setJpg = function (value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};

/**
 * optional bytes pixels = 21;
 * @return {string}
 */
proto.k3900.ImageChunk.prototype.getPixels = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};

/**
 * optional bytes pixels = 21;
 * This is a type-conversion wrapper around `getPixels()`
 * @return {string}
 */
proto.k3900.ImageChunk.prototype.getPixels_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPixels()));
};

/**
 * optional bytes pixels = 21;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPixels()`
 * @return {!Uint8Array}
 */
proto.k3900.ImageChunk.prototype.getPixels_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getPixels()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.k3900.ImageChunk} returns this
 */
proto.k3900.ImageChunk.prototype.setPixels = function (value) {
  return jspb.Message.setProto3BytesField(this, 21, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.SystemState.repeatedFields_ = [12, 30, 36];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.SystemState.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.SystemState.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.SystemState} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.SystemState.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        image:
          (f = msg.getImage()) &&
          proto.k3900.ImageChunk.toObject(includeInstance, f),
        contrast: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        brightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        gamma: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        acousticPower: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          5,
          0.0
        ),
        txFreq: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        masterGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        probeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
        sos: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
        edgeFilter: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
        gaussianFilter: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          11,
          0.0
        ),
        tgcList:
          (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null
            ? undefined
            : f,
        run: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
        playback: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
        focus: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
        txApts: jspb.Message.getFieldWithDefault(msg, 16, 0),
        rxApts: jspb.Message.getFieldWithDefault(msg, 17, 0),
        smoothing: jspb.Message.getFieldWithDefault(msg, 18, 0),
        systemError: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
        patientId: jspb.Message.getFieldWithDefault(msg, 20, ""),
        examName: jspb.Message.getFieldWithDefault(msg, 21, ""),
        agcOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
        framePersistence: jspb.Message.getFieldWithDefault(msg, 23, ""),
        pingMode: jspb.Message.getFieldWithDefault(msg, 24, 0),
        fps: jspb.Message.getFieldWithDefault(msg, 25, 0),
        pps: jspb.Message.getFieldWithDefault(msg, 26, 0),
        zoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
        media:
          (f = msg.getMedia()) &&
          proto.k3900.MediaRequest.toObject(includeInstance, f),
        filterSelect: jspb.Message.getFieldWithDefault(msg, 29, 0),
        deviceStatusList: jspb.Message.toObjectList(
          msg.getDeviceStatusList(),
          google_protobuf_any_pb.Any.toObject,
          includeInstance
        ),
        autoContrast: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
        transmitSize: jspb.Message.getFieldWithDefault(msg, 32, 0),
        vgaGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
        lnaGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
        dr: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
        peakDb: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
        ti: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
        mi: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
        errorReportsList: jspb.Message.toObjectList(
          msg.getErrorReportsList(),
          proto.k3900.ErrorReport.toObject,
          includeInstance
        ),
        tec:
          (f = msg.getTec()) && proto.k3900.TECMsg.toObject(includeInstance, f),
        probe:
          (f = msg.getProbe()) &&
          proto.k3900.ProbeFile.toObject(includeInstance, f),
        graymap: jspb.Message.getFieldWithDefault(msg, 42, ""),
        gainLevel: jspb.Message.getFieldWithDefault(msg, 43, 0),
        dlcStartLevel: jspb.Message.getFieldWithDefault(msg, 44, 0),
        afe0Hv:
          (f = msg.getAfe0Hv()) &&
          proto.k3900.HighVoltageReadings.toObject(includeInstance, f),
        afe1Hv:
          (f = msg.getAfe1Hv()) &&
          proto.k3900.HighVoltageReadings.toObject(includeInstance, f),
        afe2Hv:
          (f = msg.getAfe2Hv()) &&
          proto.k3900.HighVoltageReadings.toObject(includeInstance, f),
        speckleFilter: jspb.Message.getFieldWithDefault(msg, 48, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.SystemState}
 */
proto.k3900.SystemState.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.SystemState();
  return proto.k3900.SystemState.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.SystemState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.SystemState}
 */
proto.k3900.SystemState.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.ImageChunk();
        reader.readMessage(
          value,
          proto.k3900.ImageChunk.deserializeBinaryFromReader
        );
        msg.setImage(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setContrast(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBrightness(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGamma(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setAcousticPower(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTxFreq(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setMasterGain(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setProbeName(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setSos(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setEdgeFilter(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGaussianFilter(value);
        break;
      case 12:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addTgc(values[i]);
        }
        break;
      case 13:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setRun(value);
        break;
      case 14:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPlayback(value);
        break;
      case 15:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setFocus(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTxApts(value);
        break;
      case 17:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setRxApts(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSmoothing(value);
        break;
      case 19:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSystemError(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setPatientId(value);
        break;
      case 21:
        var value = /** @type {string} */ (reader.readString());
        msg.setExamName(value);
        break;
      case 22:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setAgcOffset(value);
        break;
      case 23:
        var value = /** @type {string} */ (reader.readString());
        msg.setFramePersistence(value);
        break;
      case 24:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPingMode(value);
        break;
      case 25:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFps(value);
        break;
      case 26:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPps(value);
        break;
      case 27:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setZoom(value);
        break;
      case 28:
        var value = new proto.k3900.MediaRequest();
        reader.readMessage(
          value,
          proto.k3900.MediaRequest.deserializeBinaryFromReader
        );
        msg.setMedia(value);
        break;
      case 29:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFilterSelect(value);
        break;
      case 30:
        var value = new google_protobuf_any_pb.Any();
        reader.readMessage(
          value,
          google_protobuf_any_pb.Any.deserializeBinaryFromReader
        );
        msg.addDeviceStatus(value);
        break;
      case 31:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutoContrast(value);
        break;
      case 32:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTransmitSize(value);
        break;
      case 33:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setVgaGain(value);
        break;
      case 40:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setLnaGain(value);
        break;
      case 34:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setDr(value);
        break;
      case 35:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setPeakDb(value);
        break;
      case 37:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTi(value);
        break;
      case 38:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setMi(value);
        break;
      case 36:
        var value = new proto.k3900.ErrorReport();
        reader.readMessage(
          value,
          proto.k3900.ErrorReport.deserializeBinaryFromReader
        );
        msg.addErrorReports(value);
        break;
      case 39:
        var value = new proto.k3900.TECMsg();
        reader.readMessage(
          value,
          proto.k3900.TECMsg.deserializeBinaryFromReader
        );
        msg.setTec(value);
        break;
      case 41:
        var value = new proto.k3900.ProbeFile();
        reader.readMessage(
          value,
          proto.k3900.ProbeFile.deserializeBinaryFromReader
        );
        msg.setProbe(value);
        break;
      case 42:
        var value = /** @type {string} */ (reader.readString());
        msg.setGraymap(value);
        break;
      case 43:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setGainLevel(value);
        break;
      case 44:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setDlcStartLevel(value);
        break;
      case 45:
        var value = new proto.k3900.HighVoltageReadings();
        reader.readMessage(
          value,
          proto.k3900.HighVoltageReadings.deserializeBinaryFromReader
        );
        msg.setAfe0Hv(value);
        break;
      case 46:
        var value = new proto.k3900.HighVoltageReadings();
        reader.readMessage(
          value,
          proto.k3900.HighVoltageReadings.deserializeBinaryFromReader
        );
        msg.setAfe1Hv(value);
        break;
      case 47:
        var value = new proto.k3900.HighVoltageReadings();
        reader.readMessage(
          value,
          proto.k3900.HighVoltageReadings.deserializeBinaryFromReader
        );
        msg.setAfe2Hv(value);
        break;
      case 48:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSpeckleFilter(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.SystemState.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.SystemState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.SystemState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.SystemState.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(1, f, proto.k3900.ImageChunk.serializeBinaryToWriter);
  }
  f = message.getContrast();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getBrightness();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getGamma();
  if (f !== 0.0) {
    writer.writeFloat(4, f);
  }
  f = message.getAcousticPower();
  if (f !== 0.0) {
    writer.writeFloat(5, f);
  }
  f = message.getTxFreq();
  if (f !== 0.0) {
    writer.writeFloat(6, f);
  }
  f = message.getMasterGain();
  if (f !== 0.0) {
    writer.writeFloat(7, f);
  }
  f = message.getProbeName();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getSos();
  if (f !== 0.0) {
    writer.writeFloat(9, f);
  }
  f = message.getEdgeFilter();
  if (f !== 0.0) {
    writer.writeFloat(10, f);
  }
  f = message.getGaussianFilter();
  if (f !== 0.0) {
    writer.writeFloat(11, f);
  }
  f = message.getTgcList();
  if (f.length > 0) {
    writer.writePackedFloat(12, f);
  }
  f = message.getRun();
  if (f) {
    writer.writeBool(13, f);
  }
  f = message.getPlayback();
  if (f) {
    writer.writeBool(14, f);
  }
  f = message.getFocus();
  if (f !== 0.0) {
    writer.writeFloat(15, f);
  }
  f = message.getTxApts();
  if (f !== 0) {
    writer.writeInt32(16, f);
  }
  f = message.getRxApts();
  if (f !== 0) {
    writer.writeInt32(17, f);
  }
  f = message.getSmoothing();
  if (f !== 0) {
    writer.writeInt32(18, f);
  }
  f = message.getSystemError();
  if (f) {
    writer.writeBool(19, f);
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(20, f);
  }
  f = message.getExamName();
  if (f.length > 0) {
    writer.writeString(21, f);
  }
  f = message.getAgcOffset();
  if (f !== 0.0) {
    writer.writeFloat(22, f);
  }
  f = message.getFramePersistence();
  if (f.length > 0) {
    writer.writeString(23, f);
  }
  f = message.getPingMode();
  if (f !== 0) {
    writer.writeInt32(24, f);
  }
  f = message.getFps();
  if (f !== 0) {
    writer.writeInt32(25, f);
  }
  f = message.getPps();
  if (f !== 0) {
    writer.writeInt32(26, f);
  }
  f = message.getZoom();
  if (f !== 0.0) {
    writer.writeFloat(27, f);
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.k3900.MediaRequest.serializeBinaryToWriter
    );
  }
  f = message.getFilterSelect();
  if (f !== 0) {
    writer.writeInt32(29, f);
  }
  f = message.getDeviceStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getAutoContrast();
  if (f) {
    writer.writeBool(31, f);
  }
  f = message.getTransmitSize();
  if (f !== 0) {
    writer.writeInt32(32, f);
  }
  f = message.getVgaGain();
  if (f !== 0.0) {
    writer.writeFloat(33, f);
  }
  f = message.getLnaGain();
  if (f !== 0.0) {
    writer.writeFloat(40, f);
  }
  f = message.getDr();
  if (f !== 0.0) {
    writer.writeFloat(34, f);
  }
  f = message.getPeakDb();
  if (f !== 0.0) {
    writer.writeFloat(35, f);
  }
  f = message.getTi();
  if (f !== 0.0) {
    writer.writeFloat(37, f);
  }
  f = message.getMi();
  if (f !== 0.0) {
    writer.writeFloat(38, f);
  }
  f = message.getErrorReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.k3900.ErrorReport.serializeBinaryToWriter
    );
  }
  f = message.getTec();
  if (f != null) {
    writer.writeMessage(39, f, proto.k3900.TECMsg.serializeBinaryToWriter);
  }
  f = message.getProbe();
  if (f != null) {
    writer.writeMessage(41, f, proto.k3900.ProbeFile.serializeBinaryToWriter);
  }
  f = message.getGraymap();
  if (f.length > 0) {
    writer.writeString(42, f);
  }
  f = message.getGainLevel();
  if (f !== 0) {
    writer.writeInt32(43, f);
  }
  f = message.getDlcStartLevel();
  if (f !== 0) {
    writer.writeInt32(44, f);
  }
  f = message.getAfe0Hv();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.k3900.HighVoltageReadings.serializeBinaryToWriter
    );
  }
  f = message.getAfe1Hv();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.k3900.HighVoltageReadings.serializeBinaryToWriter
    );
  }
  f = message.getAfe2Hv();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.k3900.HighVoltageReadings.serializeBinaryToWriter
    );
  }
  f = message.getSpeckleFilter();
  if (f !== 0) {
    writer.writeInt32(48, f);
  }
};

/**
 * optional ImageChunk image = 1;
 * @return {?proto.k3900.ImageChunk}
 */
proto.k3900.SystemState.prototype.getImage = function () {
  return /** @type{?proto.k3900.ImageChunk} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ImageChunk, 1)
  );
};

/**
 * @param {?proto.k3900.ImageChunk|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setImage = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearImage = function () {
  return this.setImage(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasImage = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional float contrast = 2;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getContrast = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setContrast = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional float brightness = 3;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getBrightness = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setBrightness = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float gamma = 4;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getGamma = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setGamma = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional float acoustic_power = 5;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getAcousticPower = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAcousticPower = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

/**
 * optional float tx_freq = 6;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getTxFreq = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTxFreq = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional float master_gain = 7;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getMasterGain = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setMasterGain = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional string probe_name = 8;
 * @return {string}
 */
proto.k3900.SystemState.prototype.getProbeName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setProbeName = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional float sos = 9;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getSos = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setSos = function (value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional float edge_filter = 10;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getEdgeFilter = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setEdgeFilter = function (value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional float gaussian_filter = 11;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getGaussianFilter = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setGaussianFilter = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * repeated float tgc = 12;
 * @return {!Array<number>}
 */
proto.k3900.SystemState.prototype.getTgcList = function () {
  return /** @type {!Array<number>} */ (
    jspb.Message.getRepeatedFloatingPointField(this, 12)
  );
};

/**
 * @param {!Array<number>} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTgcList = function (value) {
  return jspb.Message.setField(this, 12, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.addTgc = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearTgcList = function () {
  return this.setTgcList([]);
};

/**
 * optional bool run = 13;
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.getRun = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 13, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setRun = function (value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};

/**
 * optional bool playback = 14;
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.getPlayback = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 14, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setPlayback = function (value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};

/**
 * optional float focus = 15;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getFocus = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setFocus = function (value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};

/**
 * optional int32 tx_apts = 16;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getTxApts = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTxApts = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};

/**
 * optional int32 rx_apts = 17;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getRxApts = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setRxApts = function (value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};

/**
 * optional int32 smoothing = 18;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getSmoothing = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setSmoothing = function (value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};

/**
 * optional bool system_error = 19;
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.getSystemError = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 19, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setSystemError = function (value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};

/**
 * optional string patient_id = 20;
 * @return {string}
 */
proto.k3900.SystemState.prototype.getPatientId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setPatientId = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};

/**
 * optional string exam_name = 21;
 * @return {string}
 */
proto.k3900.SystemState.prototype.getExamName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setExamName = function (value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};

/**
 * optional float agc_offset = 22;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getAgcOffset = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAgcOffset = function (value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};

/**
 * optional string frame_persistence = 23;
 * @return {string}
 */
proto.k3900.SystemState.prototype.getFramePersistence = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setFramePersistence = function (value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};

/**
 * optional int32 ping_mode = 24;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getPingMode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setPingMode = function (value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};

/**
 * optional int32 fps = 25;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getFps = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setFps = function (value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};

/**
 * optional int32 pps = 26;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getPps = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setPps = function (value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};

/**
 * optional float zoom = 27;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getZoom = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setZoom = function (value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};

/**
 * optional MediaRequest media = 28;
 * @return {?proto.k3900.MediaRequest}
 */
proto.k3900.SystemState.prototype.getMedia = function () {
  return /** @type{?proto.k3900.MediaRequest} */ (
    jspb.Message.getWrapperField(this, proto.k3900.MediaRequest, 28)
  );
};

/**
 * @param {?proto.k3900.MediaRequest|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setMedia = function (value) {
  return jspb.Message.setWrapperField(this, 28, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearMedia = function () {
  return this.setMedia(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasMedia = function () {
  return jspb.Message.getField(this, 28) != null;
};

/**
 * optional int32 filter_select = 29;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getFilterSelect = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setFilterSelect = function (value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};

/**
 * repeated google.protobuf.Any device_status = 30;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.k3900.SystemState.prototype.getDeviceStatusList = function () {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 30)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setDeviceStatusList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};

/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.k3900.SystemState.prototype.addDeviceStatus = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    30,
    opt_value,
    proto.google.protobuf.Any,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearDeviceStatusList = function () {
  return this.setDeviceStatusList([]);
};

/**
 * optional bool auto_contrast = 31;
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.getAutoContrast = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 31, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAutoContrast = function (value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};

/**
 * optional int32 transmit_size = 32;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getTransmitSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTransmitSize = function (value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};

/**
 * optional float vga_gain = 33;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getVgaGain = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setVgaGain = function (value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};

/**
 * optional float lna_gain = 40;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getLnaGain = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setLnaGain = function (value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};

/**
 * optional float dr = 34;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getDr = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setDr = function (value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};

/**
 * optional float peak_db = 35;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getPeakDb = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setPeakDb = function (value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};

/**
 * optional float ti = 37;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getTi = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTi = function (value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};

/**
 * optional float mi = 38;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getMi = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setMi = function (value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};

/**
 * repeated ErrorReport error_reports = 36;
 * @return {!Array<!proto.k3900.ErrorReport>}
 */
proto.k3900.SystemState.prototype.getErrorReportsList = function () {
  return /** @type{!Array<!proto.k3900.ErrorReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.k3900.ErrorReport, 36)
  );
};

/**
 * @param {!Array<!proto.k3900.ErrorReport>} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setErrorReportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};

/**
 * @param {!proto.k3900.ErrorReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.ErrorReport}
 */
proto.k3900.SystemState.prototype.addErrorReports = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    36,
    opt_value,
    proto.k3900.ErrorReport,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearErrorReportsList = function () {
  return this.setErrorReportsList([]);
};

/**
 * optional TECMsg tec = 39;
 * @return {?proto.k3900.TECMsg}
 */
proto.k3900.SystemState.prototype.getTec = function () {
  return /** @type{?proto.k3900.TECMsg} */ (
    jspb.Message.getWrapperField(this, proto.k3900.TECMsg, 39)
  );
};

/**
 * @param {?proto.k3900.TECMsg|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setTec = function (value) {
  return jspb.Message.setWrapperField(this, 39, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearTec = function () {
  return this.setTec(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasTec = function () {
  return jspb.Message.getField(this, 39) != null;
};

/**
 * optional ProbeFile probe = 41;
 * @return {?proto.k3900.ProbeFile}
 */
proto.k3900.SystemState.prototype.getProbe = function () {
  return /** @type{?proto.k3900.ProbeFile} */ (
    jspb.Message.getWrapperField(this, proto.k3900.ProbeFile, 41)
  );
};

/**
 * @param {?proto.k3900.ProbeFile|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setProbe = function (value) {
  return jspb.Message.setWrapperField(this, 41, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearProbe = function () {
  return this.setProbe(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasProbe = function () {
  return jspb.Message.getField(this, 41) != null;
};

/**
 * optional string graymap = 42;
 * @return {string}
 */
proto.k3900.SystemState.prototype.getGraymap = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setGraymap = function (value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};

/**
 * optional int32 gain_level = 43;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getGainLevel = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setGainLevel = function (value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};

/**
 * optional int32 dlc_start_level = 44;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getDlcStartLevel = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setDlcStartLevel = function (value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};

/**
 * optional HighVoltageReadings afe0_hv = 45;
 * @return {?proto.k3900.HighVoltageReadings}
 */
proto.k3900.SystemState.prototype.getAfe0Hv = function () {
  return /** @type{?proto.k3900.HighVoltageReadings} */ (
    jspb.Message.getWrapperField(this, proto.k3900.HighVoltageReadings, 45)
  );
};

/**
 * @param {?proto.k3900.HighVoltageReadings|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAfe0Hv = function (value) {
  return jspb.Message.setWrapperField(this, 45, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearAfe0Hv = function () {
  return this.setAfe0Hv(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasAfe0Hv = function () {
  return jspb.Message.getField(this, 45) != null;
};

/**
 * optional HighVoltageReadings afe1_hv = 46;
 * @return {?proto.k3900.HighVoltageReadings}
 */
proto.k3900.SystemState.prototype.getAfe1Hv = function () {
  return /** @type{?proto.k3900.HighVoltageReadings} */ (
    jspb.Message.getWrapperField(this, proto.k3900.HighVoltageReadings, 46)
  );
};

/**
 * @param {?proto.k3900.HighVoltageReadings|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAfe1Hv = function (value) {
  return jspb.Message.setWrapperField(this, 46, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearAfe1Hv = function () {
  return this.setAfe1Hv(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasAfe1Hv = function () {
  return jspb.Message.getField(this, 46) != null;
};

/**
 * optional HighVoltageReadings afe2_hv = 47;
 * @return {?proto.k3900.HighVoltageReadings}
 */
proto.k3900.SystemState.prototype.getAfe2Hv = function () {
  return /** @type{?proto.k3900.HighVoltageReadings} */ (
    jspb.Message.getWrapperField(this, proto.k3900.HighVoltageReadings, 47)
  );
};

/**
 * @param {?proto.k3900.HighVoltageReadings|undefined} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setAfe2Hv = function (value) {
  return jspb.Message.setWrapperField(this, 47, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.clearAfe2Hv = function () {
  return this.setAfe2Hv(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.SystemState.prototype.hasAfe2Hv = function () {
  return jspb.Message.getField(this, 47) != null;
};

/**
 * optional int32 speckle_filter = 48;
 * @return {number}
 */
proto.k3900.SystemState.prototype.getSpeckleFilter = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.SystemState} returns this
 */
proto.k3900.SystemState.prototype.setSpeckleFilter = function (value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BeamformerParameter.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BeamformerParameter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BeamformerParameter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BeamformerParameter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ""),
        value:
          (f = msg.getValue()) &&
          google_protobuf_any_pb.Any.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BeamformerParameter}
 */
proto.k3900.BeamformerParameter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BeamformerParameter();
  return proto.k3900.BeamformerParameter.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BeamformerParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BeamformerParameter}
 */
proto.k3900.BeamformerParameter.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new google_protobuf_any_pb.Any();
        reader.readMessage(
          value,
          google_protobuf_any_pb.Any.deserializeBinaryFromReader
        );
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BeamformerParameter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.BeamformerParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BeamformerParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BeamformerParameter.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.k3900.BeamformerParameter.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BeamformerParameter} returns this
 */
proto.k3900.BeamformerParameter.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Any value = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.k3900.BeamformerParameter.prototype.getValue = function () {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2)
  );
};

/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.k3900.BeamformerParameter} returns this
 */
proto.k3900.BeamformerParameter.prototype.setValue = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.k3900.BeamformerParameter} returns this
 */
proto.k3900.BeamformerParameter.prototype.clearValue = function () {
  return this.setValue(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.k3900.BeamformerParameter.prototype.hasValue = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.BeamformerParametersRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BeamformerParametersRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BeamformerParametersRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BeamformerParametersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BeamformerParametersRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        parametersList: jspb.Message.toObjectList(
          msg.getParametersList(),
          proto.k3900.BeamformerParameter.toObject,
          includeInstance
        ),
        memid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BeamformerParametersRequest}
 */
proto.k3900.BeamformerParametersRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BeamformerParametersRequest();
  return proto.k3900.BeamformerParametersRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BeamformerParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BeamformerParametersRequest}
 */
proto.k3900.BeamformerParametersRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.k3900.BeamformerParameter();
        reader.readMessage(
          value,
          proto.k3900.BeamformerParameter.deserializeBinaryFromReader
        );
        msg.addParameters(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMemid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BeamformerParametersRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.k3900.BeamformerParametersRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BeamformerParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BeamformerParametersRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.k3900.BeamformerParameter.serializeBinaryToWriter
    );
  }
  f = message.getMemid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * repeated BeamformerParameter parameters = 1;
 * @return {!Array<!proto.k3900.BeamformerParameter>}
 */
proto.k3900.BeamformerParametersRequest.prototype.getParametersList =
  function () {
    return /** @type{!Array<!proto.k3900.BeamformerParameter>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.k3900.BeamformerParameter,
        1
      )
    );
  };

/**
 * @param {!Array<!proto.k3900.BeamformerParameter>} value
 * @return {!proto.k3900.BeamformerParametersRequest} returns this
 */
proto.k3900.BeamformerParametersRequest.prototype.setParametersList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.k3900.BeamformerParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.k3900.BeamformerParameter}
 */
proto.k3900.BeamformerParametersRequest.prototype.addParameters = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.k3900.BeamformerParameter,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformerParametersRequest} returns this
 */
proto.k3900.BeamformerParametersRequest.prototype.clearParametersList =
  function () {
    return this.setParametersList([]);
  };

/**
 * optional string memid = 2;
 * @return {string}
 */
proto.k3900.BeamformerParametersRequest.prototype.getMemid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BeamformerParametersRequest} returns this
 */
proto.k3900.BeamformerParametersRequest.prototype.setMemid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.k3900.BeamformerParametersResponse.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.BeamformerParametersResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.BeamformerParametersResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.BeamformerParametersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.BeamformerParametersResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, 0),
        message: jspb.Message.getFieldWithDefault(msg, 2, ""),
        resultList: jspb.Message.toObjectList(
          msg.getResultList(),
          google_protobuf_any_pb.Any.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.BeamformerParametersResponse}
 */
proto.k3900.BeamformerParametersResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.BeamformerParametersResponse();
  return proto.k3900.BeamformerParametersResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.BeamformerParametersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.BeamformerParametersResponse}
 */
proto.k3900.BeamformerParametersResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readInt32());
          msg.setCode(value);
          break;
        case 2:
          var value = /** @type {string} */ (reader.readString());
          msg.setMessage(value);
          break;
        case 3:
          var value = new google_protobuf_any_pb.Any();
          reader.readMessage(
            value,
            google_protobuf_any_pb.Any.deserializeBinaryFromReader
          );
          msg.addResult(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.BeamformerParametersResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.k3900.BeamformerParametersResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.BeamformerParametersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.BeamformerParametersResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};

/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.k3900.BeamformerParametersResponse.prototype.getCode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.BeamformerParametersResponse} returns this
 */
proto.k3900.BeamformerParametersResponse.prototype.setCode = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string message = 2;
 * @return {string}
 */
proto.k3900.BeamformerParametersResponse.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.k3900.BeamformerParametersResponse} returns this
 */
proto.k3900.BeamformerParametersResponse.prototype.setMessage = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * repeated google.protobuf.Any result = 3;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.k3900.BeamformerParametersResponse.prototype.getResultList = function () {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 3)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.k3900.BeamformerParametersResponse} returns this
 */
proto.k3900.BeamformerParametersResponse.prototype.setResultList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.k3900.BeamformerParametersResponse.prototype.addResult = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.google.protobuf.Any,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.k3900.BeamformerParametersResponse} returns this
 */
proto.k3900.BeamformerParametersResponse.prototype.clearResultList =
  function () {
    return this.setResultList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FloatParam.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.FloatParam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FloatParam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FloatParam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        absolute: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FloatParam}
 */
proto.k3900.FloatParam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FloatParam();
  return proto.k3900.FloatParam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FloatParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FloatParam}
 */
proto.k3900.FloatParam.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAbsolute(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FloatParam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FloatParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FloatParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FloatParam.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
  f = message.getAbsolute();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional float value = 1;
 * @return {number}
 */
proto.k3900.FloatParam.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FloatParam} returns this
 */
proto.k3900.FloatParam.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional bool absolute = 2;
 * @return {boolean}
 */
proto.k3900.FloatParam.prototype.getAbsolute = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.FloatParam} returns this
 */
proto.k3900.FloatParam.prototype.setAbsolute = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.IntParam.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.IntParam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.IntParam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.IntParam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        value: jspb.Message.getFieldWithDefault(msg, 1, 0),
        absolute: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.IntParam}
 */
proto.k3900.IntParam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.IntParam();
  return proto.k3900.IntParam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.IntParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.IntParam}
 */
proto.k3900.IntParam.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32());
        msg.setValue(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAbsolute(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.IntParam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.IntParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.IntParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.IntParam.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeSint32(1, f);
  }
  f = message.getAbsolute();
  if (f) {
    writer.writeBool(2, f);
  }
};

/**
 * optional sint32 value = 1;
 * @return {number}
 */
proto.k3900.IntParam.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.IntParam} returns this
 */
proto.k3900.IntParam.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional bool absolute = 2;
 * @return {boolean}
 */
proto.k3900.IntParam.prototype.getAbsolute = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.IntParam} returns this
 */
proto.k3900.IntParam.prototype.setAbsolute = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.ToggleParam.prototype.toObject = function (opt_includeInstance) {
    return proto.k3900.ToggleParam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.ToggleParam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.ToggleParam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        value: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.ToggleParam}
 */
proto.k3900.ToggleParam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.ToggleParam();
  return proto.k3900.ToggleParam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.ToggleParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.ToggleParam}
 */
proto.k3900.ToggleParam.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.ToggleParam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.ToggleParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.ToggleParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.ToggleParam.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(1, f);
  }
};

/**
 * optional bool value = 1;
 * @return {boolean}
 */
proto.k3900.ToggleParam.prototype.getValue = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.ToggleParam} returns this
 */
proto.k3900.ToggleParam.prototype.setValue = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.k3900.FloatArrayParam.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.k3900.FloatArrayParam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.k3900.FloatArrayParam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.k3900.FloatArrayParam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        index: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        absolute: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.k3900.FloatArrayParam}
 */
proto.k3900.FloatArrayParam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.k3900.FloatArrayParam();
  return proto.k3900.FloatArrayParam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.k3900.FloatArrayParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.k3900.FloatArrayParam}
 */
proto.k3900.FloatArrayParam.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAbsolute(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.k3900.FloatArrayParam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.k3900.FloatArrayParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.k3900.FloatArrayParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.k3900.FloatArrayParam.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getAbsolute();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.k3900.FloatArrayParam.prototype.getIndex = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.k3900.FloatArrayParam} returns this
 */
proto.k3900.FloatArrayParam.prototype.setIndex = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional float value = 2;
 * @return {number}
 */
proto.k3900.FloatArrayParam.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.k3900.FloatArrayParam} returns this
 */
proto.k3900.FloatArrayParam.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional bool absolute = 3;
 * @return {boolean}
 */
proto.k3900.FloatArrayParam.prototype.getAbsolute = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.k3900.FloatArrayParam} returns this
 */
proto.k3900.FloatArrayParam.prototype.setAbsolute = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * @enum {number}
 */
proto.k3900.FilterOrder = {
  SECOND: 0,
  FOURTH: 1,
  SIXTH: 2,
  EIGHT: 3,
};

/**
 * @enum {number}
 */
proto.k3900.BFInterp = {
  TRUNCATED: 0,
  NEAREST: 1,
  LINEAR: 2,
  CUBIC: 3,
};

/**
 * @enum {number}
 */
proto.k3900.CalAction = {
  UPDATE: 0,
  CLEAR: 1,
  RESET_FACTORY: 2,
  WRITE: 3,
  NONE: 4,
};

/**
 * @enum {number}
 */
proto.k3900.State = {
  NA: 0,
  YES: 1,
  NO: 2,
};

/**
 * @enum {number}
 */
proto.k3900.MediaControlType = {
  PLAY: 0,
  PAUSE: 1,
};

/**
 * @enum {number}
 */
proto.k3900.ProgressType = {
  MEDIA: 0,
  DIAGNOSTICS: 1,
};

/**
 * @enum {number}
 */
proto.k3900.FileType = {
  STILL: 0,
  VIDEO: 1,
  DATASET: 2,
};

goog.object.extend(exports, proto.k3900);
