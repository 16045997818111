import { Any } from "google-protobuf/google/protobuf/any_pb";
import { RpcError, Status } from "grpc-web";
import { isEmpty } from "lodash";
import { OptionType, SliderControlConfig } from "main";
import { makeAutoObservable, reaction } from "mobx";
import { BeamformerClient } from "proto";
import {
  BeamformerParameter,
  BeamformerParametersRequest,
  BlankRequest,
  Credentials,
  FloatArrayParam,
  FloatParam,
  ImageChunk,
  ImageRequest,
  IntParam,
  LoginRequest,
  LogoutRequest,
  NameRequest,
  ResponseMsg,
} from "proto/k3900_pb";
import { appStore } from "service";
import { isValidUUID } from "utils";

type ExamStreamType = "play" | "pause";

interface ExamTypeListOption {
  equalization: OptionType[];
  graymap: OptionType[];
}

export interface ExamEntity {
  brightness: number;
  graymap: string;
  gamma: number;
  focus: number;
  zoom: number;
  tx_apts: number;
  rx_apts: number;
  dynamic_range: number;
  tgc0: number;
  tgc1: number;
  tgc2: number;
  tgc3: number;
  tgc4: number;
  tgc5: number;
  tgc6: number;
  tgc7: number;
  tgc8: number;
}

// Predefined Slider Configurations
export const sliderConfigs: Record<
  keyof Omit<ExamEntity, "graymap">,
  SliderControlConfig
> = {
  brightness: {
    min: -0.5,
    max: 0.5,
    default: 0,
    step: 0.0001,
  },
  gamma: {
    min: 0.5,
    max: 4.5,
    default: 0.5,
    step: 0.0001,
  },
  focus: {
    min: 1200,
    max: 1600,
    default: 1540,
    step: 5,
  },
  zoom: {
    min: -0.4,
    max: 1.56,
    default: 0,
    step: 0.0001,
  },
  tx_apts: {
    min: 1,
    max: 3,
    default: 1,
    step: 1,
  },
  rx_apts: {
    min: 1,
    max: 3,
    default: 1,
    step: 1,
  },
  dynamic_range: {
    min: 30,
    max: 100,
    default: 60,
    step: 0.0001,
  },
  tgc0: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc1: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc2: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc3: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc4: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc5: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc6: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc7: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
  tgc8: {
    min: -1,
    max: 1,
    default: 0,
    step: 0.0001,
  },
};

interface ExamProps {
  client: BeamformerClient;
  memId?: string;
  imageChunks: string[];
  originImageChunks: string[];
  startStream: boolean;
  streamType: ExamStreamType;
  bufferSize: number;
  isVideoConverting: boolean;
  isWaitingForVideo: boolean;
  typeListOptions: ExamTypeListOption;
  initialValues?: ExamEntity;
  isValuesLoading: boolean;
  filteredImages: string[];
  filteredVideos: string[];
  imagesToUpload: string[];
  openedModals: string[];
  downloadImages: () => void;
  isImageSelected: (img: string) => void;
  handleSavePreset: (img: string) => void;
  toggleImageSelection: (img: string) => void;
  toggleOpenedModals: (img: string) => void;
  logout: () => void;
  setBeamformerParameter: (value: number, index: number) => void;
  setBeamformerFloatParameter: (name: string, value: number) => void;
  setBeamformerIntParameter: (name: string, value: number) => void;
  toggleVideoConverting: () => void;
  setStreamType: (type: ExamStreamType) => void;
  setHistequal: (value: string) => void;
  setGraymap: (value: string) => void;
  toggleWaitingForVideo: () => void;
}

interface ProtobufMessage {
  serializeBinary(): Uint8Array;
}

export class ExamStore implements ExamProps {
  client: BeamformerClient;
  memId?: string;
  imageChunks: string[] = [];
  originImageChunks: string[] = [];
  startStream: boolean = false;
  streamType: ExamStreamType = "play";
  bufferSize: number = 0;
  isVideoConverting: boolean = false;
  isWaitingForVideo: boolean = false;
  typeListOptions: ExamTypeListOption = {
    equalization: [],
    graymap: [],
  };
  initialValues?: ExamEntity;
  isValuesLoading: boolean = true;
  imagesToUpload: string[] = [];
  filteredImages: string[] = [];
  filteredVideos: string[] = [];
  openedModals: string[] = [];
  private isParameterChanged = false;

  constructor() {
    this.client = new BeamformerClient(
      `${process.env.REACT_APP_k3900_API_URL}:${process.env.REACT_APP_k3900_API_PORT}`
    );

    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.startStream,
      (shouldStartStream) => {
        if (!this.memId && shouldStartStream) {
          this.login();
        }
      }
    );
  }

  logout() {
    if (!this.memId) {
      return;
    }
    const logoutRequest = new LogoutRequest();
    logoutRequest.setMemid(this.memId);
    this.client
      .userLogout(logoutRequest)
      .then((response) => {
        this.setMemId(undefined);
        let msg = response.getMsg();
        if (isEmpty(msg)) {
          msg = "logout";
        }
        console.log(msg);
      })
      .catch((error: RpcError) => {
        console.log(`logout: ${error.message}`);
      });
  }

  setBeamformerParameter(value: number, index: number) {
    if (!this.memId) {
      return;
    }
    const paramBuilder = new FloatArrayParam();
    paramBuilder.setIndex(index);
    paramBuilder.setValue(value);
    paramBuilder.setAbsolute(true);

    const bfparamBuilder = new BeamformerParameter();
    bfparamBuilder.setName("tgc");
    bfparamBuilder.setValue(
      this.packAny(paramBuilder, "type.googleapis.com/k3900.FloatArrayParam")
    );

    const request = new BeamformerParametersRequest();
    request.setMemid(this.memId);
    request.addParameters(bfparamBuilder, index);
    this.client.setBeamformerParameters(request).then((resp) => {
      this.toggleIsParameterChanged();
      this.clearOriginImageChunks();
      console.log(resp);
    });
  }

  setBeamformerFloatParameter(name: string, value: number) {
    if (!this.memId) {
      return;
    }
    const paramBuilder = new FloatParam();
    paramBuilder.setValue(value);
    paramBuilder.setAbsolute(true);

    const bfparamBuilder = new BeamformerParameter();
    bfparamBuilder.setName(name);
    bfparamBuilder.setValue(
      this.packAny(paramBuilder, "type.googleapis.com/k3900.FloatParam")
    );

    const request = new BeamformerParametersRequest();
    request.setMemid(this.memId);
    request.addParameters(bfparamBuilder);
    this.client.setBeamformerParameters(request).then((resp) => {
      this.toggleIsParameterChanged();
      this.clearOriginImageChunks();
      console.log(resp);
    });
  }

  setBeamformerIntParameter(name: string, value: number) {
    if (!this.memId) {
      return;
    }
    const paramBuilder = new IntParam();
    paramBuilder.setValue(value);
    paramBuilder.setAbsolute(true);

    const bfparamBuilder = new BeamformerParameter();
    bfparamBuilder.setName(name);
    bfparamBuilder.setValue(
      this.packAny(paramBuilder, "type.googleapis.com/k3900.IntParam")
    );

    const request = new BeamformerParametersRequest();
    request.setMemid(this.memId);
    request.addParameters(bfparamBuilder, 0);
    this.client.setBeamformerParameters(request).then((resp) => {
      this.toggleIsParameterChanged();
      this.clearOriginImageChunks();
      console.log(resp);
    });
  }

  setHistequal(value: string) {
    if (!this.memId) {
      return;
    }
    const request = new NameRequest();
    request.setMemid(this.memId);
    request.setName(value);
    this.client.selectHistEqualType(request).then((resp) => {
      this.toggleIsParameterChanged();
      this.clearOriginImageChunks();
      console.log(resp);
    });
  }

  setGraymap(value: string) {
    if (!this.memId) {
      return;
    }
    const request = new NameRequest();
    request.setMemid(this.memId);
    request.setName(value);
    this.client.selectGraymapType(request).then((resp) => {
      this.toggleIsParameterChanged();
      this.clearOriginImageChunks();
      console.log(resp);
    });
  }

  toggleVideoConverting() {
    this.isVideoConverting = !this.isVideoConverting;
  }

  toggleWaitingForVideo() {
    this.isWaitingForVideo = !this.isWaitingForVideo;
  }

  setStreamType(type: ExamStreamType) {
    this.streamType = type;
  }

  toggleImageSelection(img: string) {
    if (this.imagesToUpload.includes(img)) {
      this.imagesToUpload = this.imagesToUpload.filter((item) => item !== img);
    } else {
      this.imagesToUpload.push(img);
    }
  }

  isImageSelected(img: string) {
    return this.imagesToUpload?.includes(img);
  }

  handleSavePreset = (img: string) => {
    this.filteredImages.push(img);
  };

  toggleOpenedModals(img: string) {
    if (this.openedModals.includes(img)) {
      this.openedModals = this.openedModals.filter((item) => item !== img);
    } else {
      this.openedModals.push(img);
    }
  }

  downloadImages = async () => {
    for (let i = 0; i < this.imagesToUpload.length; i++) {
      const url = this.imagesToUpload[i];

      const response = await fetch(url);
      const blob = await response.blob();
      const { type } = blob;
      const fileExtension = type.split("/")[1];
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `image-${i + 1}.${fileExtension}`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    }
  };

  appendFilteredVideo(videoSrc: string) {
    this.filteredVideos.push(videoSrc);
  }

  private login(callback?: (response: ResponseMsg) => void) {
    const loginRequest = new LoginRequest();
    const credentials = new Credentials();
    credentials.setUsername(process.env.REACT_APP_k3900_USERNAME || "");
    credentials.setPassword(process.env.REACT_APP_k3900_PASSWORD || "");
    loginRequest.setCredentials(credentials);
    loginRequest.setRo(false);
    this.client
      .userLogin(loginRequest)
      .then((response: ResponseMsg) => {
        const msg = response.getMsg();
        console.log(msg);
        if (!isValidUUID(msg)) {
          this.login();
          return;
        }
        this.setMemId(msg);
        this.keepAlive();
        this.streamData();

        // Get initial setup
        this.getGraymapType();
        this.getEqualizationType();
        this.getInitialValues();
        callback?.(response);
      })
      .catch((error: RpcError) => {
        console.log(`login: ${error.message}`);
        appStore.uiStore.showError(error.message);
      });
  }

  private keepAlive() {
    const interval = setInterval(() => {
      if (!this.memId) {
        clearInterval(interval);
        return;
      }
      const blankRequest = new BlankRequest();
      blankRequest.setMemid(this.memId);
      this.client
        .keepAlive(blankRequest)
        .then((response: ResponseMsg) => {
          let msg = response.getMsg();
          if (isEmpty(msg)) {
            msg = "keep alive";
          }
          console.log(msg);
        })
        .catch((error: RpcError) => {
          console.log(`keep alive: ${error.message}`);
          clearInterval(interval);
        });
    }, 5000);
  }

  private streamData() {
    if (!this.memId) {
      return;
    }
    const imageRequest = new ImageRequest();
    imageRequest.setMemid(this.memId);
    imageRequest.setJpg(true);
    imageRequest.setTime(0);
    const stream = this.client.sendBeamformedImageStream(imageRequest);

    stream.on("error", (err: RpcError) => {
      console.log(`stream error: ${err.message}`);
    });

    stream.on("status", (status: Status) => {
      const { details } = status;
      if (details !== "Permission Denied") {
        this.streamData();
      }
      console.log(`stream status: ${status.details}`);
    });

    stream.on("data", (response: ImageChunk) => {
      const { pixels, pbBufferSize } =
        response.toObject() as ImageChunk.AsObject;
      this.bufferSize = pbBufferSize;

      const imageSrc = `data:image/jpeg;base64,${pixels}`;
      console.log(`imageChunks,${this.imageChunks.length}`);

      if (this.originImageChunks.length < pbBufferSize) {
        this.appendOriginImageChunk(imageSrc);
      }

      if (this.streamType === "play") {
        this.handlePlayStream();
      } else {
        this.handlePauseStream();
      }
    });
  }

  private handlePlayStream() {
    if (
      this.originImageChunks.length === 1 ||
      this.imageChunks.length === this.bufferSize
    ) {
      this.clearImageChunks();
    }

    this.appendImageChunk(this.originImageChunks[this.imageChunks.length]);
  }

  private handlePauseStream() {
    if (this.isParameterChanged) {
      this.toggleIsParameterChanged();

      // sometimes there is a short delay for getting applied params
      setTimeout(() => {
        if (this.streamType === "pause" && this.originImageChunks.length > 1) {
          this.clearImageChunks();
          this.appendImageChunk(
            this.originImageChunks[this.originImageChunks.length - 1]
          );
        }
      }, 600);
    }
  }

  private getGraymapType() {
    if (!this.memId) {
      return;
    }
    const request = new BlankRequest();
    request.setMemid(this.memId);
    this.client.getGraymapTypeList(request).then((resp) => {
      this.setGraymapOptions(resp.getNamesList());
    });
  }

  private getEqualizationType() {
    if (!this.memId) {
      return;
    }
    const request = new BlankRequest();
    request.setMemid(this.memId);
    this.client.getHistEqualTypeList(request).then((resp) => {
      this.setEqualizationOptions(resp.getNamesList());
    });
  }

  private getInitialValues() {
    if (!this.memId) {
      return;
    }
    const request = new BlankRequest();
    request.setMemid(this.memId);

    this.client.getSystemState(request).then((resp) => {
      const tgcList = resp.getTgcList();
      this.initialValues = {
        brightness: this.validateExamAndSetValue(
          "brightness",
          resp.getBrightness()
        ),
        graymap: resp.getGraymap(),
        gamma: this.validateExamAndSetValue("gamma", resp.getGamma()),
        focus: this.validateExamAndSetValue("focus", resp.getFocus()),
        zoom: this.validateExamAndSetValue("zoom", resp.getZoom()),
        tx_apts: this.validateExamAndSetValue("tx_apts", resp.getTxApts()),
        rx_apts: this.validateExamAndSetValue("rx_apts", resp.getRxApts()),
        dynamic_range: this.validateExamAndSetValue(
          "dynamic_range",
          resp.getDr()
        ),
        tgc0: this.validateExamAndSetValue("tgc0", tgcList[0]),
        tgc1: this.validateExamAndSetValue("tgc1", tgcList[1]),
        tgc2: this.validateExamAndSetValue("tgc2", tgcList[2]),
        tgc3: this.validateExamAndSetValue("tgc3", tgcList[3]),
        tgc4: this.validateExamAndSetValue("tgc4", tgcList[4]),
        tgc5: this.validateExamAndSetValue("tgc5", tgcList[5]),
        tgc6: this.validateExamAndSetValue("tgc6", tgcList[6]),
        tgc7: this.validateExamAndSetValue("tgc7", tgcList[7]),
        tgc8: this.validateExamAndSetValue("tgc8", tgcList[8]),
      };
      this.toggleIsValuesLoading();
    });
  }

  private setMemId(id?: string) {
    this.memId = id;
  }

  private toggleIsValuesLoading() {
    this.isValuesLoading = !this.isValuesLoading;
  }

  private appendOriginImageChunk(src: string) {
    this.originImageChunks.push(src);
  }

  private appendImageChunk(src: string) {
    this.imageChunks.push(src);
  }

  private clearImageChunks() {
    this.imageChunks = [];
  }

  private clearOriginImageChunks() {
    this.originImageChunks = [];
  }

  private setEqualizationOptions(values: string[]) {
    this.typeListOptions["equalization"] = values.map((key) => {
      return {
        id: key,
        value: key,
      } as OptionType;
    });
  }

  private setGraymapOptions(values: string[]) {
    this.typeListOptions["graymap"] = values.map((key) => {
      return {
        id: key,
        value: key,
      } as OptionType;
    });
  }

  private packAny(message: ProtobufMessage, typeUrl: string): Any {
    const any = new Any();
    any.pack(message.serializeBinary(), typeUrl);
    return any;
  }

  private validateExamAndSetValue = (
    key: keyof Omit<ExamEntity, "graymap">,
    fetchedValue: number
  ): number => {
    const config = sliderConfigs[key];

    if (fetchedValue < config.min || fetchedValue > config.max) {
      return config.default; // Return default if out of bounds
    }

    if (key === "focus") {
      return fetchedValue / 1000;
    }

    return fetchedValue; // Return fetched value if within bounds
  };

  private toggleIsParameterChanged() {
    this.isParameterChanged = !this.isParameterChanged;
  }
}
