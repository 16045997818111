import { Slider, SliderProps } from "@mui/material";
import { Observer } from "mobx-react";
import { useEffect } from "react";
import { isArray, last } from "lodash";
import { useFormViewContext } from "main";

type TGCSliderViewProps = Omit<SliderProps, "onChange"> & {
  name?: string;
  onChange?: (value: number, index: number) => void;
};

function TGCSliderView({
  min,
  max,
  defaultValue = 0,
  name = "",
  onChange,
  ...rest
}: TGCSliderViewProps) {
  const formStore = useFormViewContext();

  const handleChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    if (isArray(value)) {
      value = last(value) || 0;
    }
    formStore.setValue(name as string, value as number);
    const index = Number(name.slice(-1));
    onChange?.(value, index);
  };

  useEffect(() => {
    const value = (defaultValue ?? min) as number;
    if (value !== undefined && value !== null) {
      formStore.setValue(name as string, value);
    }
  }, [formStore, defaultValue]);

  return (
    <Observer>
      {() => (
        <Slider
          defaultValue={(formStore.getValue(name) as number) || defaultValue}
          min={min}
          max={max}
          onChangeCommitted={handleChange}
          marks={[{ value: min as number }, { value: max as number }]}
          {...rest}
        />
      )}
    </Observer>
  );
}

export default TGCSliderView;
