import { createContext } from "react";
import {
  AuthStore,
  PatientsStore,
  UIStore,
  PatientStore,
  PUStore,
} from "service";

interface AppStoreProps {
  uiStore: UIStore;
  authStore: AuthStore;
  patientStore: PatientStore;
  patientsStore: PatientsStore;
  puStore: PUStore;
}

class AppStore implements AppStoreProps {
  uiStore = new UIStore();
  authStore = new AuthStore();
  patientsStore = new PatientsStore();
  patientStore = new PatientStore();
  puStore = new PUStore();
}

const appStore = new AppStore();
const AppStoreContext = createContext(appStore);

export { AppStoreContext, appStore };
