import { Box, Dialog, DialogProps, LinearProgress } from "@mui/material";
import { ButtonView, IconButtonView, TextBoxView } from "main";
import { convertSecondsToTime } from "utils";

type LoaderDialogProps = DialogProps & {
  progress: number;
  puName: string;
  step: number;
  timerTime: number;
  onClose: () => void;
  estimatedTimeRemaining: number;
  isCalculatingLoadTime?: boolean;
};

function LoaderDialog({
  open,
  progress,
  puName,
  step,
  timerTime,
  onClose,
  estimatedTimeRemaining,
  isCalculatingLoadTime,
}: LoaderDialogProps) {
  const isFirstStep = step === 1;
  const currentTime = isFirstStep ? estimatedTimeRemaining : timerTime;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: "500px",
          backgroundColor: "black.500",
          height: "auto",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "black.100",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          py: 1,
        }}
      >
        <TextBoxView
          sx={{
            mb: 1,
            textAlign: "center",
            color: "secondary.main",
            overflow: "hidden",
          }}
          locale="pages.patient.task_step"
          values={{ step, puName }}
        />
        <IconButtonView iconType="close" onClick={() => onClose()} />
      </Box>
      <Box
        sx={{
          p: 2,
        }}
      >
        <TextBoxView
          locale={
            isFirstStep
              ? "pages.patient.loading_from_s_3"
              : "pages.patient.loading_into_memory"
          }
        />

        <LinearProgress
          variant={isFirstStep ? "determinate" : "indeterminate"}
          value={progress}
          sx={{ my: 1 }}
        />
        {isCalculatingLoadTime && (
          <TextBoxView locale="pages.patient.calculating" sx={{ mt: 2 }} />
        )}
        {!isCalculatingLoadTime && (
          <TextBoxView
            locale="pages.patient.time_remaining"
            sx={{ mt: 2 }}
            values={{ time: convertSecondsToTime(currentTime) }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "black.600",
          p: 2,
        }}
      >
        <ButtonView
          title="general.cancel"
          sx={{
            height: "35px",
          }}
          onClick={() => onClose()}
        />
      </Box>
    </Dialog>
  );
}

export default LoaderDialog;
