import {
  authRoutes,
  AuthRouteType,
  navigationRoutes,
  NavigationRouteType,
  patientRoutes,
  PatientRouteType,
} from "config";
import { CrumbEntity, LoaderEntity } from "service";

export type RouteType = AuthRouteType | NavigationRouteType | PatientRouteType;

export interface RouteProps {
  id: RouteType;
  path: string;
  title: string;
  element: React.FunctionComponent<string>;
  navigationIconType?: string;
  loader?: () => Promise<LoaderEntity>;
  crumb?: CrumbEntity;
}

const _routes: Record<RouteType, RouteProps> = {
  ...authRoutes,
  ...navigationRoutes,
  ...patientRoutes,
};

export { _routes as routes };
