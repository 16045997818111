async function _fetchPatients() {
  return await new Promise((resolve) => {
    setTimeout(
      () =>
        resolve([
          {
            id: "18",
            firstName: "Cody ",
            lastName: "Fisher",
            hospital: "Mount Sinai Hospital",
            examDate: "Sep 11, 2024",
            images: "3",
          },
          {
            id: "8",
            firstName: "Cody",
            lastName: "Fisher",
            hospital: "Mount Sinai Hospital",
            examDate: "Sep 11, 2024",
            images: "3",
          },
          {
            id: "11",
            firstName: "Cody",
            lastName: "Fisher",
            hospital: "Mount Sinai Hospital",
            examDate: "Sep 11, 2024",
            images: "4",
          },
        ]),
      100
    );
  });
}

export { _fetchPatients as fetchPatients };
