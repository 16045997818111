import { ImageListItem, Menu, MenuItem } from "@mui/material";
import TextBoxView from "main/components/common/TextBoxView";
import { useState } from "react";
import { TIFFViewer } from "react-tiff";

type ImageListItemViewProps = {
  imageDatasetKey: string;
  isLocalDataset: boolean;
  imgUrl: string;
  onClick: (s3Key: string, isLocal: boolean) => void;
};

function ImageListItemView({
  imageDatasetKey,
  isLocalDataset,
  onClick,
  imgUrl,
}: ImageListItemViewProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false); // Track if menu is open

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget as HTMLElement);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuClick = (isLocal: boolean) => {
    onClick(imageDatasetKey, isLocal);
    handleCloseMenu();
  };
  return (
    <>
      <ImageListItem
        onContextMenu={handleContextMenu}
        sx={{
          width: "180px",
          height: "180px!important",
          border: 1,
          borderColor: isLocalDataset ? "primary.main" : "black.300",
          borderRadius: 5,
          overflow: "hidden",
          mr: 2,
          mb: 2,
          opacity: isLocalDataset ? 1 : 0.2,
        }}
      >
        <TIFFViewer
          tiff={imgUrl}
          lang="en"
          paginate="ltr"
          printable={false}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </ImageListItem>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleMenuClick(false)}>
          <TextBoxView
            locale="pages.patient.reload_from_s3"
            sx={{ color: "secondary.main" }}
          />
        </MenuItem>
        {isLocalDataset && (
          <MenuItem onClick={() => handleMenuClick(true)}>
            <TextBoxView
              locale="pages.patient.use_local_version"
              sx={{ color: "secondary.main" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default ImageListItemView;
