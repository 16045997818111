import { RpcError } from "grpc-web";
import { makeAutoObservable } from "mobx";
import {
  getAvailablePU,
  loadDataset,
  getTaskStatus,
  storage,
  TaskStateStatusType,
  cancelTask,
  getAvailableDatasets,
} from "service";

interface PUStoreProps {
  isPUAvailable: boolean;
  puId: string;
  taskID: string;
  taskProgress: number;
  isTaskLoading: boolean;
  puName: string;
  taskStatus: TaskStateStatusType;
  taskStep: number;
  timerTime: number;
  isActiveTimer: boolean;
  isTaskRequested: boolean;
  estimatedTimeRemaining: number;
  isLoadingDataSet: boolean;
  getAvailablePU: () => void;
  cancelTask: () => void;
  getTaskStatus: (taskId: string, isLocalLoad: boolean) => void;
  loadDataset: (key: string, local: boolean) => void;
  isEstimateTimeCalculating: boolean;
  availableDatasets: string[];
}

export class PUStore implements PUStoreProps {
  isPUAvailable = false;
  puId = "";
  taskID = "";
  taskProgress = 0;
  isTaskLoading = false;
  puName = "";
  taskStatus: TaskStateStatusType = "PENDING";
  taskStep = 1;
  timerTime = 45;
  isActiveTimer = false;
  isTaskRequested = false;
  estimatedTimeRemaining = 0;
  isLoadingDataSet = false;
  isEstimateTimeCalculating = false;
  availableDatasets: string[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getTaskId() {
    const { taskId } = storage.getCache();
    return taskId;
  }

  getAvailablePU() {
    getAvailablePU()
      .then((resp) => {
        const { data } = resp;
        if (data.status === "available") {
          this.setPUAvailable(true);
          this.setPUName(data.name);
        } else {
          this.setPUAvailable(false);
        }
        this.puId = data.id;
      })
      .catch((error) => {
        console.log(`pu available: ${(error as RpcError)?.message}` || error);
        this.setPUAvailable(false);
      });
  }

  async loadDataset(key: string, local: boolean) {
    try {
      this.setIsLoadingDataSet(true);
      const { data } = await loadDataset(key, local);
      this.taskID = data.task_id;
      return data.task_id;
    } catch (error) {
      console.log(`load dataset: ${(error as RpcError)?.message}` || error);
      this.taskID = "";
    } finally {
      this.setIsLoadingDataSet(false);
    }
  }

  async getTaskStatus(taskID: string, isLocalLoad: boolean) {
    try {
      this.setTaskProgress(0);
      this.setTaskLoading(true);
      this.setIsEstimateTimeCalculating(true);
      this.isTaskRequested = true;
      const startTime = Date.now();
      let requestCount = 0;
      while (this.isTaskRequested) {
        requestCount++;
        const elapsedTime = (Date.now() - startTime) / 1000;
        const { data } = await getTaskStatus(taskID);
        const { info, state } = data;
        this.setTask(state as TaskStateStatusType);
        if (this.taskProgress === 100 || isLocalLoad) {
          if (this.taskStatus === "SUCCESS") {
            break;
          } else if (this.taskStatus === "PROGRESS") {
            this.setTaskStep(2);
            this.startTimer();
            this.setIsEstimateTimeCalculating(false);
          }
        }

        if (
          info &&
          info.current !== undefined &&
          info.total !== undefined &&
          !isLocalLoad
        ) {
          const currentProgress = (info.current / info.total) * 100;
          this.setTaskProgress(currentProgress);
          if (requestCount >= 5) {
            this.setIsEstimateTimeCalculating(false);
            const downloadSpeed = info.current / elapsedTime;
            const estimateTime = (info.total - info.current) / downloadSpeed;
            this.setEstimatedTimeRemaining(estimateTime);
          }
        } else {
          this.setTaskProgress(Math.max(this.taskProgress, 0));
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    } catch (error) {
      console.log(`task status: ${(error as RpcError)?.message}` || error);
      this.clearTaskStatus();
      this.taskStatus = "FINISHED";
    } finally {
      this.setTaskLoading(false);
      this.setTaskStep(1);
      this.setTaskProgress(0);
      this.resetTimer();
      this.setEstimatedTimeRemaining(0);
      this.setIsEstimateTimeCalculating(false);
    }
  }

  async cancelTask() {
    await cancelTask(this.taskID);
    this.setTaskLoading(false);
    this.taskStatus = "REVOKED";
    this.setTaskProgress(0);
    this.isTaskRequested = false;
  }

  async getAvailableDatasets() {
    try {
      const { data } = await getAvailableDatasets();

      this.setAvailableDatasets(data);
    } catch (err) {
      console.log({ err });
    }
  }

  startTimer() {
    if (this.timerTime > 0) {
      this.isActiveTimer = true;
      const interval = setInterval(() => {
        if (this.timerTime <= 0) {
          clearInterval(interval);
          this.isActiveTimer = false;
        } else {
          this.setTimerTime(this.timerTime - 1);
        }
      }, 2000);
    }
  }

  resetTimer() {
    this.setTimerTime(45);
    this.isActiveTimer = false;
  }

  clearTaskStatus() {
    this.taskStatus = "PENDING";
  }

  private setTask(status: TaskStateStatusType) {
    this.taskStatus = status;
  }

  private setPUAvailable(state: boolean) {
    this.isPUAvailable = state;
  }

  private setPUName(name: string) {
    this.puName = name;
  }

  private setTaskProgress(value: number) {
    this.taskProgress = value;
  }

  private setEstimatedTimeRemaining(value: number) {
    this.estimatedTimeRemaining = value;
  }

  private setIsLoadingDataSet(state: boolean) {
    this.isLoadingDataSet = state;
  }

  private setTaskStep(value: number) {
    this.taskStep = value;
  }

  private setTimerTime(value: number) {
    this.timerTime = value;
  }

  private setTaskLoading(state: boolean) {
    this.isTaskLoading = state;
  }

  private setIsEstimateTimeCalculating(state: boolean) {
    this.isEstimateTimeCalculating = state;
  }

  private setAvailableDatasets(data: string[]) {
    this.availableDatasets = data;
  }
}
