import { createTheme, responsiveFontSizes } from "@mui/material";
import { themeComponents, themePalette, themeTypography } from "config";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 8,
  palette: themePalette,
  typography: themeTypography,
  components: themeComponents,
});

export default responsiveFontSizes(theme);
