import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { IconView } from "main";
import { IconViewType } from "./IconView";
import { Box } from "@mui/material";

type IconViewProps = IconButtonProps & {
  iconType: IconViewType;
  children?: React.ReactNode | React.ReactNode[];
};

function IconButtonView({
  size = "small",
  color = "secondary",
  iconType,
  children,
  ...props
}: IconViewProps) {
  return (
    <IconButton color={color} size={size} {...props}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
        <IconView type={iconType} />
      </Box>
    </IconButton>
  );
}

export default IconButtonView;
