import { makeAutoObservable } from "mobx";

class DropdownStore {
  anchorEl?: HTMLElement;
  open = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  toggle(ev: React.MouseEvent<HTMLElement>) {
    this.anchorEl = this.open ? undefined : ev.currentTarget;
    this.open = !this.open;
  }
}

export default DropdownStore;
