import { AxiosError } from "axios";
import { appStore, cacheable, Cacheable, ServerResultEntity } from "service";
import { authRoutes } from "config";

/* eslint-disable  @typescript-eslint/no-explicit-any */
function isAxiosError(value: any): value is AxiosError {
  return value.isAxiosError === true;
}

type ApiProps<Type> = {
  fn: () => Promise<Type>;
  cacheKey?: keyof Cacheable;
  errorMsg?: string;
  successMsg?: string;
};

async function useApi<T>({
  fn,
  cacheKey,
  errorMsg,
  successMsg,
}: ApiProps<T>): Promise<ServerResultEntity<T>> {
  let result: Awaited<T> | undefined;
  const { uiStore, authStore } = appStore;
  try {
    // retrive the data from backend.
    result = await fn();
    if (cacheKey) {
      cacheable(cacheKey, undefined, result);
    }

    if (successMsg) {
      uiStore.showSuccess(successMsg);
    }
    return {
      success: result,
      failure: undefined,
    } as ServerResultEntity<T>;
  } catch (error) {
    if (
      isAxiosError(error) &&
      (error.response?.data as { statusCode?: number })?.statusCode === 401
    ) {
      authStore.signOut();
      window.location.href = authRoutes.signIn.path;
    }

    console.error(`Error:`, error);
    if (errorMsg) {
      uiStore.showError(errorMsg);
    }
    return {
      success: undefined,
      failure: (error as AxiosError).response?.data,
    } as ServerResultEntity<T>;
  }
}

export { useApi };
