import {
  Box,
  Card,
  CardContent,
  CardHeader,
  ImageList,
  Stack,
  CardActions,
} from "@mui/material";
import { cardHeaderStyle, baseCardStyle, cardContentStyles } from "config";
import { includes } from "lodash";
import {
  ButtonView,
  DraggableModal,
  ExamStreamView,
  ImageControlsPanel,
  PreviewImageView,
  TextBoxView,
} from "main";
import { Observer } from "mobx-react";
import { createContext, useContext, useEffect } from "react";
import { ExamStore } from "service";

// Create the context
const ExamViewContext = createContext<ExamStore | null>(null);

// Create a custom hook for using the StreamViewContext
export const useExamViewContext = () => {
  const context = useContext(ExamViewContext);
  if (!context) {
    throw new Error(
      "useStreamViewContext must be used within a StreamViewProvider"
    );
  }
  return context;
};

function ExamPage() {
  const examStore = new ExamStore();

  useEffect(() => {
    const pathname = location.pathname;
    const isExam = includes(pathname, "/exam");
    examStore.startStream = isExam;
  }, [location]);

  return (
    <ExamViewContext.Provider value={examStore}>
      <Box sx={{ height: "100%", maxHeight: "100%" }}>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", height: "100%", p: 3 }}
        >
          <Card
            sx={{
              ...baseCardStyle,
              flex: 1,
            }}
          >
            <CardHeader
              sx={{
                ...cardHeaderStyle,
              }}
              title={
                <TextBoxView
                  locale="pages.exam.media"
                  sx={{ fontSize: "0.9rem" }}
                />
              }
            />
            <CardContent
              sx={{
                ...cardContentStyles,
                overflow: "scroll",
                paddingBottom: "0!important",
                height: "83%",
              }}
            >
              <Observer>
                {() => {
                  const {
                    filteredImages,
                    toggleImageSelection,
                    isImageSelected,
                    toggleOpenedModals,
                    openedModals,
                  } = examStore;
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <ImageList cols={1}>
                        {examStore.filteredVideos.map((videoSrc) => (
                          <PreviewImageView
                            key={`media-${videoSrc}`}
                            src={videoSrc}
                            isVideo={true}
                          />
                        ))}
                        {filteredImages.map((img) => (
                          <PreviewImageView
                            key={`media-${img}`}
                            src={img}
                            isImage={true}
                            onImageClick={toggleOpenedModals}
                            isImageChecked={isImageSelected(img)}
                            onSelectImage={toggleImageSelection}
                          />
                        ))}
                      </ImageList>

                      {openedModals.map((image, index) => (
                        <DraggableModal
                          key={`image-${image}-${index}`}
                          onClose={toggleOpenedModals}
                          imageSrc={image}
                          index={index}
                        />
                      ))}
                    </Box>
                  );
                }}
              </Observer>
            </CardContent>
            <CardActions
              sx={{
                height: "8%",
                width: "100%",
                display: "flex",
                alignItems: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Observer>
                {() => (
                  <ButtonView
                    title="pages.exam.download_image"
                    onClick={examStore.downloadImages}
                    sx={{ px: 1, height: "40px", width: "100%" }}
                    disabled={!examStore.imagesToUpload.length}
                  />
                )}
              </Observer>
            </CardActions>
          </Card>
          <ExamStreamView />
          <Card
            sx={{
              ...baseCardStyle,
              p: 0,
              flex: 1.3,
            }}
          >
            <CardContent
              sx={{
                ...cardContentStyles,
                height: "100%!important",
                p: "0",
                pb: "0!important",
              }}
            >
              <Observer>{() => <ImageControlsPanel />}</Observer>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </ExamViewContext.Provider>
  );
}

export default ExamPage;
