import { makeAutoObservable } from "mobx";
import { signIn, SignInEntity, storage, useApi } from "service";
import { LoginInfo } from "service/entity/AuthEntity";

interface AuthStoreProps {
  signIn: (body: SignInEntity) => void;
  signOut: () => void;
  setLoggedIn: (loggedIn: boolean) => void;
  getToken: () => string | undefined;
  loggedIn: boolean;
  currentUser?: LoginInfo;
  setCurrentUser: (user: LoginInfo) => void;
}

export class AuthStore implements AuthStoreProps {
  loggedIn: boolean = true;
  currentUser?: LoginInfo;

  constructor() {
    this.setLoggedIn(!!this.getToken());
    // this.setLoggedIn(true);
    makeAutoObservable(this);
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
  }

  setCurrentUser(user: LoginInfo) {
    this.currentUser = {
      ...this.currentUser,
      ...user,
    };
  }

  getToken() {
    const { user: { access_token } = {} } = storage.getCache();
    return access_token;
  }

  signOut() {
    storage.clearCache("user");
    this.setLoggedIn(false);
  }

  async signIn(body: SignInEntity) {
    return useApi({
      cacheKey: "user",
      fn: () => signIn(body),
      errorMsg: "general.something_went_wrong",
    });
  }
}
