/**
 * @fileoverview gRPC-Web generated client stub for k3900
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.2
// source: src/proto/k3900.proto

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from "grpc-web";

import * as src_proto_k3900_pb from "../../src/proto/k3900_pb"; // proto import: "src/proto/k3900.proto"

export class BeamformerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, "");
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetSystemState = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetSystemState",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.SystemStateRequest,
    src_proto_k3900_pb.SystemState,
    (request: src_proto_k3900_pb.SystemStateRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.SystemState.deserializeBinary
  );

  getSystemState(
    request: src_proto_k3900_pb.SystemStateRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.SystemState>;

  getSystemState(
    request: src_proto_k3900_pb.SystemStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.SystemState
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.SystemState>;

  getSystemState(
    request: src_proto_k3900_pb.SystemStateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.SystemState
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetSystemState",
        request,
        metadata || {},
        this.methodDescriptorGetSystemState,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetSystemState",
      request,
      metadata || {},
      this.methodDescriptorGetSystemState
    );
  }

  methodDescriptorSetBeamformerParameters = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SetBeamformerParameters",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BeamformerParametersRequest,
    src_proto_k3900_pb.BeamformerParametersResponse,
    (request: src_proto_k3900_pb.BeamformerParametersRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.BeamformerParametersResponse.deserializeBinary
  );

  setBeamformerParameters(
    request: src_proto_k3900_pb.BeamformerParametersRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.BeamformerParametersResponse>;

  setBeamformerParameters(
    request: src_proto_k3900_pb.BeamformerParametersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.BeamformerParametersResponse
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.BeamformerParametersResponse>;

  setBeamformerParameters(
    request: src_proto_k3900_pb.BeamformerParametersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.BeamformerParametersResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SetBeamformerParameters",
        request,
        metadata || {},
        this.methodDescriptorSetBeamformerParameters,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SetBeamformerParameters",
      request,
      metadata || {},
      this.methodDescriptorSetBeamformerParameters
    );
  }

  methodDescriptorSendBeamformedImageStream = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SendBeamformedImageStream",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.ImageRequest,
    src_proto_k3900_pb.ImageChunk,
    (request: src_proto_k3900_pb.ImageRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ImageChunk.deserializeBinary
  );

  sendBeamformedImageStream(
    request: src_proto_k3900_pb.ImageRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ImageChunk> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/SendBeamformedImageStream",
      request,
      metadata || {},
      this.methodDescriptorSendBeamformedImageStream
    );
  }

  methodDescriptorStartSampleUpload = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/StartSampleUpload",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.RawCaptureMetadata,
    src_proto_k3900_pb.UploadStatusResponse,
    (request: src_proto_k3900_pb.RawCaptureMetadata) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.UploadStatusResponse.deserializeBinary
  );

  startSampleUpload(
    request: src_proto_k3900_pb.RawCaptureMetadata,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.UploadStatusResponse>;

  startSampleUpload(
    request: src_proto_k3900_pb.RawCaptureMetadata,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.UploadStatusResponse
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.UploadStatusResponse>;

  startSampleUpload(
    request: src_proto_k3900_pb.RawCaptureMetadata,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.UploadStatusResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/StartSampleUpload",
        request,
        metadata || {},
        this.methodDescriptorStartSampleUpload,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/StartSampleUpload",
      request,
      metadata || {},
      this.methodDescriptorStartSampleUpload
    );
  }

  methodDescriptorStartSampleDownload = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/StartSampleDownload",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.DownloadRequest,
    src_proto_k3900_pb.RawCaptureMetadata,
    (request: src_proto_k3900_pb.DownloadRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.RawCaptureMetadata.deserializeBinary
  );

  startSampleDownload(
    request: src_proto_k3900_pb.DownloadRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.RawCaptureMetadata>;

  startSampleDownload(
    request: src_proto_k3900_pb.DownloadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.RawCaptureMetadata
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.RawCaptureMetadata>;

  startSampleDownload(
    request: src_proto_k3900_pb.DownloadRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.RawCaptureMetadata
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/StartSampleDownload",
        request,
        metadata || {},
        this.methodDescriptorStartSampleDownload,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/StartSampleDownload",
      request,
      metadata || {},
      this.methodDescriptorStartSampleDownload
    );
  }

  methodDescriptorDownloadPingData = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DownloadPingData",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.PingDownloadDataRequest,
    src_proto_k3900_pb.Ping,
    (request: src_proto_k3900_pb.PingDownloadDataRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.Ping.deserializeBinary
  );

  downloadPingData(
    request: src_proto_k3900_pb.PingDownloadDataRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.Ping> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/DownloadPingData",
      request,
      metadata || {},
      this.methodDescriptorDownloadPingData
    );
  }

  methodDescriptorRequestImageFormat = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/RequestImageFormat",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ImageFormat,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ImageFormat) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  requestImageFormat(
    request: src_proto_k3900_pb.ImageFormat,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  requestImageFormat(
    request: src_proto_k3900_pb.ImageFormat,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  requestImageFormat(
    request: src_proto_k3900_pb.ImageFormat,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/RequestImageFormat",
        request,
        metadata || {},
        this.methodDescriptorRequestImageFormat,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/RequestImageFormat",
      request,
      metadata || {},
      this.methodDescriptorRequestImageFormat
    );
  }

  methodDescriptorPingServer = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/PingServer",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  pingServer(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  pingServer(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  pingServer(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/PingServer",
        request,
        metadata || {},
        this.methodDescriptorPingServer,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/PingServer",
      request,
      metadata || {},
      this.methodDescriptorPingServer
    );
  }

  methodDescriptorPerformTEC = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/PerformTEC",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.TECMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.TECMsg.deserializeBinary
  );

  performTEC(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.TECMsg>;

  performTEC(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.TECMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.TECMsg>;

  performTEC(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.TECMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/PerformTEC",
        request,
        metadata || {},
        this.methodDescriptorPerformTEC,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/PerformTEC",
      request,
      metadata || {},
      this.methodDescriptorPerformTEC
    );
  }

  methodDescriptorSystemShutdown = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SystemShutdown",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ShutdownRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ShutdownRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  systemShutdown(
    request: src_proto_k3900_pb.ShutdownRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  systemShutdown(
    request: src_proto_k3900_pb.ShutdownRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  systemShutdown(
    request: src_proto_k3900_pb.ShutdownRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SystemShutdown",
        request,
        metadata || {},
        this.methodDescriptorSystemShutdown,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SystemShutdown",
      request,
      metadata || {},
      this.methodDescriptorSystemShutdown
    );
  }

  methodDescriptorStartMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/StartMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  startMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  startMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  startMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/StartMeasurement",
        request,
        metadata || {},
        this.methodDescriptorStartMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/StartMeasurement",
      request,
      metadata || {},
      this.methodDescriptorStartMeasurement
    );
  }

  methodDescriptorStopMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/StopMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  stopMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  stopMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  stopMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/StopMeasurement",
        request,
        metadata || {},
        this.methodDescriptorStopMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/StopMeasurement",
      request,
      metadata || {},
      this.methodDescriptorStopMeasurement
    );
  }

  methodDescriptorCaptureMeasurementMark = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/CaptureMeasurementMark",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  captureMeasurementMark(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  captureMeasurementMark(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  captureMeasurementMark(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/CaptureMeasurementMark",
        request,
        metadata || {},
        this.methodDescriptorCaptureMeasurementMark,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/CaptureMeasurementMark",
      request,
      metadata || {},
      this.methodDescriptorCaptureMeasurementMark
    );
  }

  methodDescriptorCancelMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/CancelMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  cancelMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  cancelMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  cancelMeasurement(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/CancelMeasurement",
        request,
        metadata || {},
        this.methodDescriptorCancelMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/CancelMeasurement",
      request,
      metadata || {},
      this.methodDescriptorCancelMeasurement
    );
  }

  methodDescriptorDeleteMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeleteMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.EditMeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.EditMeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deleteMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deleteMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deleteMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeleteMeasurement",
        request,
        metadata || {},
        this.methodDescriptorDeleteMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeleteMeasurement",
      request,
      metadata || {},
      this.methodDescriptorDeleteMeasurement
    );
  }

  methodDescriptorEditMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/EditMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.EditMeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.EditMeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  editMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  editMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  editMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/EditMeasurement",
        request,
        metadata || {},
        this.methodDescriptorEditMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/EditMeasurement",
      request,
      metadata || {},
      this.methodDescriptorEditMeasurement
    );
  }

  methodDescriptorHighlightMeasurement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/HighlightMeasurement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.EditMeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.EditMeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  highlightMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  highlightMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  highlightMeasurement(
    request: src_proto_k3900_pb.EditMeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/HighlightMeasurement",
        request,
        metadata || {},
        this.methodDescriptorHighlightMeasurement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/HighlightMeasurement",
      request,
      metadata || {},
      this.methodDescriptorHighlightMeasurement
    );
  }

  methodDescriptorSendCursorMovement = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SendCursorMovement",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.CursorMoveRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.CursorMoveRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  sendCursorMovement(
    request: src_proto_k3900_pb.CursorMoveRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  sendCursorMovement(
    request: src_proto_k3900_pb.CursorMoveRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  sendCursorMovement(
    request: src_proto_k3900_pb.CursorMoveRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SendCursorMovement",
        request,
        metadata || {},
        this.methodDescriptorSendCursorMovement,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SendCursorMovement",
      request,
      metadata || {},
      this.methodDescriptorSendCursorMovement
    );
  }

  methodDescriptorSwapMeasurementPoints = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SwapMeasurementPoints",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  swapMeasurementPoints(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  swapMeasurementPoints(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  swapMeasurementPoints(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SwapMeasurementPoints",
        request,
        metadata || {},
        this.methodDescriptorSwapMeasurementPoints,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SwapMeasurementPoints",
      request,
      metadata || {},
      this.methodDescriptorSwapMeasurementPoints
    );
  }

  methodDescriptorGetMeasurements = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetMeasurements",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MeasurementRequest,
    src_proto_k3900_pb.Measurements,
    (request: src_proto_k3900_pb.MeasurementRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.Measurements.deserializeBinary
  );

  getMeasurements(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.Measurements>;

  getMeasurements(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.Measurements
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.Measurements>;

  getMeasurements(
    request: src_proto_k3900_pb.MeasurementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.Measurements
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetMeasurements",
        request,
        metadata || {},
        this.methodDescriptorGetMeasurements,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetMeasurements",
      request,
      metadata || {},
      this.methodDescriptorGetMeasurements
    );
  }

  methodDescriptorGetMeasurementFrames = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetMeasurementFrames",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.FrameRequest,
    src_proto_k3900_pb.FrameMsg,
    (request: src_proto_k3900_pb.FrameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.FrameMsg.deserializeBinary
  );

  getMeasurementFrames(
    request: src_proto_k3900_pb.FrameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.FrameMsg>;

  getMeasurementFrames(
    request: src_proto_k3900_pb.FrameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.FrameMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.FrameMsg>;

  getMeasurementFrames(
    request: src_proto_k3900_pb.FrameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.FrameMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetMeasurementFrames",
        request,
        metadata || {},
        this.methodDescriptorGetMeasurementFrames,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetMeasurementFrames",
      request,
      metadata || {},
      this.methodDescriptorGetMeasurementFrames
    );
  }

  methodDescriptorSetFrame = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SetFrame",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.FrameMsg,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.FrameMsg) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  setFrame(
    request: src_proto_k3900_pb.FrameMsg,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  setFrame(
    request: src_proto_k3900_pb.FrameMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  setFrame(
    request: src_proto_k3900_pb.FrameMsg,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SetFrame",
        request,
        metadata || {},
        this.methodDescriptorSetFrame,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SetFrame",
      request,
      metadata || {},
      this.methodDescriptorSetFrame
    );
  }

  methodDescriptorGetExams = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetExams",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.PatientRequest,
    src_proto_k3900_pb.Exam,
    (request: src_proto_k3900_pb.PatientRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.Exam.deserializeBinary
  );

  getExams(
    request: src_proto_k3900_pb.PatientRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.Exam> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetExams",
      request,
      metadata || {},
      this.methodDescriptorGetExams
    );
  }

  methodDescriptorExportExams = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ExportExams",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.PatientRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.PatientRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  exportExams(
    request: src_proto_k3900_pb.PatientRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  exportExams(
    request: src_proto_k3900_pb.PatientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  exportExams(
    request: src_proto_k3900_pb.PatientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ExportExams",
        request,
        metadata || {},
        this.methodDescriptorExportExams,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ExportExams",
      request,
      metadata || {},
      this.methodDescriptorExportExams
    );
  }

  methodDescriptorDeletePatient = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeletePatient",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.PatientRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.PatientRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deletePatient(
    request: src_proto_k3900_pb.PatientRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deletePatient(
    request: src_proto_k3900_pb.PatientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deletePatient(
    request: src_proto_k3900_pb.PatientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeletePatient",
        request,
        metadata || {},
        this.methodDescriptorDeletePatient,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeletePatient",
      request,
      metadata || {},
      this.methodDescriptorDeletePatient
    );
  }

  methodDescriptorGetPatients = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetPatients",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.QueryRequest,
    src_proto_k3900_pb.Patient,
    (request: src_proto_k3900_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.Patient.deserializeBinary
  );

  getPatients(
    request: src_proto_k3900_pb.QueryRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.Patient> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetPatients",
      request,
      metadata || {},
      this.methodDescriptorGetPatients
    );
  }

  methodDescriptorBeginExam = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/BeginExam",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  beginExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  beginExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  beginExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/BeginExam",
        request,
        metadata || {},
        this.methodDescriptorBeginExam,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/BeginExam",
      request,
      metadata || {},
      this.methodDescriptorBeginExam
    );
  }

  methodDescriptorEndExam = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/EndExam",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  endExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  endExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  endExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/EndExam",
        request,
        metadata || {},
        this.methodDescriptorEndExam,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/EndExam",
      request,
      metadata || {},
      this.methodDescriptorEndExam
    );
  }

  methodDescriptorExportExam = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ExportExam",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  exportExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  exportExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  exportExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ExportExam",
        request,
        metadata || {},
        this.methodDescriptorExportExam,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ExportExam",
      request,
      metadata || {},
      this.methodDescriptorExportExam
    );
  }

  methodDescriptorDeleteExam = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeleteExam",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deleteExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deleteExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deleteExam(
    request: src_proto_k3900_pb.ExamRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeleteExam",
        request,
        metadata || {},
        this.methodDescriptorDeleteExam,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeleteExam",
      request,
      metadata || {},
      this.methodDescriptorDeleteExam
    );
  }

  methodDescriptorSave = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/Save",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.SaveRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.SaveRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  save(
    request: src_proto_k3900_pb.SaveRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  save(
    request: src_proto_k3900_pb.SaveRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  save(
    request: src_proto_k3900_pb.SaveRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/Save",
        request,
        metadata || {},
        this.methodDescriptorSave,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/Save",
      request,
      metadata || {},
      this.methodDescriptorSave
    );
  }

  methodDescriptorLiveClip = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/LiveClip",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ClipRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ClipRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  liveClip(
    request: src_proto_k3900_pb.ClipRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  liveClip(
    request: src_proto_k3900_pb.ClipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  liveClip(
    request: src_proto_k3900_pb.ClipRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/LiveClip",
        request,
        metadata || {},
        this.methodDescriptorLiveClip,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/LiveClip",
      request,
      metadata || {},
      this.methodDescriptorLiveClip
    );
  }

  methodDescriptorGetProgress = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetProgress",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ProgressRequest,
    src_proto_k3900_pb.Progress,
    (request: src_proto_k3900_pb.ProgressRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.Progress.deserializeBinary
  );

  getProgress(
    request: src_proto_k3900_pb.ProgressRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.Progress>;

  getProgress(
    request: src_proto_k3900_pb.ProgressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.Progress
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.Progress>;

  getProgress(
    request: src_proto_k3900_pb.ProgressRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.Progress
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetProgress",
        request,
        metadata || {},
        this.methodDescriptorGetProgress,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetProgress",
      request,
      metadata || {},
      this.methodDescriptorGetProgress
    );
  }

  methodDescriptorGetExamStores = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetExamStores",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.ExamStoresRequest,
    src_proto_k3900_pb.ExamStore,
    (request: src_proto_k3900_pb.ExamStoresRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ExamStore.deserializeBinary
  );

  getExamStores(
    request: src_proto_k3900_pb.ExamStoresRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ExamStore> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetExamStores",
      request,
      metadata || {},
      this.methodDescriptorGetExamStores
    );
  }

  methodDescriptorGetExamStoresTypes = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetExamStoresTypes",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamStoresTypeRequest,
    src_proto_k3900_pb.ExamStoresTypes,
    (request: src_proto_k3900_pb.ExamStoresTypeRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ExamStoresTypes.deserializeBinary
  );

  getExamStoresTypes(
    request: src_proto_k3900_pb.ExamStoresTypeRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ExamStoresTypes>;

  getExamStoresTypes(
    request: src_proto_k3900_pb.ExamStoresTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ExamStoresTypes
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ExamStoresTypes>;

  getExamStoresTypes(
    request: src_proto_k3900_pb.ExamStoresTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ExamStoresTypes
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetExamStoresTypes",
        request,
        metadata || {},
        this.methodDescriptorGetExamStoresTypes,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetExamStoresTypes",
      request,
      metadata || {},
      this.methodDescriptorGetExamStoresTypes
    );
  }

  methodDescriptorDeleteExamStoresType = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeleteExamStoresType",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamStoresRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamStoresRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deleteExamStoresType(
    request: src_proto_k3900_pb.ExamStoresRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deleteExamStoresType(
    request: src_proto_k3900_pb.ExamStoresRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deleteExamStoresType(
    request: src_proto_k3900_pb.ExamStoresRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeleteExamStoresType",
        request,
        metadata || {},
        this.methodDescriptorDeleteExamStoresType,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeleteExamStoresType",
      request,
      metadata || {},
      this.methodDescriptorDeleteExamStoresType
    );
  }

  methodDescriptorLoadExamFile = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/LoadExamFile",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamFileRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamFileRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  loadExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  loadExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  loadExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/LoadExamFile",
        request,
        metadata || {},
        this.methodDescriptorLoadExamFile,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/LoadExamFile",
      request,
      metadata || {},
      this.methodDescriptorLoadExamFile
    );
  }

  methodDescriptorDeleteExamFile = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeleteExamFile",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ExamFileRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ExamFileRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deleteExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deleteExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deleteExamFile(
    request: src_proto_k3900_pb.ExamFileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeleteExamFile",
        request,
        metadata || {},
        this.methodDescriptorDeleteExamFile,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeleteExamFile",
      request,
      metadata || {},
      this.methodDescriptorDeleteExamFile
    );
  }

  methodDescriptorGetMediaFile = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetMediaFile",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.MediaFileRequest,
    src_proto_k3900_pb.MediaFile,
    (request: src_proto_k3900_pb.MediaFileRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.MediaFile.deserializeBinary
  );

  getMediaFile(
    request: src_proto_k3900_pb.MediaFileRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.MediaFile> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetMediaFile",
      request,
      metadata || {},
      this.methodDescriptorGetMediaFile
    );
  }

  methodDescriptorUpdateMediaState = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/UpdateMediaState",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MediaUpdateRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MediaUpdateRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  updateMediaState(
    request: src_proto_k3900_pb.MediaUpdateRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  updateMediaState(
    request: src_proto_k3900_pb.MediaUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  updateMediaState(
    request: src_proto_k3900_pb.MediaUpdateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/UpdateMediaState",
        request,
        metadata || {},
        this.methodDescriptorUpdateMediaState,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/UpdateMediaState",
      request,
      metadata || {},
      this.methodDescriptorUpdateMediaState
    );
  }

  methodDescriptorGetDiagnosticTests = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetDiagnosticTests",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.DiagnosticTestsRequest,
    src_proto_k3900_pb.DiagnosticTestList,
    (request: src_proto_k3900_pb.DiagnosticTestsRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.DiagnosticTestList.deserializeBinary
  );

  getDiagnosticTests(
    request: src_proto_k3900_pb.DiagnosticTestsRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.DiagnosticTestList>;

  getDiagnosticTests(
    request: src_proto_k3900_pb.DiagnosticTestsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.DiagnosticTestList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.DiagnosticTestList>;

  getDiagnosticTests(
    request: src_proto_k3900_pb.DiagnosticTestsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.DiagnosticTestList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetDiagnosticTests",
        request,
        metadata || {},
        this.methodDescriptorGetDiagnosticTests,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetDiagnosticTests",
      request,
      metadata || {},
      this.methodDescriptorGetDiagnosticTests
    );
  }

  methodDescriptorPerformDiagnosticTest = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/PerformDiagnosticTest",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.DiagnosticRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.DiagnosticRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  performDiagnosticTest(
    request: src_proto_k3900_pb.DiagnosticRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  performDiagnosticTest(
    request: src_proto_k3900_pb.DiagnosticRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  performDiagnosticTest(
    request: src_proto_k3900_pb.DiagnosticRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/PerformDiagnosticTest",
        request,
        metadata || {},
        this.methodDescriptorPerformDiagnosticTest,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/PerformDiagnosticTest",
      request,
      metadata || {},
      this.methodDescriptorPerformDiagnosticTest
    );
  }

  methodDescriptorAbortDiagnosticTest = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/AbortDiagnosticTest",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  abortDiagnosticTest(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  abortDiagnosticTest(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  abortDiagnosticTest(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/AbortDiagnosticTest",
        request,
        metadata || {},
        this.methodDescriptorAbortDiagnosticTest,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/AbortDiagnosticTest",
      request,
      metadata || {},
      this.methodDescriptorAbortDiagnosticTest
    );
  }

  methodDescriptorClearErrors = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ClearErrors",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ClearErrorsRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ClearErrorsRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  clearErrors(
    request: src_proto_k3900_pb.ClearErrorsRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  clearErrors(
    request: src_proto_k3900_pb.ClearErrorsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  clearErrors(
    request: src_proto_k3900_pb.ClearErrorsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ClearErrors",
        request,
        metadata || {},
        this.methodDescriptorClearErrors,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ClearErrors",
      request,
      metadata || {},
      this.methodDescriptorClearErrors
    );
  }

  methodDescriptorGetFilterList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetFilterList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.FilterList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.FilterList.deserializeBinary
  );

  getFilterList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.FilterList>;

  getFilterList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.FilterList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.FilterList>;

  getFilterList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.FilterList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetFilterList",
        request,
        metadata || {},
        this.methodDescriptorGetFilterList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetFilterList",
      request,
      metadata || {},
      this.methodDescriptorGetFilterList
    );
  }

  methodDescriptorSelectFilter = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectFilter",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.FilterRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.FilterRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  selectFilter(
    request: src_proto_k3900_pb.FilterRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  selectFilter(
    request: src_proto_k3900_pb.FilterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  selectFilter(
    request: src_proto_k3900_pb.FilterRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectFilter",
        request,
        metadata || {},
        this.methodDescriptorSelectFilter,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectFilter",
      request,
      metadata || {},
      this.methodDescriptorSelectFilter
    );
  }

  methodDescriptorGetProbeList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetProbeList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ProbeList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ProbeList.deserializeBinary
  );

  getProbeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ProbeList>;

  getProbeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ProbeList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ProbeList>;

  getProbeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ProbeList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetProbeList",
        request,
        metadata || {},
        this.methodDescriptorGetProbeList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetProbeList",
      request,
      metadata || {},
      this.methodDescriptorGetProbeList
    );
  }

  methodDescriptorSelectProbe = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectProbe",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ProbeRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ProbeRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  selectProbe(
    request: src_proto_k3900_pb.ProbeRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  selectProbe(
    request: src_proto_k3900_pb.ProbeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  selectProbe(
    request: src_proto_k3900_pb.ProbeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectProbe",
        request,
        metadata || {},
        this.methodDescriptorSelectProbe,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectProbe",
      request,
      metadata || {},
      this.methodDescriptorSelectProbe
    );
  }

  methodDescriptorWriteProbe = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/WriteProbe",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.WriteProbeRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.WriteProbeRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  writeProbe(
    request: src_proto_k3900_pb.WriteProbeRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  writeProbe(
    request: src_proto_k3900_pb.WriteProbeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  writeProbe(
    request: src_proto_k3900_pb.WriteProbeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/WriteProbe",
        request,
        metadata || {},
        this.methodDescriptorWriteProbe,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/WriteProbe",
      request,
      metadata || {},
      this.methodDescriptorWriteProbe
    );
  }

  methodDescriptorGetGraymapTypeList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetGraymapTypeList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getGraymapTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getGraymapTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getGraymapTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetGraymapTypeList",
        request,
        metadata || {},
        this.methodDescriptorGetGraymapTypeList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetGraymapTypeList",
      request,
      metadata || {},
      this.methodDescriptorGetGraymapTypeList
    );
  }

  methodDescriptorSelectGraymapType = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectGraymapType",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  selectGraymapType(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  selectGraymapType(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  selectGraymapType(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectGraymapType",
        request,
        metadata || {},
        this.methodDescriptorSelectGraymapType,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectGraymapType",
      request,
      metadata || {},
      this.methodDescriptorSelectGraymapType
    );
  }

  methodDescriptorGetFramePersistenceList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetFramePersistenceList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getFramePersistenceList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getFramePersistenceList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getFramePersistenceList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetFramePersistenceList",
        request,
        metadata || {},
        this.methodDescriptorGetFramePersistenceList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetFramePersistenceList",
      request,
      metadata || {},
      this.methodDescriptorGetFramePersistenceList
    );
  }

  methodDescriptorSelectFramePersistence = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectFramePersistence",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  selectFramePersistence(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  selectFramePersistence(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  selectFramePersistence(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectFramePersistence",
        request,
        metadata || {},
        this.methodDescriptorSelectFramePersistence,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectFramePersistence",
      request,
      metadata || {},
      this.methodDescriptorSelectFramePersistence
    );
  }

  methodDescriptorGetHistEqualTypeList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetHistEqualTypeList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getHistEqualTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getHistEqualTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getHistEqualTypeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetHistEqualTypeList",
        request,
        metadata || {},
        this.methodDescriptorGetHistEqualTypeList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetHistEqualTypeList",
      request,
      metadata || {},
      this.methodDescriptorGetHistEqualTypeList
    );
  }

  methodDescriptorSelectHistEqualType = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectHistEqualType",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  selectHistEqualType(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  selectHistEqualType(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  selectHistEqualType(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectHistEqualType",
        request,
        metadata || {},
        this.methodDescriptorSelectHistEqualType,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectHistEqualType",
      request,
      metadata || {},
      this.methodDescriptorSelectHistEqualType
    );
  }

  methodDescriptorGetEngineeringControl = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetEngineeringControl",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.EngControlMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.EngControlMsg.deserializeBinary
  );

  getEngineeringControl(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.EngControlMsg>;

  getEngineeringControl(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.EngControlMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.EngControlMsg>;

  getEngineeringControl(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.EngControlMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetEngineeringControl",
        request,
        metadata || {},
        this.methodDescriptorGetEngineeringControl,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetEngineeringControl",
      request,
      metadata || {},
      this.methodDescriptorGetEngineeringControl
    );
  }

  methodDescriptorSetEngineeringControl = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SetEngineeringControl",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.EngControlMsg,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.EngControlMsg) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  setEngineeringControl(
    request: src_proto_k3900_pb.EngControlMsg,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  setEngineeringControl(
    request: src_proto_k3900_pb.EngControlMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  setEngineeringControl(
    request: src_proto_k3900_pb.EngControlMsg,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SetEngineeringControl",
        request,
        metadata || {},
        this.methodDescriptorSetEngineeringControl,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SetEngineeringControl",
      request,
      metadata || {},
      this.methodDescriptorSetEngineeringControl
    );
  }

  methodDescriptorChangeTxMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ChangeTxMask",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MaskMsg,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MaskMsg) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  changeTxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  changeTxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  changeTxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ChangeTxMask",
        request,
        metadata || {},
        this.methodDescriptorChangeTxMask,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ChangeTxMask",
      request,
      metadata || {},
      this.methodDescriptorChangeTxMask
    );
  }

  methodDescriptorChangeRxMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ChangeRxMask",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MaskMsg,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MaskMsg) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  changeRxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  changeRxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  changeRxMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ChangeRxMask",
        request,
        metadata || {},
        this.methodDescriptorChangeRxMask,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ChangeRxMask",
      request,
      metadata || {},
      this.methodDescriptorChangeRxMask
    );
  }

  methodDescriptorChangePingMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ChangePingMask",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.MaskMsg,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.MaskMsg) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  changePingMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  changePingMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  changePingMask(
    request: src_proto_k3900_pb.MaskMsg,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ChangePingMask",
        request,
        metadata || {},
        this.methodDescriptorChangePingMask,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ChangePingMask",
      request,
      metadata || {},
      this.methodDescriptorChangePingMask
    );
  }

  methodDescriptorGetTxMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetTxMask",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.MaskMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.MaskMsg.deserializeBinary
  );

  getTxMask(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.MaskMsg> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetTxMask",
      request,
      metadata || {},
      this.methodDescriptorGetTxMask
    );
  }

  methodDescriptorGetRxMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetRxMask",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.MaskMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.MaskMsg.deserializeBinary
  );

  getRxMask(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.MaskMsg> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetRxMask",
      request,
      metadata || {},
      this.methodDescriptorGetRxMask
    );
  }

  methodDescriptorGetPingMask = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetPingMask",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.MaskMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.MaskMsg.deserializeBinary
  );

  getPingMask(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.MaskMsg> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/GetPingMask",
      request,
      metadata || {},
      this.methodDescriptorGetPingMask
    );
  }

  methodDescriptorGetNumberOfPings = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetNumberOfPings",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.IntMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.IntMsg.deserializeBinary
  );

  getNumberOfPings(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.IntMsg>;

  getNumberOfPings(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.IntMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.IntMsg>;

  getNumberOfPings(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.IntMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetNumberOfPings",
        request,
        metadata || {},
        this.methodDescriptorGetNumberOfPings,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetNumberOfPings",
      request,
      metadata || {},
      this.methodDescriptorGetNumberOfPings
    );
  }

  methodDescriptorGetPingMap = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetPingMap",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.PingRequest,
    src_proto_k3900_pb.ElemList,
    (request: src_proto_k3900_pb.PingRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ElemList.deserializeBinary
  );

  getPingMap(
    request: src_proto_k3900_pb.PingRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ElemList>;

  getPingMap(
    request: src_proto_k3900_pb.PingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ElemList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ElemList>;

  getPingMap(
    request: src_proto_k3900_pb.PingRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ElemList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetPingMap",
        request,
        metadata || {},
        this.methodDescriptorGetPingMap,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetPingMap",
      request,
      metadata || {},
      this.methodDescriptorGetPingMap
    );
  }

  methodDescriptorUserLogin = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/UserLogin",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.LoginRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  userLogin(
    request: src_proto_k3900_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  userLogin(
    request: src_proto_k3900_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  userLogin(
    request: src_proto_k3900_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/UserLogin",
        request,
        metadata || {},
        this.methodDescriptorUserLogin,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/UserLogin",
      request,
      metadata || {},
      this.methodDescriptorUserLogin
    );
  }

  methodDescriptorUserLogout = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/UserLogout",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.LogoutRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.LogoutRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  userLogout(
    request: src_proto_k3900_pb.LogoutRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  userLogout(
    request: src_proto_k3900_pb.LogoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  userLogout(
    request: src_proto_k3900_pb.LogoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/UserLogout",
        request,
        metadata || {},
        this.methodDescriptorUserLogout,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/UserLogout",
      request,
      metadata || {},
      this.methodDescriptorUserLogout
    );
  }

  methodDescriptorExternalStorageInfo = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ExternalStorageInfo",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ExternalStorageMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ExternalStorageMsg.deserializeBinary
  );

  externalStorageInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ExternalStorageMsg>;

  externalStorageInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ExternalStorageMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ExternalStorageMsg>;

  externalStorageInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ExternalStorageMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ExternalStorageInfo",
        request,
        metadata || {},
        this.methodDescriptorExternalStorageInfo,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ExternalStorageInfo",
      request,
      metadata || {},
      this.methodDescriptorExternalStorageInfo
    );
  }

  methodDescriptorMountExternalStorage = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/MountExternalStorage",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.StorageRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.StorageRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  mountExternalStorage(
    request: src_proto_k3900_pb.StorageRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  mountExternalStorage(
    request: src_proto_k3900_pb.StorageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  mountExternalStorage(
    request: src_proto_k3900_pb.StorageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/MountExternalStorage",
        request,
        metadata || {},
        this.methodDescriptorMountExternalStorage,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/MountExternalStorage",
      request,
      metadata || {},
      this.methodDescriptorMountExternalStorage
    );
  }

  methodDescriptorUnmountExternalStorage = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/UnmountExternalStorage",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  unmountExternalStorage(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  unmountExternalStorage(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  unmountExternalStorage(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/UnmountExternalStorage",
        request,
        metadata || {},
        this.methodDescriptorUnmountExternalStorage,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/UnmountExternalStorage",
      request,
      metadata || {},
      this.methodDescriptorUnmountExternalStorage
    );
  }

  methodDescriptorGetAbout = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetAbout",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.AboutMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.AboutMsg.deserializeBinary
  );

  getAbout(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.AboutMsg>;

  getAbout(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.AboutMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.AboutMsg>;

  getAbout(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.AboutMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetAbout",
        request,
        metadata || {},
        this.methodDescriptorGetAbout,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetAbout",
      request,
      metadata || {},
      this.methodDescriptorGetAbout
    );
  }

  methodDescriptorSendVersionInfo = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SendVersionInfo",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.VersionInfo,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.VersionInfo) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  sendVersionInfo(
    request: src_proto_k3900_pb.VersionInfo,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  sendVersionInfo(
    request: src_proto_k3900_pb.VersionInfo,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  sendVersionInfo(
    request: src_proto_k3900_pb.VersionInfo,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SendVersionInfo",
        request,
        metadata || {},
        this.methodDescriptorSendVersionInfo,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SendVersionInfo",
      request,
      metadata || {},
      this.methodDescriptorSendVersionInfo
    );
  }

  methodDescriptorGetPresets = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetPresets",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.PresetList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.PresetList.deserializeBinary
  );

  getPresets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.PresetList>;

  getPresets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.PresetList>;

  getPresets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetPresets",
        request,
        metadata || {},
        this.methodDescriptorGetPresets,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetPresets",
      request,
      metadata || {},
      this.methodDescriptorGetPresets
    );
  }

  methodDescriptorSelectPreset = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SelectPreset",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.PresetResponse,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.PresetResponse.deserializeBinary
  );

  selectPreset(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.PresetResponse>;

  selectPreset(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.PresetResponse>;

  selectPreset(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SelectPreset",
        request,
        metadata || {},
        this.methodDescriptorSelectPreset,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SelectPreset",
      request,
      metadata || {},
      this.methodDescriptorSelectPreset
    );
  }

  methodDescriptorCapturePreset = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/CapturePreset",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.PresetResponse,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.PresetResponse.deserializeBinary
  );

  capturePreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.PresetResponse>;

  capturePreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.PresetResponse>;

  capturePreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/CapturePreset",
        request,
        metadata || {},
        this.methodDescriptorCapturePreset,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/CapturePreset",
      request,
      metadata || {},
      this.methodDescriptorCapturePreset
    );
  }

  methodDescriptorResetPreset = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ResetPreset",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.PresetResponse,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.PresetResponse.deserializeBinary
  );

  resetPreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.PresetResponse>;

  resetPreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.PresetResponse>;

  resetPreset(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.PresetResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ResetPreset",
        request,
        metadata || {},
        this.methodDescriptorResetPreset,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ResetPreset",
      request,
      metadata || {},
      this.methodDescriptorResetPreset
    );
  }

  methodDescriptorSendEngineeringCommand = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/SendEngineeringCommand",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.EngineeringCmdRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.EngineeringCmdRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  sendEngineeringCommand(
    request: src_proto_k3900_pb.EngineeringCmdRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  sendEngineeringCommand(
    request: src_proto_k3900_pb.EngineeringCmdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  sendEngineeringCommand(
    request: src_proto_k3900_pb.EngineeringCmdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/SendEngineeringCommand",
        request,
        metadata || {},
        this.methodDescriptorSendEngineeringCommand,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/SendEngineeringCommand",
      request,
      metadata || {},
      this.methodDescriptorSendEngineeringCommand
    );
  }

  methodDescriptorGetTxSizeList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetTxSizeList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getTxSizeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getTxSizeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getTxSizeList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetTxSizeList",
        request,
        metadata || {},
        this.methodDescriptorGetTxSizeList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetTxSizeList",
      request,
      metadata || {},
      this.methodDescriptorGetTxSizeList
    );
  }

  methodDescriptorGetTxAptList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetTxAptList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getTxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getTxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getTxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetTxAptList",
        request,
        metadata || {},
        this.methodDescriptorGetTxAptList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetTxAptList",
      request,
      metadata || {},
      this.methodDescriptorGetTxAptList
    );
  }

  methodDescriptorGetRxAptList = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetRxAptList",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getRxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getRxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getRxAptList(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetRxAptList",
        request,
        metadata || {},
        this.methodDescriptorGetRxAptList,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetRxAptList",
      request,
      metadata || {},
      this.methodDescriptorGetRxAptList
    );
  }

  methodDescriptorGetSystemInfo = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetSystemInfo",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.SystemInfo,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.SystemInfo.deserializeBinary
  );

  getSystemInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.SystemInfo>;

  getSystemInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.SystemInfo
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.SystemInfo>;

  getSystemInfo(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.SystemInfo
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetSystemInfo",
        request,
        metadata || {},
        this.methodDescriptorGetSystemInfo,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetSystemInfo",
      request,
      metadata || {},
      this.methodDescriptorGetSystemInfo
    );
  }

  methodDescriptorGetBoardTests = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetBoardTests",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getBoardTests(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getBoardTests(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getBoardTests(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetBoardTests",
        request,
        metadata || {},
        this.methodDescriptorGetBoardTests,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetBoardTests",
      request,
      metadata || {},
      this.methodDescriptorGetBoardTests
    );
  }

  methodDescriptorRunBoardTest = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/RunBoardTest",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BoardTestRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.BoardTestRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  runBoardTest(
    request: src_proto_k3900_pb.BoardTestRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  runBoardTest(
    request: src_proto_k3900_pb.BoardTestRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  runBoardTest(
    request: src_proto_k3900_pb.BoardTestRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/RunBoardTest",
        request,
        metadata || {},
        this.methodDescriptorRunBoardTest,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/RunBoardTest",
      request,
      metadata || {},
      this.methodDescriptorRunBoardTest
    );
  }

  methodDescriptorGetMasterGainRange = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetMasterGainRange",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.MasterGainRange,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.MasterGainRange.deserializeBinary
  );

  getMasterGainRange(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.MasterGainRange>;

  getMasterGainRange(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.MasterGainRange
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.MasterGainRange>;

  getMasterGainRange(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.MasterGainRange
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetMasterGainRange",
        request,
        metadata || {},
        this.methodDescriptorGetMasterGainRange,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetMasterGainRange",
      request,
      metadata || {},
      this.methodDescriptorGetMasterGainRange
    );
  }

  methodDescriptorChangeSystemName = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ChangeSystemName",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.SystemNameChange,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.SystemNameChange) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  changeSystemName(
    request: src_proto_k3900_pb.SystemNameChange,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  changeSystemName(
    request: src_proto_k3900_pb.SystemNameChange,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  changeSystemName(
    request: src_proto_k3900_pb.SystemNameChange,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ChangeSystemName",
        request,
        metadata || {},
        this.methodDescriptorChangeSystemName,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ChangeSystemName",
      request,
      metadata || {},
      this.methodDescriptorChangeSystemName
    );
  }

  methodDescriptorProbeCalibration = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/ProbeCalibration",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.ProbeCalRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.ProbeCalRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  probeCalibration(
    request: src_proto_k3900_pb.ProbeCalRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  probeCalibration(
    request: src_proto_k3900_pb.ProbeCalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  probeCalibration(
    request: src_proto_k3900_pb.ProbeCalRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/ProbeCalibration",
        request,
        metadata || {},
        this.methodDescriptorProbeCalibration,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/ProbeCalibration",
      request,
      metadata || {},
      this.methodDescriptorProbeCalibration
    );
  }

  methodDescriptorRequestDataCapture = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/RequestDataCapture",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  requestDataCapture(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  requestDataCapture(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  requestDataCapture(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/RequestDataCapture",
        request,
        metadata || {},
        this.methodDescriptorRequestDataCapture,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/RequestDataCapture",
      request,
      metadata || {},
      this.methodDescriptorRequestDataCapture
    );
  }

  methodDescriptorGetListOfDatasets = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/GetListOfDatasets",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.StringList,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.StringList.deserializeBinary
  );

  getListOfDatasets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.StringList>;

  getListOfDatasets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.StringList>;

  getListOfDatasets(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.StringList
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/GetListOfDatasets",
        request,
        metadata || {},
        this.methodDescriptorGetListOfDatasets,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/GetListOfDatasets",
      request,
      metadata || {},
      this.methodDescriptorGetListOfDatasets
    );
  }

  methodDescriptorDeleteDataset = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DeleteDataset",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  deleteDataset(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  deleteDataset(
    request: src_proto_k3900_pb.NameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  deleteDataset(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/DeleteDataset",
        request,
        metadata || {},
        this.methodDescriptorDeleteDataset,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/DeleteDataset",
      request,
      metadata || {},
      this.methodDescriptorDeleteDataset
    );
  }

  methodDescriptorDownloadDataset = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/DownloadDataset",
    grpcWeb.MethodType.SERVER_STREAMING,
    src_proto_k3900_pb.NameRequest,
    src_proto_k3900_pb.DataFile,
    (request: src_proto_k3900_pb.NameRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.DataFile.deserializeBinary
  );

  downloadDataset(
    request: src_proto_k3900_pb.NameRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.DataFile> {
    return this.client_.serverStreaming(
      this.hostname_ + "/k3900.Beamformer/DownloadDataset",
      request,
      metadata || {},
      this.methodDescriptorDownloadDataset
    );
  }

  methodDescriptorKeepAlive = new grpcWeb.MethodDescriptor(
    "/k3900.Beamformer/KeepAlive",
    grpcWeb.MethodType.UNARY,
    src_proto_k3900_pb.BlankRequest,
    src_proto_k3900_pb.ResponseMsg,
    (request: src_proto_k3900_pb.BlankRequest) => {
      return request.serializeBinary();
    },
    src_proto_k3900_pb.ResponseMsg.deserializeBinary
  );

  keepAlive(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null
  ): Promise<src_proto_k3900_pb.ResponseMsg>;

  keepAlive(
    request: src_proto_k3900_pb.BlankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ): grpcWeb.ClientReadableStream<src_proto_k3900_pb.ResponseMsg>;

  keepAlive(
    request: src_proto_k3900_pb.BlankRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: src_proto_k3900_pb.ResponseMsg
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/k3900.Beamformer/KeepAlive",
        request,
        metadata || {},
        this.methodDescriptorKeepAlive,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/k3900.Beamformer/KeepAlive",
      request,
      metadata || {},
      this.methodDescriptorKeepAlive
    );
  }
}
