import { flatten } from "flat";
import { isEmpty, isString, join, map, omit, omitBy } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import { validatorErrorMessages } from "utils";
import Validator from "validatorjs";

export type FormInputValue =
  | string
  | string[]
  | number
  | URL
  | File
  | boolean
  | undefined;

type FormValues = Record<string, FormInputValue>;

type FormStoreProps = {
  setValue: (name: string, value?: FormInputValue) => void;
  setValues: (values?: Record<string, FormInputValue>) => void;
  getValue: (name: string) => FormInputValue;
  getValues: () => FormValues;
  validate: (callback?: (valid: boolean) => void) => void;
  getRules: () => Record<string, string>;
  setRule: (name: string, rules: string) => void;
  setAttributeName: (name: string, attribute: string) => void;
  setErrorMessage: (ruleName: string, msg: string) => void;
  setLoading: (loading?: boolean) => void;
  clear: () => void;
  values: Map<string, FormInputValue>;
  attributeNames: Record<string, string>;
  rules: Record<string, string>;
  errors: Map<string, string>;
  loading: boolean;
};

export class FormStore implements FormStoreProps {
  /**
   * @cfg {Record<input name, input value>} Form values.
   * Final values prepared for preserving.
   */
  values = new Map<string, FormInputValue>();

  /**
   * @cfg {Record<input name, input value>} Validation values.
   * Values we want to include in validation process.
   */
  public attributeNames: Record<string, string> = {};
  public rules: Record<string, string> = {};

  /**
   * @cfg {Map<input name, error msg>} Error msg for each input in the form.
   * Error msg change will rerender input error label.
   */
  errors = new Map<string, string>();

  /**
   * @cfg {boolean} loading flag.
   */
  loading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setValue<InputValue extends FormInputValue>(
    name: string,
    value?: InputValue
  ) {
    this.values.set(name, value);
    this.errors.delete(name);
  }

  setValues(values?: Record<string, FormInputValue>) {
    const flattenValues =
      flatten(values, {
        safe: true,
      }) ?? {};
    map(flattenValues, (v, k) => this.values.set(k, v));
  }

  omitFieldValue(name: string) {
    this.values.delete(name);
    this.errors.delete(name);
    this.rules = omit(this.rules, name);
  }

  getValue<InputValue extends FormInputValue>(name: string): InputValue {
    return toJS(this.values.get(name)) as InputValue;
  }

  getValues(): FormValues {
    return Object.fromEntries(this.values);
  }

  getIsInvalid(): boolean {
    return !isEmpty(this.errors);
  }

  getIsFieldInvalid(name: string) {
    return this.errors.get(name) !== "";
  }

  setRule(name: string, rules?: string) {
    if (!rules) {
      return;
    }
    this.rules = {
      ...this.rules,
      [name]: rules,
    };
  }

  setAttributeName(name: string, attribute: string) {
    this.attributeNames = {
      ...this.attributeNames,
      [name]: attribute,
    };
  }

  setErrorMessage(ruleName: string, msg: string) {
    validatorErrorMessages[ruleName] = msg;
  }

  getRules(): Record<string, string> {
    return this.rules;
  }

  setLoading(loading?: boolean) {
    this.loading = loading ?? false;
  }

  clear() {
    this.values.clear();
    this.errors.clear();
    this.rules = {};
    this.attributeNames = {};
  }

  async validate(): Promise<boolean> {
    const rules = this.getRules();
    const values = omitBy(this.getValues(), (v) => isString(v) && isEmpty(v));
    const validator = new Validator(values, rules, validatorErrorMessages);

    validator.setAttributeNames(this.attributeNames);
    this.errors.clear();

    return new Promise((resolve) => {
      validator.checkAsync(
        () => resolve(true), // Invoked on success
        () => {
          const errors = validator.errors.all();
          map(errors, (v, k) => this.errors.set(k, join(v, "\n")));
          resolve(false);
        } // Invoked on failure
      );
    });
  }
}
