import { Box, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import {
  AccordionView,
  FormView,
  IconView,
  TextBoxView,
  ControlWithInputView,
  ButtonView,
  TGCSliderView,
  SelectView,
  useExamViewContext,
} from "main";
import { reaction } from "mobx";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ExamEntity, FormStore } from "service";
import { sliderConfigs } from "service/store/ExamStore";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export interface SliderControlConfig {
  min: number;
  max: number;
  default: number;
  step: number;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ p: 1, width: "100%", overflow: "scroll" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function ImageControlsPanel() {
  const [value, setValue] = useState<number>(1);
  const formStore = new FormStore();
  const examStore = useExamViewContext();
  const tgcConfig = sliderConfigs["tgc0"];
  const brightnessConfig = sliderConfigs["brightness"];
  const dynamicRangeConfig = sliderConfigs["dynamic_range"];
  const focusConfig = sliderConfigs["focus"];
  const gammaConfig = sliderConfigs["gamma"];
  const rxAptsConfig = sliderConfigs["rx_apts"];
  const txAptsConfig = sliderConfigs["tx_apts"];
  const zoomConfig = sliderConfigs["zoom"];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    reaction(
      () => examStore.initialValues,
      (newValue) => {
        if (!newValue) {
          return;
        }
        Object.keys(newValue).forEach((key) => {
          const value = newValue[key as keyof ExamEntity];
          formStore.setValue(key, value);
        });
      }
    );
  }, [examStore]);

  const onTGCChange = (value: number, index: number) => {
    examStore.setBeamformerParameter(value, index);
  };

  const onControlChange = (name: string, value: number) => {
    if (name === "rx_apts" || name === "tx_apts") {
      examStore.setBeamformerIntParameter(name, value);
    }
    if (name === "focus") {
      examStore.setBeamformerFloatParameter(name, value * 1000);
    } else {
      examStore.setBeamformerFloatParameter(name, value);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        height: "100%",
        backgroundColor: "black.500",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        sx={{ width: 40, height: "100%", backgroundColor: "black.100" }}
      >
        <Tab
          icon={<IconView type="file" />}
          aria-label="home"
          {...a11yProps(0)}
        />
        <Tab
          icon={<IconView type="tune" />}
          aria-label="settings"
          {...a11yProps(1)}
        />
        <Tab
          icon={<IconView type="equalize" />}
          aria-label="profile"
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}></TabPanel>
      <TabPanel value={value} index={1}>
        <TextBoxView locale="pages.exam.image_controls" />
        <Divider sx={{ borderColor: "black.300", mb: 1 }} />
        <Observer>
          {() => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {examStore.isValuesLoading && <CircularProgress size="20px" />}
              {!examStore.isValuesLoading && (
                <FormView store={formStore}>
                  <AccordionView title="pages.exam.controls.tgc">
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc0"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc1"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc2"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc3"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc4"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc5"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc6"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc7"
                      onChange={onTGCChange}
                    />
                    <TGCSliderView
                      min={tgcConfig.min}
                      max={tgcConfig.max}
                      step={tgcConfig.step}
                      name="tgc8"
                      onChange={onTGCChange}
                    />
                    <ButtonView
                      title="general.center"
                      sx={{
                        height: "32px",
                        width: 40,
                        margin: "0 auto",
                        backgroundColor: "black.400",
                        borderColor: "primary.main",
                        borderWidth: 1,
                        borderStyle: "solid",
                        textTransform: "capitalize",
                      }}
                      textBoxStyle={{ color: "secondary.light" }}
                    />
                  </AccordionView>
                  <AccordionView title="pages.exam.controls.control1">
                    <ControlWithInputView
                      title="pages.exam.controls.dynamic_range"
                      name="brightness"
                      min={brightnessConfig.min}
                      max={brightnessConfig.max}
                      step={brightnessConfig.step}
                      onChange={onControlChange}
                    />
                    <ControlWithInputView
                      title="pages.exam.controls.fine_adjust"
                      name="dynamic_range"
                      min={dynamicRangeConfig.min}
                      max={dynamicRangeConfig.max}
                      step={dynamicRangeConfig.step}
                      onChange={onControlChange}
                    />
                    <ControlWithInputView
                      title="pages.exam.controls.zoom"
                      name="zoom"
                      min={zoomConfig.min}
                      max={zoomConfig.max}
                      step={zoomConfig.step}
                      hideInput={true}
                      onChange={onControlChange}
                    />
                  </AccordionView>
                  <AccordionView title="pages.exam.controls.control2">
                    <ControlWithInputView
                      title="pages.exam.controls.tx"
                      name="tx_apts"
                      min={txAptsConfig.min}
                      max={txAptsConfig.max}
                      step={txAptsConfig.step}
                      onChange={onControlChange}
                    />
                    <ControlWithInputView
                      title="pages.exam.controls.rx"
                      name="rx_apts"
                      min={rxAptsConfig.min}
                      max={rxAptsConfig.max}
                      step={rxAptsConfig.step}
                      onChange={onControlChange}
                    />
                    <ControlWithInputView
                      title="pages.exam.controls.speed_of_sound"
                      name="focus"
                      suffix="m/s"
                      min={focusConfig.min}
                      max={focusConfig.max}
                      step={focusConfig.step}
                      onChange={onControlChange}
                    />
                  </AccordionView>
                  <AccordionView title="pages.exam.controls.display_controls">
                    <SelectView
                      name="graymap"
                      options={examStore.typeListOptions["graymap"]}
                      placeholder="pages.exam.controls.graymap_type"
                      onChange={(value) => {
                        examStore.setGraymap(value as string);
                      }}
                    />
                    <SelectView
                      name="equalization"
                      options={examStore.typeListOptions["equalization"]}
                      placeholder="pages.exam.controls.equalization_type"
                      onChange={(value) => {
                        examStore.setHistequal(value as string);
                      }}
                    />
                    <ControlWithInputView
                      title="pages.exam.controls.gamma"
                      name="gamma"
                      min={gammaConfig.min}
                      max={gammaConfig.max}
                      step={gammaConfig.step}
                      onChange={onControlChange}
                    />
                    <TextBoxView locale="pages.exam.controls.equalization_type" />
                  </AccordionView>
                  <AccordionView title="pages.exam.controls.image_filters">
                    -
                  </AccordionView>
                  <AccordionView title="pages.exam.controls.capture_and_analysis">
                    -
                  </AccordionView>
                </FormView>
              )}
            </Box>
          )}
        </Observer>
      </TabPanel>
      <TabPanel value={value} index={2}></TabPanel>
    </Box>
  );
}

export default ImageControlsPanel;
