import { SvgIcon, SvgIconProps } from "@mui/material";
function GoogleLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={[
        { fill: "none", width: 80, height: 51, scale: 0.7 },
        sx ?? false,
      ].flat(2)}
      {...props}
    >
      <path
        fill="#FBC02D"
        d="M34.525 15.9h-1.276v-.067H19v6.334h8.949c-1.306 3.686-4.814 6.333-8.949 6.333a9.5 9.5 0 0 1 0-19c2.422 0 4.625.914 6.303 2.406l4.478-4.479c-2.828-2.635-6.61-4.26-10.78-4.26-8.745 0-15.834 7.09-15.834 15.833 0 8.744 7.09 15.833 15.833 15.833 8.744 0 15.834-7.089 15.834-15.833 0-1.061-.11-2.098-.308-3.1Z"
      />
      <path
        fill="#E53935"
        d="m4.992 11.63 5.202 3.815C11.602 11.961 15.011 9.5 19 9.5a9.45 9.45 0 0 1 6.302 2.406l4.479-4.479C26.953 4.793 23.17 3.168 19 3.168c-6.082 0-11.356 3.433-14.008 8.463Z"
      />
      <path
        fill="#4CAF50"
        d="M19 34.833c4.09 0 7.806-1.565 10.615-4.11l-4.9-4.147A9.429 9.429 0 0 1 19 28.5c-4.118 0-7.615-2.626-8.932-6.29l-5.164 3.978c2.62 5.127 7.942 8.645 14.096 8.645Z"
      />
      <path
        fill="#1565C0"
        d="m34.525 15.899-.012-.066H19v6.334h8.948a9.532 9.532 0 0 1-3.235 4.41l.002-.002 4.9 4.147c-.346.315 5.218-3.805 5.218-11.722 0-1.062-.109-2.098-.308-3.101Z"
      />
    </SvgIcon>
  );
}

export default GoogleLogo;
