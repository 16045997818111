import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionProps,
} from "@mui/material";
import { IconView } from "./IconView";
import TextBoxView from "./TextBoxView";

type AccordionViewProps = AccordionProps & {
  title: string;
};

function AccordionView({ title, children }: AccordionViewProps) {
  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<IconView type="arrowRight" sx={{ color: "info.main" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <TextBoxView locale={title} />
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionView;
