function convertSecondsToTime(seconds: number): string {
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }
  return `${Math.round(seconds)}s`;
}

export default convertSecondsToTime;
