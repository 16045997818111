import { Box, BoxProps, ButtonProps } from "@mui/material";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { IconViewType } from "./IconView";

type TextBoxViewProps = BoxProps &
  WrappedComponentProps<"intl"> &
  Pick<ButtonProps, "endIcon" | "startIcon"> & {
    locale?: string;
    defaultMessage?: string;
    values?: Record<string, string | number>;
    icon?: IconViewType;
  };

function TextBoxView({
  locale,
  defaultMessage = "",
  values,
  intl: { messages },
  startIcon,
  endIcon,
  sx,
  ...props
}: TextBoxViewProps) {
  if (locale && !messages[locale]) {
    messages[locale] = locale;
    console.warn(`Missing translation: ${locale}`);
  }

  return (
    <Box
      sx={[
        { color: "secondary.main", alignContent: "center" },
        sx ?? false,
      ].flat(2)}
      {...props}
    >
      {startIcon}
      <Box sx={{ pl: startIcon ? 1 : 0, pr: endIcon ? 1 : 0 }}>
        {locale ? (
          <FormattedMessage id={locale} {...{ defaultMessage, values }} />
        ) : (
          defaultMessage
        )}
      </Box>
      {endIcon}
    </Box>
  );
}

export default injectIntl(TextBoxView);
