import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  ImageList,
  Typography,
} from "@mui/material";
import { cardHeaderStyle, baseCardStyle, cardContentStyles } from "config";
import TextBoxView from "main/components/common/TextBoxView";
import { MediaEntity } from "service";

import ImageListItemView from "./ImageListItemView";

export type PatientImageCardProps = CardProps & {
  date?: string;
  examName: string;
  notes?: string;
  mediaList: MediaEntity[];
  onImageClick: (url: string, isLocalLoad: boolean) => void;
  availableDatasets: string[];
};

function PatientImageCard({
  notes,
  date,
  examName,
  mediaList,
  availableDatasets,
  onImageClick,
}: PatientImageCardProps) {
  return (
    <Card
      sx={{
        ...baseCardStyle,
        height: "auto",
        border: "none",
        borderRadius: 0,
        background: "transparent",
      }}
    >
      <CardHeader
        sx={{
          ...cardHeaderStyle,
          border: "none",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        title={
          <Breadcrumbs separator="|" aria-label="breadcrumb">
            <Typography color="secondary.main">{date}</Typography>
            <Typography color="secondary.main">{examName}</Typography>
            {notes && <Typography color="secondary.main">{notes}</Typography>}
            {!notes && (
              <TextBoxView locale="pages.patient.no_available_notes" />
            )}
          </Breadcrumbs>
        }
      />
      <CardContent sx={{ ...cardContentStyles }}>
        <ImageList cols={5}>
          {mediaList.length &&
            mediaList.map((img) => (
              //   <ImageListItem
              //     key={`media-${img.id}`}
              //     // onClick={() => onImageClick(img.s3Key)}
              //     sx={{
              //       width: "180px",
              //       height: "180px!important",
              //       border: 1,
              //       borderColor: availableDatasets.includes(img.s3Key)
              //         ? "primary.main"
              //         : "black.300",
              //       borderRadius: 5,
              //       overflow: "hidden",
              //       mr: 2,
              //       mb: 2,
              //       opacity: availableDatasets.includes(img.s3Key) ? 1 : 0.2,
              //     }}
              //   >
              //     <TIFFViewer
              //       tiff={img.url}
              //       lang="en"
              //       paginate="ltr"
              //       printable={false}
              //       style={{
              //         width: "100%",
              //         height: "100%",
              //         objectFit: "cover",
              //       }}
              //     />
              //   </ImageListItem>
              <ImageListItemView
                key={img.id}
                imageDatasetKey={img.s3Key}
                imgUrl={img.url}
                onClick={onImageClick}
                isLocalDataset={availableDatasets.includes(img.s3Key)}
              />
            ))}
        </ImageList>
      </CardContent>
    </Card>
  );
}

export default PatientImageCard;
