import { NavLink, UIMatch, useMatches, useParams } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { last } from "lodash";
import { breadcrumbRoutes, replaceParams } from "utils";

function BreadcrumbsView() {
  const matches = useMatches() as UIMatch[];
  const crumbRoutes = breadcrumbRoutes(matches);
  const params = useParams<Record<string, string | undefined>>();

  return (
    <Breadcrumbs sx={{ my: 2, px: 3, color: "black.50" }}>
      {crumbRoutes.map((route) => {
        const fullPath = replaceParams(route?.path ?? "", params);
        return (
          <Box key={route?.id} sx={{ color: "secondary.main" }}>
            {last(crumbRoutes) === route && (
              <Typography sx={{ color: "secondary.main" }}>
                {route?.crumb?.title}
              </Typography>
            )}
            {last(crumbRoutes) !== route && (
              <NavLink to={fullPath ?? ""} style={{ textDecoration: "none" }}>
                <Typography sx={{ color: "black.50" }}>
                  {route?.crumb?.title}
                </Typography>
              </NavLink>
            )}
          </Box>
        );
      })}
    </Breadcrumbs>
  );
}

export default BreadcrumbsView;
