import { Box } from "@mui/material";
import {
  TextBoxView,
  TextFieldView,
  SelectView,
  FormView,
  PatientFilterView,
} from "main";
import { AppStoreContext, FormStore } from "service";
import { useCallback, useContext, useEffect } from "react";
import { Observer } from "mobx-react";
import { generatePath, useNavigate } from "react-router-dom";

function PatientsPage() {
  const formStore = new FormStore();
  const navigate = useNavigate();
  const { patientsStore } = useContext(AppStoreContext);

  useEffect(() => {
    patientsStore.fetchPatients();
  }, []);

  const onClick = useCallback(
    (patientId: string) => {
      navigate(
        generatePath("/patients/:patientId", {
          patientId: patientId,
        })
      );
    },
    [navigate]
  );

  return (
    <Box sx={{ px: 3 }}>
      <Observer>
        {() => {
          return (
            <>
              <FormView store={formStore}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <TextBoxView
                    values={{ patientsCount: patientsStore.patientsCount }}
                    locale="pages.patients.total_patients"
                  />
                  <TextFieldView
                    placeholder="general.search"
                    name="search"
                    startIcon="search"
                    size="small"
                    onChange={(value) =>
                      patientsStore.updateFilter(value as string)
                    }
                    boxProps={{
                      width: { sm: "30%" },
                      flexGrow: { xs: 1, sm: 0 },
                      px: 2.5,
                    }}
                  />
                  <SelectView
                    name="filter"
                    options={[]}
                    placeholder="general.filters"
                    filter
                  />
                </Box>
              </FormView>
              <Box sx={{ flex: 1, height: "100%", mt: 3 }}>
                <PatientFilterView
                  onRowClick={onClick}
                  patients={JSON.parse(
                    JSON.stringify(patientsStore?.filteredPatients)
                  )}
                />
              </Box>
            </>
          );
        }}
      </Observer>
    </Box>
  );
}

export default PatientsPage;
