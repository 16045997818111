import { Chip, ChipProps } from "@mui/material";
import { IconView } from "./IconView";

type ChipViewProps = ChipProps & {
  active: boolean;
};

function ChipView({ label, active }: ChipViewProps) {
  return (
    <Chip
      label={active ? label : "No available PU"}
      icon={
        <IconView
          type="lens"
          color={active ? "success" : "error"}
          sx={{ fontSize: 16 }}
        />
      }
      sx={{
        backgroundColor: "black.100",
        color: "secondary.main",
        borderRadius: "5px",
      }}
    />
  );
}

export default ChipView;
