import { AlertColor } from "@mui/material";
import { SnackbarViewProps } from "main";
import { LocaleType } from "config";
import { makeAutoObservable } from "mobx";
import { cacheable, storage } from "service";
import Validator from "validatorjs";

interface UIStoreProps {
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
  clearMessage: () => void;
  setLocale: (lang?: LocaleType) => void;
  locale: LocaleType;
  message?: SnackbarViewProps;
}

export class UIStore implements UIStoreProps {
  locale: LocaleType = "en";
  message: SnackbarViewProps = {
    message: "",
    open: false,
    severity: undefined,
  };

  constructor() {
    this.setLocale();
    makeAutoObservable(this);
  }

  showSuccess(message: string) {
    this.showMessage(message, "success");
  }

  showError(message: string) {
    this.showMessage(message, "error");
  }

  clearMessage() {
    this.message.open = false;
  }

  setLocale(lang?: LocaleType) {
    const { activeLang } = storage.getCache();
    const pineLang =
      lang ?? activeLang ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? "en";
    this.locale = pineLang as LocaleType;
    cacheable("activeLang", undefined, pineLang);
    Validator.useLang(pineLang);
  }

  private showMessage(message: string, severity: AlertColor) {
    this.message = {
      message,
      severity,
      open: true,
    };
    setTimeout(() => this.clearMessage(), 4000);
  }
}
