import { Components, outlinedInputClasses, Theme } from "@mui/material";

const themeComponents: Components<Omit<Theme, "components">> = {
  MuiButton: {
    styleOverrides: {
      root: {
        height: 50,
        borderRadius: 7,
      },
    },
    variants: [
      {
        props: { size: "small" },
        style: {},
      },
      {
        props: { size: "medium" },
        style: {},
      },
      {
        props: { size: "large" },
        style: {},
      },
    ],
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: ({ theme }) => ({
        background: theme.palette.black?.[100],
        color: theme.palette.black?.[50],
        "&::placeholder": {
          opacity: 1,
        },
      }),
      notchedOutline: ({ theme }) => ({
        borderColor: theme.palette.black?.[200],
      }),
      root: ({ theme }) => ({
        background: theme.palette.black?.[100],
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.primary.main,
        },
        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
      }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.secondary.main,
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        marginBottom: "20px",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 40,
        minWidth: 40,
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.black[100],
        "&.Mui-selected": {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.black[500],
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        display: "none",
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.black[300],
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.black[600],
      }),
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.dark,
        marginTop: "0!important",
      }),
      thumb: ({ theme }) => ({
        height: 17,
        width: 17,
        backgroundColor: theme.palette.black[600],
        border: 5,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box",
        "&:hover": {
          boxShadow: "none",
        },
      }),
      track: {
        border: "none",
        height: 2,
      },
      rail: {
        opacity: 1,
        height: 2,
      },
      mark: ({ theme }) => ({
        width: 11,
        height: 11,
        borderRadius: "50%",
        backgroundColor: theme.palette.black[600],
        border: 3,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        opacity: 1,
        transform: "translateX(-50%) translateY(-50%)",
        boxSizing: "border-box",
      }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.black[300],
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.black[300],
        "&:hover": {
          backgroundColor: theme.palette.black[200],
        },
      }),
    },
  },
};
export { themeComponents };
