import { Checkbox, ImageListItem } from "@mui/material";

type PreviewImageViewProps = {
  src: string;
  isImage?: boolean;
  isVideo?: boolean;
  onImageClick?: (src: string) => void;
  isImageChecked?: boolean;
  onSelectImage?: (src: string) => void;
};

function PreviewImageView({
  src,
  isImage = false,
  isVideo = false,
  onImageClick,
  isImageChecked,
  onSelectImage,
}: PreviewImageViewProps) {
  return (
    <ImageListItem
      key={`media-${src}`}
      sx={{
        position: "relative",
        width: "180px",
        height: "180px!important",
        border: 1,
        borderColor: "black.300",
        borderRadius: 2,
        mb: 2,
        margin: "0 auto",
      }}
    >
      {isImage && (
        <>
          <Checkbox
            checked={isImageChecked}
            onChange={() => onSelectImage?.(src)}
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              zIndex: 1,
            }}
          />
          <img
            src={src}
            alt={"item"}
            loading="lazy"
            onClick={() => onImageClick?.(src)}
            style={{ borderRadius: 2 }}
          />
        </>
      )}
      {isVideo && (
        <video
          controls
          src={src}
          style={{
            position: "relative",
            width: "180px",
            height: "180px!important",
            border: 1,
            borderColor: "black.300",
            borderRadius: 2,
            marginBottom: 2,
            margin: "0 auto",
          }}
        >
          Your browser does not support the video tag.
        </video>
      )}
    </ImageListItem>
  );
}

export default PreviewImageView;
