import { useRouteError } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import TextBoxView from "main/components/common/TextBoxView";

interface ErrorRouteProps {
  statusText?: string;
  message?: string;
}

function ErrorPage() {
  const { statusText, message } = useRouteError() as ErrorRouteProps;
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Stack spacing={6}>
        <TextBoxView locale="pages.error.oops" sx={{ typography: "h3" }} />
        <TextBoxView
          locale="pages.error.error_has_occurred"
          sx={{ typography: "h4" }}
        />
        <Box
          sx={{
            typography: "body1",
            color: "secondary.main",
          }}
        >
          {statusText || message}
        </Box>
      </Stack>
    </Box>
  );
}

export default ErrorPage;
