import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, {
  BaseSelectProps,
  SelectChangeEvent,
} from "@mui/material/Select";
import { Observer } from "mobx-react";
import { isEmpty } from "lodash";
import { useFormViewContext } from "./FormView";
import { IconView } from "./IconView";
import { Box, Typography } from "@mui/material";
import TextBoxView from "./TextBoxView";

export interface OptionType {
  id: string | number;
  value: string;
}

export type SelectViewProps = BaseSelectProps & {
  options: OptionType[];
  name: string;
  value?: string | string[];
  filter?: boolean;
  onChange?: (value: string) => void;
};

function SelectView({
  name,
  options,
  placeholder,
  value,
  multiple = false,
  filter = false,
  onChange,
  ...props
}: SelectViewProps) {
  const formStore = useFormViewContext();

  if (filter) {
    options = [{ id: "", value: "" }, ...options];
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    formStore.setValue(name, value as string);
    onChange?.(value as string);
  };

  return (
    <Observer>
      {() => {
        const selectValue = formStore?.getValue(name) ?? value ?? "";
        return (
          <>
            <Select
              {...props}
              displayEmpty
              value={selectValue}
              multiple={multiple}
              onChange={handleChange}
              IconComponent={(iconProps) => (
                <IconView type="expandMore" {...iconProps} />
              )}
              size="small"
              sx={{
                my: 1,
                ".MuiSelect-icon": {
                  color: "secondary.main",
                },
              }}
              renderValue={() => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {filter && (
                      <IconView type="filter" sx={{ color: "black.50" }} />
                    )}

                    {isEmpty(selectValue) ? (
                      <TextBoxView locale={placeholder} />
                    ) : (
                      <Typography>{String(selectValue)}</Typography>
                    )}
                  </Box>
                );
              }}
            >
              {options.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.id}
                  sx={{ ...(isEmpty(item.id) && { display: "none" }) }}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      }}
    </Observer>
  );
}

export default SelectView;
