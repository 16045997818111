import { makeAutoObservable } from "mobx";
import { SortDirection, SortDirectionType } from "react-virtualized";
import { sortBy as _sortBy } from "lodash";
import { getHospitalPatients } from "service/endpoint/PatientsEndpoint";
import { fetchPatients, LoaderEntity, PatientEntity } from "service";

export interface PatientsStoreSortProps {
  sortBy: string;
  sortDirection: SortDirectionType;
}

interface PatientsStoreProps {
  filterText: string;
  patients: PatientEntity[];
  filterOpen: boolean;
  patientsCount: number;
  sortBy: string;
  sortDirection: SortDirectionType;
  updateFilter: (filterText: string) => void;
  fetchPatients: () => void;
  setPatients: (patients: PatientEntity[]) => void;
  filteredPatients: PatientEntity[];
  sort: (sortParams: PatientsStoreSortProps) => void;
}

export class PatientsStore implements PatientsStoreProps {
  filterText = "";
  patients: PatientEntity[] = [];
  filterOpen = false;
  sortBy: keyof PatientEntity = "firstName";
  sortDirection: SortDirectionType = "DESC";

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get patientsCount(): number {
    return this.filteredPatients.length;
  }

  updateFilter(filterText: string) {
    this.filterText = filterText;
  }

  setPatients(patients: PatientEntity[]) {
    this.patients = patients ?? [];
  }

  get filteredPatients() {
    const patients = this.patients;
    const newList = _sortBy(patients, [this.sortBy]);
    if (this.sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    const filter = new RegExp(this.filterText, "i");

    return newList.filter((patient: PatientEntity) =>
      filter.test(`${patient.fullName} ${patient.shortId}`)
    );
  }

  async fetchPatients() {
    try {
      const { data } = await getHospitalPatients();
      this.setPatients(data);
    } catch (err) {
      console.log({ err });
    }
  }

  async patientsLoader() {
    const patients = await fetchPatients();
    return { patients } as LoaderEntity;
  }

  sort({ sortBy, sortDirection }: PatientsStoreSortProps) {
    this.sortBy = sortBy as keyof PatientEntity;
    this.sortDirection = sortDirection;
  }
}
