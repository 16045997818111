import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { TextBoxView } from "main";

export type SnackbarViewProps = AlertProps &
  Pick<SnackbarProps, "open"> & {
    message?: string;
  };

function SnackbarView({ open, message, ...props }: SnackbarViewProps) {
  return (
    <Snackbar
      {...{ open }}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <Alert sx={{ display: "flex" }} {...props}>
        <TextBoxView locale={message} />
      </Alert>
    </Snackbar>
  );
}

export default SnackbarView;
