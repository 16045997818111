import Validator from "validatorjs";

const _registerValidators = () => {
  PasswordValidator();
};

const PasswordValidator = () => {
  Validator.register(
    "password",
    (value: string | number | boolean) => {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        value.toString()
      );
    },
    undefined
  );
};

const _validatorErrorMessages: Record<string, string> = {};

export {
  _registerValidators as registerValidators,
  _validatorErrorMessages as validatorErrorMessages,
};
