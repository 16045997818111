import { SxProps, Theme } from "@mui/material";
import { themePalette } from "./palette";

export const cardHeaderStyle: SxProps<Theme> = {
  px: 2,
  background: themePalette.black[300],
  width: "100%",
  borderBottom: 0.5,
  borderBottomColor: themePalette.black[50],
  height: "8%",
};

export const baseCardStyle: SxProps<Theme> = {
  borderRadius: "10px",
  border: 0.5,
  borderColor: themePalette.black[50],
  boxShadow: "none",
  height: "auto",
  maxHeight: "100%",
  backgroundColor: themePalette.black[100],
};

export const cardContentStyles: SxProps<Theme> = {
  px: 2,
  backgroundColor: themePalette.black[100],
  width: "100%",
  height: "100%",
  overflow: "scroll",
};
