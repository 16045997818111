import { client, PatientEntity, ServerResponseEntity, Url } from "service";
import { format } from "react-string-format";

async function _getHospitalPatients(): Promise<
  ServerResponseEntity<PatientEntity[]>
> {
  return await client("users").get(Url.allPatients);
}

async function _getPatientDetails(
  patientId: string
): Promise<ServerResponseEntity<PatientEntity>> {
  const url = format(Url.patientDetails, patientId);
  return await client("users").get(url);
}

async function _getPatientMedia(patientId: string) {
  const url = format(Url.patientMedia, patientId);
  return await client("users").get(url);
}

export {
  _getHospitalPatients as getHospitalPatients,
  _getPatientDetails as getPatientDetails,
  _getPatientMedia as getPatientMedia,
};
