import { Box } from "@mui/material";
import { SliderView, TextBoxView, TextFieldView } from "main";

type ControlWithInputProps = {
  title: string;
  name: string;
  min?: number;
  max?: number;
  suffix?: string;
  step?: number;
  hideInput?: boolean;
  defaultValue?: number;
  disableInput?: boolean;
  onChange?: (name: string, value: number) => void;
};

function ControlWithInputView({
  title,
  name,
  min,
  max,
  suffix,
  step,
  hideInput = false,
  defaultValue,
  disableInput = false,
  onChange,
}: ControlWithInputProps) {
  const isSuffix = suffix?.length;
  return (
    <Box sx={{ mt: "0!important" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextBoxView
          locale={title}
          sx={{ color: "secondary.light", textTransform: "uppercase" }}
        />
        <TextFieldView
          hidden={hideInput}
          name={name}
          size="small"
          fullWidth={false}
          suffix={suffix}
          defaultValue={defaultValue}
          disabled={disableInput}
          sx={{
            maxWidth: isSuffix ? 130 : 110,
            "& input": {
              padding: 0,
              borderRadius: "15px",
              px: 1,
            },
            ".MuiInputBase-root": {
              borderRadius: "15px",
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
              },
            "&.Mui-disabled input": {
              color: "red!important", // Disabled text color
            },
            // Disable opacity when the input is disabled
            "&.Mui-disabled .MuiInputBase-input": {
              color: "red!important", // Ensure the text color for the disabled state
            },
            // Optional: Modify the background color for disabled input
          }}
          type="number"
        />
      </Box>
      <SliderView {...{ min, max, name, step, defaultValue, onChange }} />
    </Box>
  );
}

export default ControlWithInputView;
