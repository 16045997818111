import { NavLink, useMatches, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { HomeLogo } from "assets";
import IconButtonView from "../common/IconButtonView";
import { ButtonView, ChipView, DropdownView, IconView } from "main";
import { breadcrumbRoutes } from "utils";
import { find, map } from "lodash";
import { IconViewType } from "../common/IconView";
import { navigationRoutes, routes } from "config";
import { useContext } from "react";
import { AppStoreContext } from "service";
import { Observer } from "mobx-react";

function NavigationBarView() {
  const matches = useMatches();
  const crumbRoutes = breadcrumbRoutes(matches);
  const { authStore, puStore } = useContext(AppStoreContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    authStore.signOut();
    navigate(routes.signIn.path);
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <HomeLogo sx={{ mr: 5 }} />
        <Stack direction="row" spacing={2}>
          {map(Object.values(navigationRoutes), (route) => {
            const isActive = Boolean(find(crumbRoutes, { id: route.id }));
            return (
              <Box key={route.id}>
                <NavLink to={route.path}>
                  <ButtonView
                    title={route.title}
                    variant="text"
                    color="secondary"
                    startIcon={
                      <IconView
                        type={route.navigationIconType as IconViewType}
                      />
                    }
                    sx={{
                      borderBottom: isActive ? 1 : 0,
                      borderRadius: "0px",
                      borderColor: "primary.main",
                    }}
                  />
                </NavLink>
              </Box>
            );
          })}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Observer>
          {() => (
            <ChipView label={puStore.puName} active={puStore.isPUAvailable} />
          )}
        </Observer>
        <IconButtonView iconType="help" sx={{ ml: 3 }} />
        <Badge badgeContent={17} color="primary" sx={{ mr: 2, ml: 2 }}>
          <IconButtonView iconType="notification" />
        </Badge>
        <DropdownView
          options={[{ id: "logout", value: "Logout" }]}
          onClick={handleLogout}
          iconProps={{
            "&:hover": { background: "none" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
            <Typography color="secondary" sx={{ ml: "5px" }}>
              Dr.JohnSmith
            </Typography>
          </Box>
        </DropdownView>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBarView;
