import { SvgIcon, SvgIconProps } from "@mui/material";

function LinkedInLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={[
        { fill: "none", width: 80, height: 51, scale: 0.7 },
        sx ?? false,
      ].flat(2)}
      {...props}
    >
      <path
        fill="#0078D4"
        d="M3.666 8.083h.041c2.209 0 3.584-1.583 3.584-3.562C7.25 2.498 5.916.958 3.749.958S.166 2.498.166 4.521c0 1.979 1.374 3.562 3.5 3.562Zm-3.5 2.375h7.125v20.584H.166V10.458Zm31.667 7.521a7.52 7.52 0 0 0-7.521-7.52 7.5 7.5 0 0 0-5.938 2.916v-2.917H11.25v20.584h7.125V19.167a3.167 3.167 0 0 1 6.334 0v11.875h7.125V17.979Z"
      />
    </SvgIcon>
  );
}

export default LinkedInLogo;
