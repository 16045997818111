import { RouteProps } from "config";
import { PatientPage } from "main";
import { ExamPage } from "main";

export type PatientRouteType = "patient" | "exam";

const _patientRoutes: Record<PatientRouteType, RouteProps> = {
  patient: {
    id: "patient",
    path: "patients/:patientId",
    title: "pages.patients.title",
    element: PatientPage,
    crumb: {
      paths: ["patients", "patient"],
      title: "Patient Details",
    },
  },
  exam: {
    id: "exam",
    path: "patients/:patientId/exam",
    title: "pages.exam.title",
    element: ExamPage,
    crumb: {
      paths: ["patients", "patient", "exam"],
      title: "Exam",
    },
  },
};

export { _patientRoutes as patientRoutes };
