import { PaletteOptions } from "@mui/material";

declare module "@mui/material/styles/createPalette" {
  interface CustomPaletteColor {
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }

  interface Palette {
    black: CustomPaletteColor;
  }

  interface PaletteOptions {
    black: CustomPaletteColor;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    "paper-1": string;
  }
}

const themePalette: PaletteOptions = {
  primary: {
    main: "#FB451D",
    light: "#FBB41D",
    dark: "#FB451D",
  },
  secondary: {
    main: "#D2DBE4",
    light: "#FBB41D",
    dark: "#919FAF",
  },
  error: {
    main: "#ff0000",
    dark: "#ff0000",
    light: "#ff0000",
    contrastText: "#ff0000",
  },
  warning: {
    main: "#FFFFFF",
    dark: "#FFFFFF",
    light: "#FFFFFF",
    contrastText: "#FFFFFF",
  },
  info: {
    main: "#FFFFFF",
    dark: "#FFFFFF",
    light: "#FFFFFF",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#00FF37",
    dark: "#FFFFFF",
    light: "#00FF37",
    contrastText: "#FFFFFF",
  },
  background: {
    "paper-1": "#000000E6",
  },
  black: {
    50: "#919FAF",
    100: "#292e32",
    200: "#5E6774",
    300: "#3C464F",
    400: "#101214",
    500: "#1D2125",
    600: "#121417",
  },
};

export { themePalette };
