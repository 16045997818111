import { RouteProps, routes } from "config";
import { find, get, last, map } from "lodash";
import { UIMatch } from "react-router-dom";
import { CrumbEntity } from "service";

const _breadcrumbRoutes = (matches: UIMatch[]): RouteProps[] => {
  const crumbs = (get(last(matches)?.handle, "crumb") as unknown as CrumbEntity)
    ?.paths;
  const crumbRoutes = map(crumbs, (crumb) => find(routes, { id: crumb }));
  return (crumbRoutes as RouteProps[]) || [];
};

const _replaceParams = (
  path: string,
  params: Record<string, string | undefined>
) => {
  return Object.keys(params).reduce((updatedPath, paramKey) => {
    return updatedPath.replace(`:${paramKey}`, params[paramKey] || "");
  }, path);
};

export {
  _breadcrumbRoutes as breadcrumbRoutes,
  _replaceParams as replaceParams,
};
